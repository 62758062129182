<template lang="pug">
product-display(:itemNumber="itemNumber" :customerNumber="customerNumber" @closeProductView="onCloseProductView")
</template>

<script setup>
  import ProductDisplay from '@/components/ProductDisplay'
  import { useRouter } from "vue-router";

  const props = defineProps({
      customerNumber: {
        type: String
      },
      itemNumber: {
        type: String,
        required: true      
      }
    })  

  const router = useRouter();

  const onCloseProductView = async () => {
    await router.push({name: 'Public'})
  }
</script>

<style scoped lang="scss">
.product-display {  
  max-width: 892px;
  
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  flex: 1 1 auto;
  overflow: hidden;

  @media only screen and (max-width: $phone-portrait) {
    width: 100%;
  }
}
</style>