<template lang="pug">
.search-field
  input(
    ref="searchinput"
    autocomplete="off"
    name="search"
    Color="accent"
    :placeholder="placeholder"
    :value="value",
    @input="update($event.target.value)"
    @keyup.enter="action"
    @focus="onFocus"
    @blur="onBlur"
  )
  .input-icon
    ui-icon search
  .action-buttons
    .action-button(@click="action")
      ui-icon arrow_forward
    .seperator
    svg.qrcode-text-btn(
        @click="qrAction"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 11 11"
        enable-background="new 0 0 11 11"
        xml:space="preserve"
      )
      rect.forground(x="0" y="0" width="5" height="5")
      rect.forground(x="6" y="0" width="5" height="5")
      rect.forground(x="0" y="6" width="5" height="5")
      rect.background(x="1" y="1" width="3" height="3")
      rect.forground(x="2" y="2" width="1" height="1")
      rect.background(x="7" y="1" width="3" height="3")
      rect.forground(x="8" y="2" width="1" height="1")
      rect.background(x="1" y="7" width="3" height="3")
      rect.forground(x="2" y="8" width="1" height="1")
      rect.forground(x="6" y="6" width="1" height="1")
      rect.forground(x="8" y="6" width="1" height="1")
      rect.forground(x="10" y="6" width="1" height="1")
      rect.forground(x="6" y="8" width="1" height="1")
      rect.forground(x="8" y="8" width="1" height="1")
      rect.forground(x="10" y="8" width="1" height="1")
      rect.forground(x="6" y="10" width="1" height="1")
      rect.forground(x="8" y="10" width="1" height="1")
      rect.forground(x="10" y="10" width="1" height="1")
      rect.forground(x="7" y="7" width="1" height="1")
      rect.forground(x="9" y="7" width="1" height="1")
      rect.forground(x="7" y="9" width="1" height="1")
      rect.forground(x="9" y="9" width="1" height="1")
</template>

<script>
  export default {
    name:'SearchInput',
    props: {
      value: String,
      action: Function,
      qrAction: Function,
      placeholder: String
    },
    emits: [
      'input',
      'focus',
      'blur' 
    ],
    methods: {
      update(inputValue) {
        this.$emit('input', inputValue)
      },
      onFocus (event) {
        this.$emit('focus', event)
      },
      onBlur (event) {
        this.$emit('blur', event)
      }
    }
  }
</script>

<style scoped lang="scss">
  .search-field {
    display: flex;
    position: relative;
    width: 100%;

    input {
      border-radius: 35px;
      border: 1px solid transparent;
      border-color: $deep-sea;
      background-color: $white;
      color: $deep-sea;
      padding-left: 30px;
      padding-right: 60px;
      width: 100%;
      height: 30px;
      font-size: 12px;

      &:focus {
        outline: none;
      }
    }

    .input-icon {
      position: absolute;
      top: 4px;
      left: 5px;
    }

    .action-buttons {
      display: flex;
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 5px;

      .seperator {
        margin-top: 2px;
        width: 1px;
        height: 22px;
        margin: 0 4px;
        background-color: $gray;
      }

      .md-icon {
        color: $iced-slate;
        &:hover {
          color: $deep-sea;
        }
      }

      .qrcode-text-btn {
        margin: 2px 3px;
        height: 18px;
        width: 18px;

        .forground {
          fill: $iced-slate;
        }

        .background {
          fill: $white;
        }

        &:hover {
          .forground {
            fill: $deep-sea;
          }
        }
      }
    }
  }
</style>