<template lang="pug">
.tile.responsive(@click="onClick")
  .title.responsive {{ title }}
  img.responsive(:src="img")
</template>

<script>
export default {
  name: 'Tile',
  emits: ['click'],
  props: {
    title: {
      default: '',
      type: String
    },
    img: {
      default: null,
      type: String
    },
    link: {
      default: null,
      type: String
    },
    id: {
      default: '',
      type: String,
      required: true
    },
    clickedValue: {
      default: null
    }
  },
  methods: {
    onClick() {
      this.$emit('click', {id: this.id, clickedValue: this.clickedValue})
    }
  }
}
</script>

<style lang="scss" scoped>
.tile {
  margin: 24px;
  cursor: pointer;
  background-color: $white;
  border-radius: 12px;

  .title {
    position: absolute;
    padding: 8px 12px;
    color: $iced-slate;
    text-shadow:1px 1px 8px $white, -1px -1px 8px $white;
  }

  img {
    border: 1px solid $iced-slate;
    border-radius: 12px;
  }

}

.responsive {
  height: 200px;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  @media only screen and (max-width: 651px) {
    height: 150px;
    width: 150px;
  }
  @media only screen and (max-width: 412px) {
    height: 115px;
    width: 115px;
    font-size: 10px;
    &.title {
      padding: 4px 8px;
    }
  }
}
</style>