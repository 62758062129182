<template lang="pug">
.line
  .rows(v-if="type == 'backordered'")
    .row
      .item-number(@click="goToProduct(value.productId)") {{ '#' + value.productId }}
        .item-special-order(v-if="value.backOrderStatus")
          .separator -
          .text {{ value.backOrderStatus }}
      .item-shipped-from {{ getShippedFrom(value.allocatedWarehouse, value.expectedReceiptDate) }}
    .row
      .item-title {{ value.description ? value.description : "No Description" }}
      .item-qty x{{ value.quantity }}
  .rows(v-else="")
    .row
      .item-number(@click="goToProduct(value.productId)") {{ '#' + value.productId }}
      .item-qty x{{ value.quantity }}
    .row
      .item-title {{ value.description }}
</template>

<script>
  import { mapGetters } from 'vuex'
  import { goToProduct } from '../utility/Util.js'  

  export default {
    name:'OrderLine',
    model: {
      prop: 'value', 
      event: 'update'
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        required: true,
        validator: (value) => {
          return ['processing', 'shipment', 'backordered', 'canceled', 'pending', 'submitted'].includes(value)
        }
      }
    },
    methods: {
      getShippedFrom (allocatedWarehouse, expectedReceiptDate) {
        if (allocatedWarehouse) {
          if (allocatedWarehouse.trim().startsWith(',')) {
              allocatedWarehouse = allocatedWarehouse.substring(1)
          }
          allocatedWarehouse = allocatedWarehouse.trim()
        }
        allocatedWarehouse = allocatedWarehouse ? allocatedWarehouse +  ' - ' : ''
        let shippedFrom = allocatedWarehouse + (expectedReceiptDate ? this.formatDate(expectedReceiptDate) : 'Date Pending')
        return shippedFrom
      },
      formatDate (date) {
        return new Date(date).toLocaleDateString('en-us', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
      },
      goToProduct (id) {
        goToProduct(this.$router, this.customer.CustomerID, id)
      }
    },
    computed: {
      ...mapGetters([
        'customer'
      ]),
    }
  }
</script>

<style scoped lang="scss">
  .line {
    padding: 6px 18px;
    margin: 0 8px;

    .row {
      display: flex;
      justify-content: space-between;
      background-color: $white;

      .item-number {
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .item-special-order {
        display: inline-flex;
        padding-left: 1px;
        .separator {
          padding: 0 5px;
        }
        .text {
          color: $sunrise;
        }
      }

      .item-title {
        padding-right: 10px;
        flex-grow: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-shipped-from {
        display: inline-block;
      }

      .item-qty {
        white-space: nowrap;
        display: inline-block;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }
  }
</style>