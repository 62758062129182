<template lang="pug">
.image-display(:style="{backgroundImage: backgroundImage}")
  img(v-if="!isThumbnail || isStoreImage" :src="displayUrl" :width="isStoreImage ? width : null" :class="{'image-dimensions': !isStoreImage}")
</template>

<script setup>
import { computed } from "vue";
import ImagePlaceholder from '@/assets/missing-image.png'

const props = defineProps({
  modelValue: {
    type: String
  },
  width: {
    type: Number,
    default: 232
  },
  height: {
    type: Number,
    default: 232
  }, 
  isThumbnail: {
    type: Boolean,
    default: false
  }
    
})

const minCssWidth = computed(() => `${props.width}px`)
const missingImage = 'missing-image'
const cssWidth = computed(() => props.isThumbnail ? `${props.width}px` : 'auto')
const cssHeight = computed(() => props.isThumbnail ? `${props.height}px` : 'auto')

const backgroundImage = computed(() => {
  return props.isThumbnail && !isStoreImage.value ? `url(${displayUrl.value})` : 'none'
})

const displayUrl = computed(() => {
  let url = props.modelValue
  url ??= ImagePlaceholder
  if (!isStoreImage.value) {
    url = `${url}&width=${props.width}&height=${props.height}`
  }
  return url
})
  
const isStoreImage = computed(() => 
  !!props.modelValue?.includes('store.burkhartdental.com') || props.modelValue == null || props.modelValue?.includes(missingImage))  

</script>

<style lang="scss" scoped>

  .image-display {
    display: flex;
    justify-content: center;
    height: auto;
    top: 0;
    bottom: 0;
    width: v-bind(cssWidth);  
    height: v-bind(cssHeight);
    min-width: v-bind(minCssWidth);
    background-repeat: no-repeat;
    background-position: center;    

    .image-dimensions {
      max-width: v-bind(cssWidth);
    }
  }


</style>
