import { httpInterceptor, emitter, EventTypes } from '@burkhart/vue-components'

const state = () => ({
  itemTagRequests: []
})

const getters = {  
  hasTagRequest: (state) => (itemNumber) => {
    return !!state.itemTagRequests.find(t => t == itemNumber)
  },
  tagRequests (state) {
    return state.itemTagRequests
  }
}

const mutations = {
  toggleTagRequest (state, {itemNumber, removeOnly = false}) {
    const index = state.itemTagRequests.indexOf(itemNumber)
    if (index >= 0) {
      state.itemTagRequests.splice(index, 1)
    } else if (!removeOnly) {
      state.itemTagRequests.push(itemNumber)
    }
  }, 
  clearTagRequests (state) {
    state.itemTagRequests.length = 0
  },
  setTagRequests (state, itemTagRequests) {
    state.itemTagRequests.length = 0
    state.itemTagRequests = [...itemTagRequests]
  },
  addTagRequests (state, tagRequests) {
    state.itemTagRequests.push(...tagRequests)
  }
}

const actions = {
  async toggleTagRequest (context, itemNumber) {    
    context.commit('toggleTagRequest', {itemNumber, removeOnly: false})    
    context.dispatch('updateTagRequests', [itemNumber])
  },
  async updateTagRequests (context, updateList) {
    let hasError = false    
    await context.dispatch('syncTagRequests', updateList)
    try {
      await httpInterceptor.interceptor.post('itemtagrequest', {
        "CustomerNumber": context.rootGetters.customerId,
        "ItemNumbers": context.state.itemTagRequests
      })
    } catch (error) {
      console.error(error)
      hasError = true
    }
    return hasError
  }, 
  toggleAllTagRequests (context, itemList) {
    const itemListCount = itemList?.length ?? 0
    const currentTagRequests = context.state.itemTagRequests.length
    context.commit('clearTagRequests')
    if (itemListCount != currentTagRequests) {
      context.commit('setTagRequests', itemList)
    }
    context.dispatch('updateTagRequests', itemList)
  },
  async submitTagRequests (context) {
    let hasError = await context.dispatch('updateTagRequests')

    if (!hasError && context.state.itemTagRequests?.length > 0) {
      try {
        await httpInterceptor.interceptor.post(`itemtagrequest/customer/${context.rootGetters.customerId}/submit`)      
        context.commit('clearTagRequests')
        emitter.emit(EventTypes.SuccessNotificationEvent, 'Tags submitted succesfully')        
      } catch (error) {
        console.error(error)
        hasError = true
      }
    }
    if (hasError) {
      emitter.emit(EventTypes.ErrorEvent, 'There was problem submitting the tag requests.')
    }
  },
  async loadTagRequests (context, customerId) {
    const tagRequests = await context.dispatch('getTagRequests', customerId)
    context.commit('setTagRequests', tagRequests)      
  },
  removeTagRequest (context, itemNumber) {
    context.commit('toggleTagRequest', {itemNumber, removeOnly: true})
  },
  async getTagRequests (context, customerId) {
    let tagRequests = null
    try {
      let response = await httpInterceptor.interceptor.get(`/itemtagrequest/customer/${customerId}`)
      tagRequests = response.data?.itemNumbers ?? []      
    } catch(error) {
      console.error(error)
    }

    return tagRequests
  },
  /**
   * Gets any tag requests stored in the database and synchronizes the list with the current tag request list. 
   * The state of any tag request that was modified will be preserved. If the update list is null then
   * the tag list will be refreshed with what is in the database. 
   * @param {object} context 
   */
  async syncTagRequests (context, updateList = null) {
    const storedTagRequests = await context.dispatch('getTagRequests', context.rootGetters.customerId)

    let synchronizedTagRequests = storedTagRequests ?? []
    if (updateList) {      
      const selectedTagRequests = updateList.filter(t => context.getters.tagRequests.includes(t))
      const deselectedTagRequests = updateList.filter(t => !context.getters.tagRequests.includes(t))
      selectedTagRequests.forEach(t => {
        if (!synchronizedTagRequests.includes(t)){
          synchronizedTagRequests.push(t)
        }      
      })
      
      deselectedTagRequests.forEach(t => {
        const index = synchronizedTagRequests.findIndex(f => f == t)
        if (index >= 0){
          synchronizedTagRequests.splice(index, 1)
        }            
      })
    }


    context.commit('setTagRequests', synchronizedTagRequests)
  }
}

export default { state, getters, mutations, actions }