<template lang="pug">
.product-description
  .label {{ modelValue.description }}
  .description(v-html="displayDescription")
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object
  },
  compactView: {
    type: Boolean,
    default: false
  }
})

const displayDescription = computed(() => {
  let description = ''
    if (hasAttributes.value) {          
      const catalogDescription = _getDescriptionFromAttribute('Burkhart.DataManagementTool.Contracts.CatalogAttribute', 'Description')
      const manufacturerDescription = _getDescriptionFromAttribute('Burkhart.DataManagementTool.Contracts.ManufacturerAttribute', 'LongDescription')      
      description = props.compactView ? catalogDescription : (manufacturerDescription ?  manufacturerDescription : catalogDescription)
    }

  return description  
})

const hasAttributes = computed(() => props.modelValue.attributes?.length > 0)

const _getDescriptionFromAttribute = (attributeName, field) => {
  let description = null
  const attribute = props.modelValue.attributes.find(c => c.attributeName == attributeName)        
  if (attribute && attribute.data?.Data) {
    description = attribute.data.Data[field]
  }
  return description
}
</script>

<style scoped lang="scss">
.product-description {  
  .label {
    font-weight: bold;
    padding-bottom: 8px;
  }  
}

</style>