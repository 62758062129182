<template lang="pug">
.product-sds(v-if="hasSds")
  bd-button(icon="description" @click="showSds") Safety Data Sheet
</template>

<script setup>
import { computed } from 'vue'
import { getSdsUrlFromProduct } from '../utility/Util.js'


const props = defineProps({
  modelValue: {
    type: Object
  }
})

const hasSds = computed(() => getSdsUrlFromProduct(props.modelValue))
const showSds = () => window.open(getSdsUrlFromProduct(props.modelValue), '_blank')
</script>

<style scoped lang="scss">
.product-sds {  
  .bd-button {    
    color: $iced-slate;
  }
}

</style>