<template lang="pug">
.cart-details
  bd-modal.submitting(v-model:show="isCartSubmitting")
    template(v-slot:content="")
      .modal-content
        .message Submitting cart
          ui-spinner(:size="'small'" active)
  bd-modal(v-model:show="showCartOutOfSyncModal" :firstFocusElement="outOfSyncConfirmButton" 
      :lastFocusElement="outOfSyncConfirmButton"
      @focusFirstElement="outOfSyncConfirmButton.focus()"
      @focusLastElement="outOfSyncConfirmButton.focus()")
      template(v-slot:content="")
        .modal-content 
          .message {{ outOfSyncMessage }}
          .buttons         
            bd-button.out-of-sync(raised @click="closeOutOfSyncModal()" ref="outOfSyncConfirmButton") Ok
  bd-modal(v-model:show="showModal" :firstFocusElement="cancelButton" 
    :lastFocusElement="continueButton"
    @focusFirstElement="cancelButton.focus()"
    @focusLastElement="continueButton.focus()")
    template(v-slot:content="")
      .modal-content 
        .message(v-if="!checkoutActive") You have unredeemed coupons for the following items:
          .undredeemed-item(v-for="(item, index) in unredeemedItems")
            svg(width="20" height="16" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg")
              path.coupon-icon(class="incomplete-redemption" d="M37 32H3C2.175 32 1.46875 31.7062 0.88125 31.1187C0.29375 30.5312 0 29.825 0 29V21.35C1.23333 21.0833 2.25833 20.4583 3.075 19.475C3.89167 18.4917 4.3 17.3333 4.3 16C4.3 14.6667 3.89167 13.5 3.075 12.5C2.25833 11.5 1.23333 10.8833 0 10.65V3C0 2.175 0.29375 1.46875 0.88125 0.88125C1.46875 0.29375 2.175 0 3 0H37C37.825 0 38.5313 0.29375 39.1188 0.88125C39.7063 1.46875 40 2.175 40 3V10.65C38.7667 10.8833 37.7417 11.5 36.925 12.5C36.1083 13.5 35.7 14.6667 35.7 16C35.7 17.3333 36.1083 18.4917 36.925 19.475C37.7417 20.4583 38.7667 21.0833 40 21.35V29C40 29.825 39.7063 30.5312 39.1188 31.1187C38.5313 31.7062 37.825 32 37 32ZM37 29V23.55C35.7333 22.6833 34.7 21.6 33.9 20.3C33.1 19 32.7 17.5667 32.7 16C32.7 14.4333 33.1 13 33.9 11.7C34.7 10.4 35.7333 9.31667 37 8.45V3H3V8.45C4.3 9.31667 5.34167 10.4 6.125 11.7C6.90833 13 7.3 14.4333 7.3 16C7.3 17.5667 6.90833 19 6.125 20.3C5.34167 21.6 4.3 22.6833 3 23.55V29H37Z")
              path.coupon-icon(class="incomplete-redemption" d="M12.6682 14.3438C11.3769 14.3438 10.276 13.8873 9.36563 12.9745C8.45521 12.0617 8 10.9596 8 9.66824C8 8.37691 8.45642 7.27604 9.36925 6.36562C10.2821 5.45521 11.3842 5 12.6755 5C13.9668 5 15.0677 5.45642 15.9781 6.36926C16.8885 7.28209 17.3438 8.38417 17.3438 9.6755C17.3438 10.9668 16.8873 12.0677 15.9745 12.9781C15.0617 13.8885 13.9596 14.3438 12.6682 14.3438ZM12.6676 12.1875C13.3653 12.1875 13.9596 11.9433 14.4508 11.455C14.9419 10.9667 15.1875 10.3737 15.1875 9.67612C15.1875 8.9785 14.9433 8.38411 14.455 7.89297C13.9667 7.40182 13.3737 7.15625 12.6761 7.15625C11.9785 7.15625 11.3841 7.40041 10.893 7.88873C10.4018 8.37707 10.1562 8.97004 10.1562 9.66763C10.1562 10.3653 10.4004 10.9596 10.8887 11.4508C11.3771 11.9419 11.97 12.1875 12.6676 12.1875ZM26.3245 28C25.0332 28 23.9323 27.5436 23.0219 26.6307C22.1115 25.7179 21.6562 24.6158 21.6562 23.3245C21.6562 22.0332 22.1127 20.9323 23.0255 20.0219C23.9383 19.1115 25.0404 18.6562 26.3318 18.6562C27.6231 18.6562 28.724 19.1127 29.6344 20.0255C30.5448 20.9383 31 22.0404 31 23.3318C31 24.6231 30.5436 25.724 29.6307 26.6344C28.7179 27.5448 27.6158 28 26.3245 28ZM26.3239 25.8438C27.0215 25.8438 27.6159 25.5996 28.107 25.1113C28.5982 24.6229 28.8438 24.03 28.8438 23.3324C28.8438 22.6347 28.5996 22.0404 28.1113 21.5492C27.6229 21.0581 27.03 20.8125 26.3324 20.8125C25.6347 20.8125 25.0404 21.0567 24.5492 21.545C24.0581 22.0333 23.8125 22.6263 23.8125 23.3239C23.8125 24.0215 24.0567 24.6159 24.545 25.107C25.0333 25.5982 25.6263 25.8438 26.3239 25.8438ZM9.50937 28L8 26.4906L29.4906 5L31 6.50938L9.50937 28Z")
            .item {{item}}
        .message(v-else="") Adding {{ reviewReason }} will cause this order to be held for review
        .buttons         
          bd-button(icon='chevron_left' raised @click="cancelCheckout" ref="cancelButton") {{ popupCancelText }}
          bd-button(:icon="popupContinueIcon" raised @click="modalContinue" ref="continueButton") Continue    
  .checkout(:class="{show: checkoutActive}")
    .item-list
      .header
        bd-button(icon='arrow_back_ios' @click="closeCheckout")
        .buttons          
          bd-button(icon='picture_as_pdf' raised @click="generatePDF" :disabled="generatingPDF") PDF        
          bd-button(icon='send' raised @click="debounceSubmit" :disabled="!submitEnabled || !isPOrequirementMet") Submit
      .item-list-field
        .label Order Notes
        textarea.input(rows="8" v-model="notes")
      .item-list-field(v-if="!isCustomerUser")
        .label Unspecified Product
        textarea.input(rows="1" v-model="usp" maxlength="100")        
      .item-list-field
        .label PO#
          .required(v-if="isPOrequired") &nbsp;{{ requiredFieldLabel }}      
        .container
          input.input(:value="po" @input="po=$event.target.value")
      .item-list-field
        .container(v-if="!isCustomerUser")
          .container-group
            bd-date-picker(@change="onFutureFillChange" :value="calendarPickerFutureFillDate" :minDate="getFutureFillMinDate()" 
              :mode="'single'" noMaxDateLimit :hasError="futureFillError" :hoverColor="'#C6D966'"
              :outputFormattedData="false")
            .label Future Fill                                          
          .container-group            
            input.free-shipping(v-model="hasFreeShipping" type="checkbox")        
            .label Free&nbsp;Shipping                  
      .item-list-field
        .label Order Summary
        .item-container(v-for="(item, index) in items")
          .item
            .item-info {{(index + 1) + ")"}} {{ item.itemNumber }} {{ item.description }}
            .qty-info
              .tag-request
                svg(width="22" height="21" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isTagRequested(item)")
                  path(d="M20.2 31.5L9 20.3L11.15 18.15L20.2 27.2L39.4 8L41.55 10.15L20.2 31.5Z" fill="#36667D")
                  path(d="M23.95 39.15C23.35 39.75 22.625 40.05 21.775 40.05C20.925 40.05 20.2 39.75 19.6 39.15L0.85 20.4C0.516667 20.0667 0.291667 19.7167 0.175 19.35C0.0583333 18.9833 0 18.6 0 18.2V3C0 2.13333 0.283333 1.41667 0.85 0.85C1.41667 0.283333 2.13333 0 3 0H18.2C18.6 0 19 0.0583333 19.4 0.175C19.8 0.291667 20.1667 0.516667 20.5 0.85L39.15 19.5C39.7833 20.1333 40.1 20.875 40.1 21.725C40.1 22.575 39.7833 23.3167 39.15 23.95L23.95 39.15ZM21.9 37.1L37.1 21.9L18.2 3H3V18.2L21.9 37.1ZM8.25 10.8C8.95 10.8 9.55833 10.5417 10.075 10.025C10.5917 9.50833 10.85 8.9 10.85 8.2C10.85 7.5 10.5917 6.89167 10.075 6.375C9.55833 5.85833 8.95 5.6 8.25 5.6C7.55 5.6 6.94167 5.85833 6.425 6.375C5.90833 6.89167 5.65 7.5 5.65 8.2C5.65 8.9 5.90833 9.50833 6.425 10.025C6.94167 10.5417 7.55 10.8 8.25 10.8Z" fill="#173442")
              .qty-price
                .qty Qty {{ item.quantity }}
                .price {{ currencyFormatter( (actualItemPrice(item)) || 0)  }}
        .total Total: {{ currencyFormatter(cartTotal || 0)  }}
  .cart(ref="cartDiv")
    .header(ref="cartHeaderDiv")
      .content
        .total Total: {{ currencyFormatter(cartTotal || 0)  }}
        .buttons
          bd-button(icon='shopping_cart_checkout' raised @click="checkout" :disabled="cart.length < 1") checkout
      .cart-header
        .header-left
          quick-order(:customerNumber="customerId") 
        .header-right
          bd-button.priceoverride(v-if="!isCustomerUser && cart.length > 0" :class="{ 'price-override-enabled': priceOverrideActive }" :icon="priceOverrideIcon" @click="toggleOverridePrices")
            .override-label price override 
          bd-button(v-if="cart.length > 0" icon='sell' @click="toggleAllTagsClicked" :disabled="priceOverrideActive") 
            .request-text request all tags      
    .no-items(v-if="cart.length < 1")    
      .no-items-message No Items In Cart          
    .items(v-else="" ref="itemsDiv")
      CartItem(v-for="item in cart" :item="item" :priceOverrideActive="priceOverrideActive")
</template>

<script>
import CartItem from '@/components/CartItem'
import QuickOrder from '@/components/QuickOrder'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { debounce } from 'throttle-debounce'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { v4 as uuid } from 'uuid'
import { EmittedEvents } from '@/utility/Constants.js'
import { EventTypes } from "@burkhart/vue-components";

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'Cart',
  components: {
    CartItem,
    QuickOrder
  },
  props: {
    id: String
  },

  setup() {
    const currencyFormatter = inject('currencyFormatter')
    const goToProduct = inject('goToProduct')
    const emitter = inject('emitter')
    const router = useRouter()
    const showModal = ref(false)
    const cancelButton = ref({})
    const continueButton = ref({})
    const dateFormatter = inject('dateFormatter')
    const { interceptor } = inject('httpInterceptor')
    const showCartOutOfSyncModal = ref(false)
    const outOfSyncConfirmButton = ref()    
    const isComponentActive = ref(false)
    const outOfSyncMessage = ref()
    const cartAlreadySubmitted = ref(false)
    const isCartSubmitting = ref(false)
    const cartHeaderDiv = ref(null)
    const itemsMargin = ref(null)
    const cartHeaderWidth = ref(null)
    const itemsDiv = ref(null)
    const resizeObserver = null
    const cartDiv = ref(null)

    return {
      currencyFormatter,
      goToProduct,
      router,
      showModal,
      cancelButton,
      continueButton,
      dateFormatter, 
      interceptor,      
      emitter,      
      showCartOutOfSyncModal,
      outOfSyncConfirmButton,      
      isComponentActive,
      outOfSyncMessage,
      cartAlreadySubmitted,
      isCartSubmitting,
      cartHeaderDiv,
      itemsMargin,
      cartHeaderWidth,
      itemsDiv,
      resizeObserver,
      cartDiv
    }
  },
  data() {
    return {
      checkoutActive: false,
      submitEnabled: true,
      requiredFieldLabel: ' (required)',
      generatingPDF: false,
      priceOverrideActive: false,
      pricesOverriddenOnOrder: false
    }
  },
  watch: {
    cart (newCart, oldCart) {
      this.updateCartDiv()
    }
  },
  activated () {
    this.isComponentActive = true
  },
  deactivated () {
    this.isComponentActive = false
  },
  mounted () {    
    this.updateCartDiv()
    this.resizeObserver.observe(this.cartDiv)
  },
  beforeMount () {
      this.emitter.on(EmittedEvents.CartOutOfSyncEvent, () => {
        this.onOutOfSync(EmittedEvents.CartOutOfSyncEvent)       
      })

      this.emitter.on(EmittedEvents.CartAlreadySubmittedEvent, () => {
        this.onOutOfSync(EmittedEvents.CartAlreadySubmittedEvent)       
      })
      
      this.resizeObserver = new ResizeObserver(this.updateCartDiv)
  },
  beforeUnmount () {
    this.emitter.off(EmittedEvents.CartOutOfSyncEvent)
    this.emitter.off(EmittedEvents.CartAlreadySubmittedEvent)    
    this.resizeObserver.unobserve(this.cartDiv)

  },
  methods: {
    ...mapActions(['clearCart', 'updateComments', 'updatePoNumber', 
      'submitOrder', 'deleteCart', 'updateRemoteCart', 'toggleAllTagRequests',
      'submitTagRequests', 'loadCart', 'syncCart', 'syncTagRequests']),
    ...mapMutations(['updateFreeShipping', 'updateAccountManagerNotes', 'updateFutureFillDate']),
    async updateCartDiv () {
      //need to make sure the items div loads
      await this.$nextTick()

      this.cartHeaderWidth = this.itemsDiv ? `${this.itemsDiv.clientWidth}px` : '100%'
      //make sure the cart header width is applied before setting the items margin
      await this.$nextTick()

      this.itemsMargin = `${this.cartHeaderDiv.clientHeight}px`
      
    },
    async checkout () {
      await this.syncCart()
      await this.syncTagRequests()
      if (this.cart.length > 0) {
        if (this.unredeemedRedemptions?.length > 0) {
          this.showModal = true
        } else {
          this.checkoutActive = true
        }
      }
    },
    async submit () {
      this.isCartSubmitting = true
      const versionInfo = (await this.interceptor.get(
        `${process.env.VUE_APP_SPROCKET_API}/version?key=appversion`)).data          

      if (versionInfo != null && versionInfo != process.env.VUE_APP_VERSION) {        
        this.emitter.emit(EmittedEvents.AppOutOfSyncEvent, 
          `Please update to toolkit version ${versionInfo} before submitting your cart`
        )
      } else {
          if (this.validateCart()) {

            const isOutOfSyncWhileSubmitting = await this.syncCart()

            if (!isOutOfSyncWhileSubmitting) {              
              await this.submitOrder()
              await this.submitTagRequests()
              this.checkoutActive = false
              this.priceOverrideActive = false                            
            }
          }
      }
      this.submitEnabled = true
      this.isCartSubmitting = false      
    },
    async onSubmitClick () {
      this.submitEnabled = false
      if (!this.orderHasDelay || this.isCustomerUser) {
          await this.submit()
      } else {
        this.showModal = true
      }
    },
    debounceSubmit: debounce(200, async function() {
      await this.onSubmitClick()
    }),
    closeCheckout () {
      this.checkoutActive = false
      this.updateRemoteCart()
    }, 
    toggleAllTagsClicked: debounce(200, function () {
      this.toggleAllTagRequests(this.standardItemNumbers)
    }),
    isTagRequested (item) {
      return item.type == 0 && this.hasTagRequest(item.itemNumber)
    },
    modalContinue () {
      this.showModal = false
      if (this.checkoutActive) {
        this.submit()
      } else {
        this.checkoutActive = true
        this.submitEnabled = true
      }
      
    },
    cancelCheckout () {
      this.showModal = false
      this.submitEnabled = true
    },
    toggleOverridePrices () {
      this.priceOverrideActive = !this.priceOverrideActive;
    },
    generatePDF () {
      this.generatingPDF = true
      
      const topAndBottomBorder = [false, true, false, true]
      const noBorder = [false, false, false, false]
      const labelWidth = 80
      const blankValue = { text: '', border: noBorder}
      const currentDate = new Date().toLocaleString()

      let doc = {
        info: {
          title: 'Burkhart Dental Supply Order',
          subject: 'Shopping Cart',
          author: 'Burkhart Dental Supply'
        },
        content: [
          {
            text: 'Burkhart Dental Supply',
            style: 'header',
          },          
          {
            bold: true, 
            text: 'Shopping Cart',
            style: 'header',
            fontSize: 16,            
          },
          {
            bold: true,
            margin: [0, 10],
            columns: [              
                {
                  width: labelWidth,
                  text: 'Phone:'
                }, 
                { 
                  width: 125,                 
                  text: (this.customer.ShipToPhoneNumber)
                }, 
                {
                  width: 200,
                  columns: [
                    {
                      width: 65,
                      text: 'Customer:'
                    }, 
                    {                      
                      text: this.customer.CustomerID
                    }, 
                  ]
                }, 
                {
                  columns: [
                    {
                      width: 40,
                      text: 'Date:'                      
                    }, 
                    {
                      text: this.dateFormatter(currentDate)
                    }
                  ]
                } 
            ]
          },
          {
            alignment: 'left',
            bold: true,
            margin: [0, 10],
            columns: [
              {
                width: labelWidth,
                stack: [                  
                  {
                    text: 'Bill To:'
                  }, 
                  {
                    text: 'Ship To:'
                  },
                  {
                    text: 'PO Number:'
                  },
                  {
                    text: 'Ship Via:'
                  } 
                ], 
              },
              {
                bold: true,
                stack: [               
                  {
                    text: ((this.customer.BillToName ? this.customer.BillToName : this.customer.Name) ?? '') + '\n'
                  }, 
                  {
                    text: (this.customer.Name ?? '') + '\n'
                  },
                  {
                    text: (this.po ?? '') + '\n'
                  },
                  {
                    text: (this.customer.ShipViaNorm ?? '') + '\n'
                  }
                ]                
              }                            
            ]
          },
          {            
            table: {
              headerRows: 1,
              widths: [25, 25, 65, 285, 65],
              body: [
                [
                  {                    
                    text: 'Line',
                    border: topAndBottomBorder
                  }, 
                  {                    
                    text: 'Qty',
                    border: topAndBottomBorder                  
                  },
                  {                    
                    text: 'Product',
                    border: topAndBottomBorder                  
                  }, 
                  {                    
                    text: 'Description',
                    border: topAndBottomBorder                  
                  },
                  {                    
                    alignment: 'right',
                    text: 'Price',
                    border: topAndBottomBorder                  
                  }, 
                ],
                ...this.getPDFdata(noBorder),
                [
                  blankValue,
                  blankValue,
                  blankValue,
                  { 
                    text: 'Total', alignment: 'right', 
                    border: noBorder},
                  {
                    border: [false, true, false, false],
                    alignment: 'right',
                    text: `${this.currencyFormatter(this.cartTotal || 0)}`
                  }
                ]
              ]
            }
          }
        ],
        footer: (currentPage, pageCount) => {
          return [
            {
              text: `Page ${currentPage} of ${pageCount}`,
              style: 'footer'
            },
            {
              text: this.cartKey,
              fontSize: 8,
              style: 'footer',
              alignment: 'right',
              margin: [0, 0, 45, 0]
            }
          ]
        },
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'center',
            
          },
          footer: {
            bold: true, 
            alignment: 'center'
          }
        }
      }      
      pdfMake.createPdf(doc).download(`${uuid()}.pdf`)

      this.generatingPDF = false
    },
    getPDFdata (border) {      
      return this.items.map((item, index) => {
        return [
          {            
            text:`${index + 1})`,
            border: border,
            alignment: 'right'
          },
          {            
            text: `${item.quantity}`,
            border: border,
            alignment: 'right'
          },
          {            
            text: item.itemNumber,
            border: border,
            alignment: 'left'
          },
          {            
            text: item.description,
            border: border,
            alignment: 'left'
          },
          {            
            text: `${this.currencyFormatter(((this.actualItemPrice(item)) * item.quantity) || 0)}`,
            border: border,
            alignment: 'right'
          }
        ]
      })
    },
    onFutureFillChange (value) {
      const fillDate = value?.start 
      this.calendarPickerFutureFillDate = fillDate
      this.validateFutureFillDate()      
    }, 
    getFutureFillMinDate () {
      let date = new Date()
      date.setDate(date.getDate() + 1)
      return date
    },
    validateFutureFillDate () {
      if (this.futureFillError) {
        let message = this.isCustomerUser ? 'The order has an invalid future fill date. Please contact your account manager' 
          : 'Future fill date must be after today'
        this.emitter.emit(EventTypes.ErrorEvent, message);        
      }
    },
    validateCart () {      
      this.validateFutureFillDate()
      return !this.futureFillError
    },
    async closeOutOfSyncModal () {
      if (this.cartAlreadySubmitted) {
        await this.updateRemoteCart()
      }
      this.checkoutActive = this.checkoutActive && this.cart.length > 0
      this.showCartOutOfSyncModal = false
    }, 
    onOutOfSync (event) {
      if (this.isComponentActive && !this.showCartOutOfSyncModal) {
        this.cartAlreadySubmitted = false

        switch (event) {
          case EmittedEvents.CartAlreadySubmittedEvent:
            this.outOfSyncMessage = 'This cart has already been submitted in another window. Your cart will need to be refreshed.'            
            this.cartAlreadySubmitted = true
            this.checkoutActive = false
            break;
          case EmittedEvents.CartOutOfSyncEvent:
            this.outOfSyncMessage = 'Your cart has been updated with changes made outside of this window.'
              + ' Please review before submitting.'
            break;
        }

        this.showCartOutOfSyncModal = true
      }              
    }
  },
  computed: {
    ...mapGetters(['items', 'cart', 'cartTotal', 'poNumber', 
      'comments', 'customerId', 'standardItemNumbers', 'hasTagRequest', 
      'couponInfo', 'customer', 'cartKey', 'accountManagerNotes', 'freeShipping',
      'isCustomerUser', 'futureFillDate', 'actualItemPrice']),
    notes: {
      get() {
        return this.comments
      },
      set(value) {
        this.updateComments(value)
      }
    },
    po: {
      get() {
        return this.poNumber
      },
      set(value) {
        this.updatePoNumber(value)
      }
    },
    hasFreeShipping: {
      get () {
        return this.freeShipping
      },
      set (value) {        
        this.updateFreeShipping(value)
      }
    },
    usp: {
      get () {
        const usp = (this.accountManagerNotes?.length > 0) ? this.accountManagerNotes[0].note : ''
        return usp
      },
      set (value) {
        this.updateAccountManagerNotes(value)
      }
    },
    orderHasDelay () {
      return !!this.usp || !!this.hasFreeShipping || !!this.hasPricesOverridden
    },
    calendarPickerFutureFillDate: {
      get () {
        return { start: this.futureFillDate, end: null}
      },
      set (value) {        
        this.updateFutureFillDate(value)
      }
    },  
    unredeemedRedemptions () {
      return this.couponInfo?.filter(c => !c.isRedemptionComplete)
    },
    unredeemedItems () {
      return this.unredeemedRedemptions?.reduce((previous, current) => 
        previous.concat(current.items), [])
    }, 
    isPOrequired () {
      return (this.customer.IsPORequired?.toUpperCase() == "Y")
    }, 
    isPOvalid () {
      return (this.poNumber?.trim() ?? '') != ''
    },    
    isPOrequirementMet () {                  
      let valid = (!this.isPOrequired || this.isPOvalid)
      return valid
    }, 
    popupCancelText () {      
      return this.orderHasDelay ? 'Back to Checkout': 'Back to Cart'
    }, 
    popupContinueIcon () {
      return this.orderHasDelay ? 'send' : 'shopping_cart_checkout'
    },
    futureFillError () {
      return !!(this.futureFillDate && new Date(this.futureFillDate) <= new Date())
    },
    reviewReason () {
      let reasons = !!this.hasFreeShipping || !!this.usp ? "an unspecified product or free shipping" : "pricing overrides" 
      return reasons
    },
    priceOverrideIcon () {
      return this.priceOverrideActive ? 'toggle_on_icon' : 'toggle_off_icon'
    },
    hasPricesOverridden () {      
      let value = this.items.some(o => o.hasPriceOverride)
      return value
    }
  }

}
</script>

<style scoped lang="scss">

.override-label {
  color: $deep-sea;
}
.price-override-enabled:has(.material-icons) {
  color: $zest;
}

.cart-details {  
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  display: flex;

  :deep(.bd-modal .overlay) {
    z-index: 999999 !important;    
  }  

  :deep(.submitting .overlay) {
    position: fixed !important;
    top: 0;
    left: 0;
  }  

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1em;

    .incomplete-redemption {
      color: $grapefruit;
      fill: $grapefruit;      
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 24px;
      flex-wrap: wrap;
      .bd-button {
        padding: 4px;

      }
      .bd-button.out-of-sync {
          padding: 4px 24px;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      .undredeemed-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
.cart {
  flex: 1 1 auto;  
  height: 100%;
  display: flex;  
  overflow: auto;
  justify-content: center;
  align-content: center;
  width: 100%;
  overscroll-behavior: none;
  .header {
    position: absolute;
    width: v-bind(cartHeaderWidth);
    max-width: v-bind(cartHeaderWidth);
    z-index: 1;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;    

    .content {
      display: flex;
      flex: 1 1;
      justify-content: space-between;
      align-items: center;
      color: $white;
      padding: 8px;
      max-width: 800px;
      margin: auto;
      background-color: lighten($iced-slate, 25);
      width: 100%;

      .buttons {
        display: flex;
        flex-direction: row;
      }
    }

    .cart-header {
      background-color: $white;
      border: 1px solid #173442;
      border-top: 0;
      max-width: 800px;
      width: 100%;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
      flex: 1 0;
      margin: auto;
      font-size: 12px;

      .header-right {
        display: flex;
        align-items: center;
      }

      .bd-button {
        white-space: normal;
        text-align: left;        
      }
    }     
  
  }

     
  .no-items {
    display: flex;
    margin-top: v-bind(itemsMargin);
    flex-direction: column;
    .no-items-message {
      text-align: center;
      font-size: 26px;
      margin: 50px 0;
      color: $grapefruit;
    }
  }

  .items {
    margin-bottom: 10px;
    width: 100%;
    margin-top: v-bind(itemsMargin);
    z-index: 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    
    
    .bd-button {
      width: 175px;
    }
  }
}
.checkout {
  background-color: $white;
  width: 100%;  
  height: 100%;
  left: 100%;
  position: absolute;
  overflow: hidden;
  transition: 0.3s ease-out;
  z-index: 100;  


  &.show {
    overflow: auto;
    width: 100%;
    left: 0;
  }

  .input {
    border-radius: 12px;
    padding: 4px;
    resize: vertical;
    border-width: 1px;
    font-family: monospace;
  }

  .item-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 8px;
    font-size: 12px;
    .header {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;     

      .buttons {
        display: flex;
        gap: 4px;
      }
    }
    .item-list-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      .container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        
        .input {
          width: 100%;
        }
        .container-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          justify-content: flex-start;
          color: $label;          
          input.free-shipping {
            width: 24px;
            height: 24px;            
            accent-color: $label;
          }
        }        
      }

      .label {
        color: $label;              
        display: flex;
        flex-direction: row;
      }

      .required {
        color: $sunrise;
      }

      .item-container{      
        &:nth-of-type(2) {
          border-top: 1px solid $teal;
        }
        .item {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          background-color: $white;
          padding: 8px;
          border-width: 0 1px 1px 1px;
          border-style: solid;
          border-color: $teal;
          color: $deep-sea;
          
          .qty-info {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            width: 75px;
            align-items: center;
            .qty-price {
              display: flex;
              justify-content: flex-end;
              flex-flow: column wrap;
            }
            .price, .qty {
              align-self: flex-end;            
            }
          }
        }
      }      
    }
    .total {
      text-align: end;
      padding: 8px 9px;
      font-size: 16px;
      color: $deep-sea;
    }
  }
}
</style>