<template lang="pug">
.app-container
  bd-modal(v-model:show="showUpdateModal" 
    :firstFocusElement="updateButton" 
    :lastFocusElement="updateButton"
    @focusFirstElement="updateButton.focus()"
    @focusLastElement="updateButton.focus()")      
    template(v-slot:content="")
      .message(v-html="updateMessage")
      bd-button(raised @click="update" ref="updateButton" :disabled="updateInProgress") Update 
  bd-app(:appTitle="appTitle" :subDomain="subDomain")
    template(v-slot:toolbar="")    
      alerts(v-if="userRole == 'AccountManager' && auth.loggedIn")
      help
    template(v-slot:default="")
      router-view
</template>

<script>  
  import Alerts from '@/components/Alerts'
  import Help from '@/components/Help'
  import { inject, ref, onMounted, onUnmounted, computed } from 'vue'
  import { mapMutations, mapGetters, mapActions } from 'vuex'  
  import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
  import {useRouter} from 'vue-router'
  import { EmittedEvents } from '@/utility/Constants.js'

  export default {
    name: 'app',
    components: {      
      alerts: Alerts,
      help: Help
    },
    setup () {
      const auth = inject('auth')
      const authUtility = inject('authUtility')
      const {interceptor} = inject('httpInterceptor')
      const emitter = inject('emitter')
      const showUpdateModal = ref(false)
      const updateButton = ref(null)
      const updateInProgress = ref(false)
      const router = useRouter()      
      const updateMessage = ref(null)

      const sprocketInfoConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_SPROCKET_API}/infoHub`, {
          accessTokenFactory: async () => {
            const token = (await authUtility.getAccessTokenFromRedirect()) 
                            ?? process.env.VUE_APP_NO_AUTH_TOKEN
            return token
          },
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets          
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build()

      sprocketInfoConnection.onclose(function (error) {
        startSprocketConnection()
      })

      sprocketInfoConnection.on('Info', function (data) {
        handleInfoReceived(data)
      })

      const handleInfoReceived = (info) => {
        if ((process.env.VUE_APP_VERSION != info.appVersion)) {          
          displayUpdateModal(`Toolkit version ${info.appVersion} is available!`)
        } else {
          showUpdateModal.value = false  
        }
      }

      const displayUpdateModal = (message) => {
        if (!showUpdateModal.value) {
          updateMessage.value = message          
          showUpdateModal.value = true
        }
      }

      const startSprocketConnection = () => {
        sprocketInfoConnection.start() 
          .then(() => {})
          .catch((error) => {            
            //attempt to reconnect after 10 seconds
            setTimeout(() => startSprocketConnection(), 10000)
          })        
      }

      const hashChangeHandler = () => {
        var target = window.location.hash
        router.push(target.substring(1, target.length))
      }      

      onMounted(() => {
        // fix for IE 11
        if (!!window.MSInputMethodContext && !!document.documentMode) {
          window.addEventListener('hashchange', hashChangeHandler)
        }
        emitter.on(EmittedEvents.AppOutOfSyncEvent, (message) => {          
          displayUpdateModal(message)          
        })
        startSprocketConnection()        
      })

      onUnmounted(() => {
        // fix for IE 11
        if (!!window.MSInputMethodContext && !!document.documentMode) {
          window.removeEventListener('hashchange', hashChangeHandler)
        }        
        emitter.off(EmittedEvents.AppOutOfSyncEvent)
      })

      return {
        auth,
        authUtility,
        interceptor,
        emitter,
        showUpdateModal,
        updateButton,
        updateInProgress,
        sprocketInfoConnection,
        startSprocketConnection,
        handleInfoReceived, 
        updateMessage,        
        displayUpdateModal
      }
    },
    data () {
      return {
        appTitle: 'Toolkit',
        isOrderNumberKeyed: false,
        subDomain: process.env.VUE_APP_SUB_DOMAIN
      }
    },
    watch: {
      'auth.loggedIn': function(value){
        if(value){
          this.loadUserState()
          this.loadAppPermissions()
          this.interceptor.get('/user/role')
            .then(response => {
              this.setUserRole(response.data)
            })
            .catch(error => {
              console.error(error)
            })
        }
      }
    },
    computed: {
      ...mapGetters([
        'userRole'
      ]),
    },
    methods: {
      ...mapMutations([
        'setUserRole',        
      ]),
      ...mapActions([
        'loadAppPermissions', 
        'loadUserState'
      ]),
      update () {
        if (!this.updateInProgress) {
          this.updateInProgress = true          
          window.location.reload()          
        }
      }
    }
  }
</script>

<style lang="scss">
  html {
    overscroll-behavior: none;
  }
  // burkhart settings
  body {
    background-color: darken($iced-slate, 12);
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: $deep-sea;
  }
  //#app is added to the main app div by vue
  #app {
    height: 100%;
  }
  .app-container {
    height: 100%;
    .bd-modal {      
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .message {
          padding-bottom: 4px;
        }

        .bd-button {
          padding: 4px 8px;
          &:focus-visible {
            outline: none;
          }
          .text {
            font-size: 14px;
          }
        }
      }

    }
  }
</style>