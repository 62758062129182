<template lang="pug">
.compact-product-card(:class="{clickable: enableClickToOpenProduct}" @click="productClicked")
    .left
        .top-row
            .top-left
                .item-number {{ product.itemNumber }}
                .vendor-part-number(v-if="hasVendorPartNumber") {{ vendorNumber }}
            .top-right(v-if="enablePricing && product.pricingLoaded")
                .price {{ itemPrice }}
        .bottom-row
            .name {{ productDescription }}
    .right
        product-purchase-info(:modelValue="product" :enablePricing="enablePricing" :enableCart="enableCart" :waitForPricingToLoad="waitForPricingToLoad" :compactProductPurchaseView="true" @click="stopPropagation" :isCustomerUser="isCustomerUser") 
</template>
<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import isMobile from 'is-mobile'
import { handleDisplayValue } from '../utility/Util'
import ProductPurchaseInfo from '@/components/ProductPurchaseInfo'

const props = defineProps({
    product: Object,
    customerId: {
        type: String,
        default: null
    },
    enableCart: {
        type: Boolean,
        default: false
    },
    enablePricing: {
        type: Boolean,
        default: false
    },
    enableClickToOpenProduct: {
        type: Boolean,
        default: false
    },
    waitForPricingToLoad: {
        type: Boolean,
        default: false
    }
})

const router = useRouter()
const store = useStore()
const goToProduct = inject('goToProduct')
const currencyFormatter = inject('currencyFormatter')


const isMobileDevice = ref(false)

onMounted(() => {
    isMobileDevice.value = isMobile({tablet: false})
})

const hasVendorPartNumber = computed(() => !!props.product?.vendorPartInfo?.VendorPartNumber)
const isCustomerUser = computed(() => store.getters.isCustomerUser)
const productDescription = computed(() => {
    if (props.product.description.length > 90 && !isMobileDevice.value) {
        return props.product.description.slice(0, 90) + '...'
    } else {
        return handleDisplayValue(props.product.description, 27, isMobileDevice.value)
    }
})
const vendorNumber = computed(() => {
    if (props.product.vendorPartInfo?.VendorPartNumber.length > 30 && !isMobileDevice.value) {
        return props.product.vendorPartInfo.VendorPartNumber.slice(0, 30) + '...'
    } else {
        return handleDisplayValue(props.product.vendorPartInfo.VendorPartNumber, 8, isMobileDevice.value)
    }
})
const itemPrice = computed(() => {
    if (isCustomerUser.value
    && props.product?.lifeCycle == "D" && props.enableCart) {
        return `$--`
    } else {
        return currencyFormatter(props.product?.price)
    }
})

const productClicked = () => {
  if (props.enableClickToOpenProduct) {
    goToProduct(router, props.customerId, props.product.itemNumber)
  }
}

const stopPropagation = (event) => {
    event.stopPropagation()
}

</script>
<style scoped lang="scss">
.compact-product-card {
    color: $iced-slate;
    display: flex;
    border-bottom: $iced-slate 1.5px solid;
    background-color: $white;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    padding: 8px !important;

    .left {
        width: 100%;

        .top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 4px;
            margin-right: 8px;

            .top-left {
                display: flex;
                max-width: 70%;
                justify-content: space-between;

                * {
                    margin-right: 12px;
                }
            }
        }

        .bottom-row {
            display: flex;
            align-items: center;
        }
    }
}

.clickable {
    cursor: pointer;
    .top-left, .bottom-row {
        &:hover {
            text-decoration: underline;
        }   
    }
}
</style>
    