<template lang="pug">
.customer
  .head
    .head-content(ref="head")
      CustomerInfo(:id="id" v-if="auth.loggedIn" @showPopOut="toggleCustomerPopOut")
      .search-by
        .select
          bd-tabs(v-slot="slotProperties" ref="tabs")
            bd-button.button(icon="local_shipping" @click="slotProperties.onClick(); searchBySelect(1);" darkmode ref="ordersButton") Orders
            bd-button.button(icon="category" @click="slotProperties.onClick(); searchBySelect(2);" darkmode ref="productsButton") Shop
            bd-button.button(v-if="enableCart" icon="shopping_cart" @click="slotProperties.onClick(); searchBySelect(3);" darkmode ref="cartButton" :alert="lineItemCount") Cart
  .child-route-container
    product-slider(v-bind:modelValue="itemNumber" :show="showProduct" :customerNumber="customerId" @closeSlider="closeProductSlider")
    customer-sales-report(v-model="customer" :show="showCustomerSalesReport && !closeAllCustomerPopOuts" @closeSlider="toggleCustomerPopOut(customerPopOutValues.SalesReport, false)")
    keep-alive
      component(:is="component" v-bind="componentProperties")
  .foot
    .search-by
      .select
        bd-tabs(v-slot="slotProperties" ref="tabs2")
          bd-button.button(icon="local_shipping" @click="slotProperties.onClick(); searchBySelect(1);" darkmode ref="ordersButton2") Orders
          bd-button.button(icon="category" @click="slotProperties.onClick(); searchBySelect(2);" darkmode ref="productsButton2") Shop
          bd-button.button(v-if="enableCart" icon="shopping_cart" @click="slotProperties.onClick(); searchBySelect(3);" darkmode ref="cartButton2" :alert="lineItemCount") Cart
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { inject, ref } from 'vue'
  import Products from '@/views/Products'
  import Orders from '@/views/Orders'
  import Cart from '@/views/Cart'
  import ProductDisplay from '@/components/ProductDisplay'
  import ProductSlider from '@/components/ProductSlider'  
  import CustomerInfo from '@/components/CustomerInfo'
  import { EventTypes } from "@burkhart/vue-components";
  import { isDirectLink } from '../utility/Util'
  import { customerProductRouteName } from '@/router/index'
  import CustomerSalesReport from "@/components/CustomerSalesReport"
  import {CustomerPopOuts} from '@/utility/Constants.js'

  const orderSearchBySelect = 1
  const productsSearchBySelect = 2
  const cartSearchBySelect = 3
  const productViewSearchBySelect = 4

  const ordersPath = '/orders'
  const productsPath = '/products'
  const cartPath = '/cart'
  const productPathName = 'ProductViewInternal'
  const productRedirectPathName = 'ProductViewRedirect'
  

  export default {
    name:'Customer',
    components: {
      Orders,
      Products,
      Cart,
      CustomerInfo,
      ProductDisplay,
      ProductSlider,      
      CustomerSalesReport
    },
    setup () {
      const authUtility = inject('authUtility')
      const auth = inject('auth')
      const { interceptor } = inject('httpInterceptor')
      const tabs = ref(null)
      const ordersButton = ref(null)
      const productsButton = ref(null)
      const cartButton = ref(null)
      const tabs2 = ref(null)
      const ordersButton2 = ref(null)
      const productsButton2 = ref(null)
      const cartButton2 = ref(null)
      const emitter = inject('emitter')
      const showCustomerSalesReport = ref(false)
      const customerPopOutValues = CustomerPopOuts
      const closeAllCustomerPopOuts = ref(false)

      return {
        authUtility,
        auth,
        tabs,
        ordersButton,
        productsButton,
        cartButton,
        tabs2,
        ordersButton2,
        productsButton2,
        cartButton2,
        interceptor,
        emitter,
        showCustomerSalesReport,
        customerPopOutValues,
        closeAllCustomerPopOuts
      }
    },
    props: {
      id: String
    },
    data () {
      return {
        currentSearchBySelect: null,
        lastOrdersPath: null,
        lastProductsPath: null,
        lastCartPath: null,
        tabClicked: false, 
        enableCart: false,
        disableLoadFiltersFromCache: false,
        itemNumber: null, 
        showProduct: false        
      }
    },    
    async beforeRouteUpdate (to, from, next) {

      let nextPath = null
      let cancelNavigation = false

      cancelNavigation = !(await this.canAccessCustomer(to.params.id))

      if (!cancelNavigation) {
        if (from.path.endsWith(ordersPath)) {
          this.lastOrdersPath = from.fullPath
        } else if (from.path.endsWith(productsPath)) {
          this.lastProductsPath = from.fullPath
        } else if (from.path.endsWith(cartPath)) {
          this.lastCartPath = from.fullPath
        }
                
        this.disableLoadFiltersFromCache = !!isDirectLink(to.query)
        
        const isProductViewPath = to.name == productPathName

        const redirectInProgress = to.name == customerProductRouteName 
          && (to.redirectedFrom?.name == productRedirectPathName 
            || from.redirectedFrom?.name == productRedirectPathName)

        if (to.path != from.path && !redirectInProgress) {          
          let newCustomer = false
          this.closeAllCustomerPopOuts = true
          if (to.params.id != from.params.id) {            
            this.lastOrdersPath = to.path.endsWith(ordersPath) ? to.fullPath : null
            this.lastProductsPath = to.path.endsWith(productsPath) ? to.fullPath : null
            this.lastCartPath = to.path.endsWith(cartPath) ? to.fullPath : null
            await this.loadCustomerData(to.params.id)
            newCustomer = true
          }
          
          if (!this.hasSearchError) {
            if (!this.tabClicked) {
              if (this.isPathValid(to.path) && !isProductViewPath) {
                this.setSearchBySelect(to.path, to.params)                
              } else {
                if (newCustomer) {
                  this.setSearchBySelect(productsPath)
                  let customerId = to.params.id
                  let customerIdIndex = to.path.indexOf(customerId)
                  nextPath = `${to.path.substring(0, customerIdIndex)}${customerId}${productsPath}`
                } else {
                  this.setSearchBySelect(from.path)
                  cancelNavigation = true
                }
              }
            }

            if (isProductViewPath) {
              if (!newCustomer) {
                cancelNavigation = true
              }              
              this.itemNumber = to.params['itemNumber']          
            }
          }
        }

        this.showProduct = isProductViewPath || redirectInProgress        
      }
      
      if (cancelNavigation) {        
        next(false)
      } else if (nextPath) {
        next(nextPath)
      } else {
        next()
      }
    },
    async beforeMount () {    
      const loadUser = await this.canAccessCustomer(this.id)
      if (loadUser) {
        await this.loadCustomerData(this.id)
        if (this.isPathValid(this.$route.path)) {
          this.setSearchBySelect(this.$route.path)
        } else {
          await this.goToRoute(this.lastOrdersPath, ordersPath)
        }
      } else {
        this.$router.push({name: 'Home'})
      }
    },
    computed: {
      ...mapGetters([
        'customer',
        'hasSearchError',
        'cart', 
        'userRole',
        'shoppingCartEnabled',        
        'pricingEnabled',
        'lineItemCount',
        'isCustomerUser',
        'customerId'
      ]),
      component () {

        let dynamicComponent

        switch (this.currentSearchBySelect) {
          case cartSearchBySelect:
            dynamicComponent = 'Cart'
            break;
          case productsSearchBySelect:
            dynamicComponent = 'Products'
            break;
          case orderSearchBySelect:
            dynamicComponent = 'Orders'
            break;
          case productViewSearchBySelect:
            dynamicComponent = 'Product'
            break;
          default:
            dynamicComponent = null
            break;
        }

        return dynamicComponent
      },
      componentProperties () {
        let dynamicComponentProperties

        switch (this.currentSearchBySelect) {
          case orderSearchBySelect:
            dynamicComponentProperties = { 
              id: this.customerId,
              disableFilterCacheLoadOnCreated: this.disableLoadFiltersFromCache 
            }
            break;
          case productsSearchBySelect:
            dynamicComponentProperties = { 
              customerId: this.customerId,
              customerDCs:  this.customer.DCsMerchs?.map(dc => dc.MerchDC),
              enableCart: this.enableCart,
              enablePricing: this.pricingEnabled,
              disableFilterCacheLoadOnCreated: this.disableLoadFiltersFromCache,
              goHomeIfLoggedIn: false,
              activePath: 'products'
            }
            break;
          case cartSearchBySelect:
            dynamicComponentProperties = { id: this.id }
            break;
          case productViewSearchBySelect:
            dynamicComponentProperties = { itemNumber: this.routeItemNumber, 
              customerNumber: this.id }
            break;            
          default:
            dynamicComponentProperties = null
            break;
        }
        return dynamicComponentProperties
      },
      routeItemNumber  () {        
        return this.itemNumber ?? this.$route.params.itemNumber
      }
    },
    methods: {
      ...mapActions([
        'loadCustomer'
      ]),
      updateTabs(searchBySelect) {
        let button
        let button2

        switch (searchBySelect) {
          case productsSearchBySelect:
          case productViewSearchBySelect:            
            button = this.productsButton
            button2 = this.productsButton2            
            break;
          case cartSearchBySelect:
            button = this.cartButton
            button2 = this.cartButton2
            break;
          default:
            button = this.ordersButton
            button2 = this.ordersButton2
            break;

        }
        this.tabs.updateSlider(button)
        this.tabs2.updateSlider(button2)
      },
      setSearchBySelect (path, routeParams = null) {
        let useOrders = false

        if (path?.includes('products')) {
          this.currentSearchBySelect = productsSearchBySelect          
        } else if (path?.includes('cart')) {
          if (this.enableCart) {
            this.currentSearchBySelect = cartSearchBySelect            
          } else {
            useOrders = true
          }
        } else if (path?.includes('product')) {
          this.itemNumber = routeParams?.itemNumber
          this.currentSearchBySelect = productViewSearchBySelect

        }
        else {
          useOrders = true
        }        

        if (useOrders) {
          this.currentSearchBySelect = orderSearchBySelect
        }
        this.updateTabs(this.currentSearchBySelect)
      },
      async searchBySelect (value) {
        if (value == orderSearchBySelect && this.currentSearchBySelect != value) {        
          await this.goToRoute(this.lastOrdersPath, ordersPath)          
        } else if (value == productsSearchBySelect && this.currentSearchBySelect != value) {          
          await this.goToRoute(this.lastProductsPath, productsPath)
        } else if (value == cartSearchBySelect && this.currentSearchBySelect != value) {
          if (this.enableCart) {
            await this.goToRoute(this.lastCartPath,  cartPath)
          } else {

            //go back to the orders
            await this.goToRoute(this.lastOrdersPath, ordersPath)
          }
        }
      },

      async goToRoute (cachedPath, initialRoute) {
        this.tabClicked = true
        let {basePath, query} = this.createRoute(cachedPath, initialRoute)
        await this.$router.push({path: basePath, query: query })
        this.setSearchBySelect(basePath)
        this.tabClicked = false
      },
      isPathValid (path) {
        let pathValid = true
        
        if (path.endsWith(cartPath)) {
          pathValid = this.enableCart 
        }
        
        return pathValid
      },
      createRoute (cachedPath, initialRoute) {
        let basePath = ""
        let query = {}
        let path = cachedPath ?? this.$route.path
        let pathArray = []        

        if (cachedPath) {
          let queryStringStart = path.indexOf('?')

          if (queryStringStart > 0) {            
            basePath = path.substring(0, queryStringStart)
            
            query = Object.fromEntries(decodeURIComponent(path.substring(queryStringStart + 1))
                    .split('&')
                    .map(keyAndValue => keyAndValue.split('=')))
          } else{
            basePath = cachedPath
          }

        } else {
          pathArray = path.split('/')                  
          //the base path will consist of everything up to the customer id
          basePath = pathArray.slice(0, pathArray.indexOf(this.id) + 1).join('/')
          basePath += initialRoute
        }

        
        return { basePath, query }
      },
      goHome () {
        this.$router.push("/")
      },
      async loadCustomerData (id) {
        await this.loadCustomer(id)
        if (this.hasSearchError) {
            this.goHome()
        } else {
          this.enableCart = this.shoppingCartEnabled
        }
      },
      async canAccessCustomer (customerId) {
        let canAccessCustomer = true
        if (this.isCustomerUser) {        
        let accounts = []
        try {
          accounts = (
            await this.interceptor.get(
              `user/customeraccess/${this.authUtility.email}/all`
            )
          ).data;
          if (accounts.length > 0) {
            const match = accounts.find(a => a.customerNumber == customerId)
            canAccessCustomer = (match != undefined)
          }
        } catch (error) {
          console.error(error); 
          canAccessCustomer = false         
          this.emitter.emit(EventTypes.ErrorEvent, `Error validating user customer access`);
        }
      }
      return canAccessCustomer
      },
      closeProductSlider () {
        this.showProduct = false
      },
      toggleCustomerPopOut (popOut, open=true) {
        this.closeAllCustomerPopOuts = !open
        switch (popOut) {
          case CustomerPopOuts.SalesReport:
            this.showCustomerSalesReport = open
            break;
        }
      }      
    }
  }
</script>

<style scoped lang="scss">
  .customer {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .head {
      flex: 0 0 auto;
      background-color: lighten($iced-slate, 8);
      width: 100%;

      .head-content {
        display: flex;
        max-width: 892px;
        padding: 6px;
        justify-content: space-between;
        margin: auto;

        .back-button {
          position: absolute;
        }

        .search-by {
          @media only screen and (max-width: 700px) {
            display: none;
          }
        }
      }
    }

    .child-route-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      position: relative;

    }

    .foot {
      flex: 0 0 auto;
      background-color: lighten($iced-slate, 8);
      padding: 8px;
      display: flex;
      justify-content: space-evenly;

      @media only screen and (min-width: 701px) {
        display: none;
      }
    }
  }

  .search-by {
    .label {
      font-size: 12px;
    }

    .select {
      display: flex;
      font-size: 14px;

      .button {
        margin-right: 10px;        
      }
    }

    .select.header {
      display: none;
    }

  }
</style>