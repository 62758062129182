<template lang="pug">
.item(:class="{ coupon: coupon }")
  .promotion(v-if="coupon")
    svg.coupon-icon(width="20" height="16" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg")
      path.coupon-icon(:class="{ green: redemptionComplete }" d="M37 32H3C2.175 32 1.46875 31.7062 0.88125 31.1187C0.29375 30.5312 0 29.825 0 29V21.35C1.23333 21.0833 2.25833 20.4583 3.075 19.475C3.89167 18.4917 4.3 17.3333 4.3 16C4.3 14.6667 3.89167 13.5 3.075 12.5C2.25833 11.5 1.23333 10.8833 0 10.65V3C0 2.175 0.29375 1.46875 0.88125 0.88125C1.46875 0.29375 2.175 0 3 0H37C37.825 0 38.5313 0.29375 39.1188 0.88125C39.7063 1.46875 40 2.175 40 3V10.65C38.7667 10.8833 37.7417 11.5 36.925 12.5C36.1083 13.5 35.7 14.6667 35.7 16C35.7 17.3333 36.1083 18.4917 36.925 19.475C37.7417 20.4583 38.7667 21.0833 40 21.35V29C40 29.825 39.7063 30.5312 39.1188 31.1187C38.5313 31.7062 37.825 32 37 32ZM37 29V23.55C35.7333 22.6833 34.7 21.6 33.9 20.3C33.1 19 32.7 17.5667 32.7 16C32.7 14.4333 33.1 13 33.9 11.7C34.7 10.4 35.7333 9.31667 37 8.45V3H3V8.45C4.3 9.31667 5.34167 10.4 6.125 11.7C6.90833 13 7.3 14.4333 7.3 16C7.3 17.5667 6.90833 19 6.125 20.3C5.34167 21.6 4.3 22.6833 3 23.55V29H37Z" fill="black")
      path.coupon-icon(:class="{ green: redemptionComplete }" d="M12.6682 14.3438C11.3769 14.3438 10.276 13.8873 9.36563 12.9745C8.45521 12.0617 8 10.9596 8 9.66824C8 8.37691 8.45642 7.27604 9.36925 6.36562C10.2821 5.45521 11.3842 5 12.6755 5C13.9668 5 15.0677 5.45642 15.9781 6.36926C16.8885 7.28209 17.3438 8.38417 17.3438 9.6755C17.3438 10.9668 16.8873 12.0677 15.9745 12.9781C15.0617 13.8885 13.9596 14.3438 12.6682 14.3438ZM12.6676 12.1875C13.3653 12.1875 13.9596 11.9433 14.4508 11.455C14.9419 10.9667 15.1875 10.3737 15.1875 9.67612C15.1875 8.9785 14.9433 8.38411 14.455 7.89297C13.9667 7.40182 13.3737 7.15625 12.6761 7.15625C11.9785 7.15625 11.3841 7.40041 10.893 7.88873C10.4018 8.37707 10.1562 8.97004 10.1562 9.66763C10.1562 10.3653 10.4004 10.9596 10.8887 11.4508C11.3771 11.9419 11.97 12.1875 12.6676 12.1875ZM26.3245 28C25.0332 28 23.9323 27.5436 23.0219 26.6307C22.1115 25.7179 21.6562 24.6158 21.6562 23.3245C21.6562 22.0332 22.1127 20.9323 23.0255 20.0219C23.9383 19.1115 25.0404 18.6562 26.3318 18.6562C27.6231 18.6562 28.724 19.1127 29.6344 20.0255C30.5448 20.9383 31 22.0404 31 23.3318C31 24.6231 30.5436 25.724 29.6307 26.6344C28.7179 27.5448 27.6158 28 26.3245 28ZM26.3239 25.8438C27.0215 25.8438 27.6159 25.5996 28.107 25.1113C28.5982 24.6229 28.8438 24.03 28.8438 23.3324C28.8438 22.6347 28.5996 22.0404 28.1113 21.5492C27.6229 21.0581 27.03 20.8125 26.3324 20.8125C25.6347 20.8125 25.0404 21.0567 24.5492 21.545C24.0581 22.0333 23.8125 22.6263 23.8125 23.3239C23.8125 24.0215 24.0567 24.6159 24.545 25.107C25.0333 25.5982 25.6263 25.8438 26.3239 25.8438ZM9.50937 28L8 26.4906L29.4906 5L31 6.50938L9.50937 28Z" fill="black")
    .description  {{ coupon.data.Description }}
  CouponItem(v-for="(qItem, idx) in item.qualifyingList" :item="qItem" :isRedemptionItem="false" :priceOverrideActive="priceOverrideActive" :isLastItem="idx === item.qualifyingList.length-1")
  .option-list(v-if="coupon")
    CouponItem(v-for="(rItem, idx) in item.couponRedemptionList" :item="rItem" :isRedemptionItem="true" 
    :redemptionRemaining="rewardQuantityRemaining" :priceOverrideActive="priceOverrideActive" :isLastItem="idx === item.couponRedemptionList.length-1 ")
  CollapsibleDiv(ref="redemptionSelect" v-if="coupon")
    .label Coupon Redemption
    .redeption-select
      select(v-model="selectedRedemption")     
        option(disabled value="") Select a redemption
        option(v-for="option in coupon.data.Options" :value="option") {{ getRedemptionDisplay(option) }}
      quantity-input(:modelValue="redemptionQty" @validInput="newValue => redemptionQty = newValue" :max="rewardQuantityRemaining" @hasError="hasError => hasRedemptionQuantityError = hasError" :resetToValidWhenInvalid="false")        
      bd-button(
        icon="add_shopping_cart"
        @click="addRedemption()"
        :disabled="!enableAddRedemption"
      )
</template>

<script>
import CouponItem from '@/components/CouponItem'
import CollapsibleDiv from '@/components/CollapsibleDiv'
import QuantityInput from '@/components/QuantityInput'
import { mapActions } from 'vuex'
import { inject, ref } from 'vue'
import { debounce } from 'throttle-debounce'

const MANUFACTURER_REWARD = 2
const BURKHART_FULFILLED_FREE_GOOD = 1

export default {
  name: 'CartItem',
  components: {
    CouponItem,
    CollapsibleDiv, 
    QuantityInput
  },
  props: {
    item: Object,
    priceOverrideActive: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const currencyFormatter = inject('currencyFormatter')
    const redemptionSelect = ref(null)
    const couponInfo = ref(null)
    return {
      currencyFormatter,
      redemptionSelect,
      couponInfo
    }
  },
  data() {
    return {
      selectedRedemption: '',
      redemptionQty: 1,
      addingRedemptionInProgress: false,
      hasRedemptionQuantityError: false
    }
  },
  mounted() {
    this.refreshState()

    if (this.coupon) {
      this.selectedRedemption = this.coupon.data.Options.find(f => 
        f.redemptionItem == this.item.qualifyingList[0].itemNumber) ?? ''
    }
  },
  activated() {
    this.refreshState()
  },
  methods: {
    ...mapActions(['addCouponRedemption', 'changeQty', 'removeItemFromCart', 
        'updateRedemptionQuantity', 'updateCouponRedemptionStatus']),
    toggleCouponInfo () {
      this.couponInfo.toggleCollapse()
    },
    refreshState () {
      if (this.coupon) {
        this.refreshRedemptionPickerState()
        this.updateCouponRedemptionStatus({
          couponId: this.coupon.id, 
          isRedemptionComplete: this.redemptionComplete,
          rewardQuantityRemaining: this.rewardQuantityRemaining
        })
      }

    },
    refreshRedemptionPickerState () {
      if (this.redemptionSelect) {
        if (this.redemptionComplete) {
          this.redemptionSelect.collapse()
        } else {
          this.redemptionSelect.expand()
        }
      }
    }, 
    addRedemption: debounce(300, function (event) { this.saveRedemption(event) }),
    async saveRedemption (event) {
      if(!this.addingRedemptionInProgress && !this.redemptionComplete) {
        this.addingRedemptionInProgress = true
        let redemption = {          
          itemNumber: this.selectedRedemption.redemptionItem ?? this.selectedRedemption.FreeProducts, 
          quantity: this.redemptionQty,
          type: this.coupon.data.IsBurkhartFulfilled == "Y"? BURKHART_FULFILLED_FREE_GOOD: MANUFACTURER_REWARD,          
          rewardValue: this.selectedRedemption.RewardValue,
          couponId: this.coupon.id, 
          isRedemptionBurkhartProduct: this.selectedRedemption.isRedemptionBurkhartProduct ?? true,
          description: this.selectedRedemption.redemptionDescription,
          coupon: this.coupon
        }

        await this.addCouponRedemption(redemption)

        this.setDisplayedRedemptionQuantity()

        this.addingRedemptionInProgress = false        
      }
    }, 
    setDisplayedRedemptionQuantity() {
      if (this.rewardQuantityRemaining <= 0) {
        this.redemptionQty = 1
      } else if (this.redemptionQty >= this.rewardQuantityRemaining) {
        this.redemptionQty = this.rewardQuantityRemaining
      }
    },
    getRedemptionDisplay (option) {
      let display = ''
      if (option.redemptionItem) {
        display = option.isRedemptionBurkhartProduct ? `${option.redemptionItem} ${option.redemptionDescription}` : option.redemptionItem 
      } else {
        display = `${option.FreeProducts} ${option.description ?? ''}`
      }
      return display
    }
  },
  computed: {
    coupon () {
      return this.item.qualifyingList[0].coupon
    },
    redemptionComplete () {
      return this.rewardQuantityUsed >= this.rewardQuantity
    },
    enableAddRedemption () {
      return !this.hasRedemptionQuantityError && !this.addingRedemptionInProgress && this.selectedRedemption
    },
    tiers () {
      let t = []
      if (this.coupon) { 
        t = this.coupon.data.Tiers
      }
      t.sort((a, b) => (a.BuyQuantity < b.BuyQuantity) ? 1 : -1)
      return t
    },
    rewardQuantity () {
      let rq = 0
      let q = this.qty
      for (let i = 0; i < this.tiers.length; i++) {
        let div = Math.floor(q / this.tiers[i].BuyQuantity)
        if (div >= 1) {
          rq += div * this.tiers[i].RewardQuantity
          q -= div * this.tiers[i].BuyQuantity
          if (q <= 0) {
            break
          }
        }
      }
      return rq
    },
    rewardQuantityUsed () {
      let rqu = 0
      if (this.item.couponRedemptionList) {
        this.item.couponRedemptionList.forEach(i => {
          rqu += i.quantity
        })
      }
      return rqu
    },
    rewardQuantityRemaining () {
      return this.rewardQuantity - this.rewardQuantityUsed
    },
    qty () {
      let q = 0
      this.item.qualifyingList.forEach(i => q += i.quantity)
      return q
    }
  },
  watch: {
    redemptionComplete () {
      this.refreshState()
    },
    rewardQuantityRemaining () {
      //reward quantities need to be adjusted if the reward quantity remaining goes negative and
      //there are redemptions
      if (this.rewardQuantityRemaining < 0 && this.item.couponRedemptionList.length > 0) {
        let lastRedemptionItem = this.item.couponRedemptionList[this.item.couponRedemptionList.length -1]
        let reduceQuantityBy = this.rewardQuantityRemaining * -1
        
        //if the quantity the redemption needs to be reduced by is more than the quantity 
        //for the last redemption item then delete it, otherwise reduce the quantity
        if (lastRedemptionItem.quantity - reduceQuantityBy <= 0) {
          this.removeItemFromCart({
            itemNumber: lastRedemptionItem.itemNumber, 
            couponId: lastRedemptionItem.coupon.id,
            isRedemptionItem: true
            })
        } else {
          this.updateRedemptionQuantity({itemNumber: lastRedemptionItem.itemNumber,
            quantity: lastRedemptionItem.quantity - reduceQuantityBy, 
            couponId: lastRedemptionItem.coupon.id})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  background-color: $spring;
  border: 1px solid $deep-sea;
  border-top: 0;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;  
  margin: 0 auto;
  font-size: 12px;

  .label {
    color: $gray;
    font-size: 10px;
    padding: 4px 0 0 12px;
  }

  .promotion {
    display: flex;
    padding: 12px;
    background-color: $white;
    .coupon-icon {
      color: $grapefruit;
      fill: $grapefruit;
      &.green {
        color: $spring;
        fill: $spring;
      }
      &.red {
        color: $grapefruit;
        fill: $grapefruit;
      }
    }
    .description {
      margin-left: 12px;
    }
  }

  .info-block {
    padding: 8px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .info {
      padding-left: 8px;
    }

    .img-info {
      display: flex;
      flex-flow: row;
    }

    .coupon-info {
      margin: 0 16px;
      max-width: 164px;
    }
  }
}

.actions {
  .actions-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;

    .price {
      margin: 0 16px;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      width: 28px;
    }

    label {
      font-size: 12px;
      color: gray;
    }

    input {
      margin: 0 8px 0 4px;
    }
  }
}

.coupon {
  padding: 0 0 4px 8px;
}

.incomplete {
  color: $grapefruit;
}

.complete {
  color: $spring;
}

.redeption-select {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 8px;
}

input {
  border-radius: 35px;
  border: 1px solid transparent;
  border-color: $iced-slate;
  background-color: $white;
  color: $deep-sea;
  padding: 0 0 0 10px;
  width: 52px;
  height: 24px;
  font-size: 12px;
  margin: 4px;

  &:focus {
    outline: none;
  }
}

select {
  border-radius: 35px;
  border: 1px solid transparent;
  border-color: $iced-slate;
  background-color: $white;
  color: $deep-sea;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  height: 24px;
  font-size: 12px;
  width: 98%;

  &:focus {
    outline: none;
  }
}
</style>