<template lang="pug">
bd-card.order-card(no-padding v-if="orderHasData()")
  template(v-slot:header="")
    .order-header(v-if="Order.origination=='Toolkit'")      
      .no-order-number
        .header         
          .order-info                    
          .header-status {{ orderDate }}    
    .order-header(v-else="" @click="gotoOrder(Order.orderNumber)")      
      .order-number
        .header         
          .order-info          
            ui-icon(v-if="orderIcon") {{orderIcon}}
            .order-text(class="hover-effects") Order# {{ Order.orderNumber }}
          .header-status(class="hover-effects") {{ orderDate }}
  OrderGroup(
    ref="submitted"
    v-if="Order.submitted.length > 0"
    v-model:value="Order.submitted"
    type="submitted"
  )
  OrderGroup(
    ref="pending"
    v-if="Order.pending.length > 0"
    v-model:value="Order.pending"
    type="pending"
  )
  .order-processing-group(v-if="processingGroups.length > 0")
    OrderGroup(
      ref="processing"
      v-for="(processingGroup, index) in processingGroups"
      v-model:value="Order.processing"
      :status="processingGroup"
      type="processing"
    )
  OrderGroup(
    :ref="el => addShipmentRef(el)"
    v-for="(shipment, index) in Order.shipments"
    :key="index"
    v-model:value="Order.shipments[index]"
    type="shipment"
    :disable="shipment.lines.length <= 0"
    :trackingNumberCarrierGroups="trackingNumberCarrierGroups"
    :carrierTracking="carrierTracking"
    :customerNumber="Order.contactInfo.customerNumber"  
  )
  OrderGroup(
    ref="backordered"
    v-if="Order.backordered.length > 0"
    v-model:value="Order.backordered"
    type="backordered"
  )
  OrderGroup(
    ref="canceled"
    v-if="Order.canceled.length > 0"
    v-model:value="Order.canceled"
    type="canceled"
  )
.no-order-lines(v-else-if="!isInListView")
  .header
    ui-icon warning
    .header-txt No Results Found
</template>

<script>
  import OrderGroup from '@/components/OrderGroup'
  import CarrierTracking from '../utility/CarrierTracking.js'  
  import { OrderHasData } from '../utility/Util.js'
  import { ref, onBeforeUpdate } from 'vue'

  export default {
    name: 'OrderCard',
    props: {
      Order: Object,
      isInListView: Boolean,
      collapseGroups: Array
    },
    emits: [
      'goToOrderEvent'
    ],
    components: {
      OrderGroup
    },
    setup () {
      const submitted = ref(null)
      const pending = ref(null)
      const processing = ref(null)
      const shipment = ref([])
      const backordered = ref(null)
      const canceled = ref(null)

      let groupsRef = ref({
        pending: pending, 
        processing: processing, 
        shipment: shipment, 
        backordered: backordered, 
        canceled: canceled, 
        submitted: submitted
      })

      const addShipmentRef = div => {
        shipment.value.push(div)
      }

      onBeforeUpdate(() => {
        shipment.value.length = 0
      })

      return {
        pending, 
        processing, 
        shipment, 
        backordered, 
        canceled,
        groupsRef,
        addShipmentRef,
        submitted
      }

    },
    data () {
      return {
        processingGroups: [],
        trackingNumberCarrierGroups: [],
        carrierTracking: new CarrierTracking()
      }
    },
    created () {
      if (this.Order && this.Order.processing.length > 0) {
        this.processingGroups = [...new Set(this.Order.processing.map(p => p.status))]
      }
      this.trackingNumberCarrierGroups = this.carrierTracking.setUpTrackingGroups(this.Order.shipments)

      if (this.collapseGroups?.length > 0) {
        this.$nextTick(() => {
          this.collapseGroups.forEach((group) => {
            this.collapse(group)
          })
        })
      }

    },
    computed: {
      orderDate () {
        return this.formatDate(this.Order.orderDate)
      },
      orderIcon () {
        let icon = null;

        switch (this.Order.origination?.toLowerCase()) {
          case "laptop":
            icon = "laptop_mac"
            break
          case "web":
            icon = "store"
            break
          case "phone":
            icon = "support_agent"
            break                        
          default:
            icon = null
            break
        }
        return icon
      }
    },
    methods: {
      collapse (group) {
        if (this.groupsRef[group]) {          
          if (Array.isArray(this.groupsRef[group])) {
            this.groupsRef[group].forEach(card => {card.collapse()})
          } else {
            this.groupsRef[group].collapse()
          }
        }
      },
      expand (group) {
        if (this.groupsRef[group]) {
          if (Array.isArray(this.groupsRef[group])) {
            this.groupsRef[group].forEach(card => {card.expand()})
          } else {
            this.groupsRef[group].expand()
          }
        }
      },
      gotoOrder (id) {
        this.$emit('goToOrderEvent', id)
      },
      hideOpenTrackingDropDown (event) {
        if (event.target.className != 'tracking invalid-icon') {
          this.carrierTracking.hideOpen()
        }
      },
      showTracking (event, shipmentNumber, carrier, trackingLink) {
        if (this.isValidCarrier(carrier)) {
          window.open(trackingLink, '_blank')
        } else {
          this.carrierTracking.toggleVisibility(shipmentNumber)
        }

      },
      getTrackingText (shipmentNumber) {
        let invalidCarrier = this.carrierTracking.getInvalidCarrier(shipmentNumber)

        return invalidCarrier ? invalidCarrier.trackingLink : ""
      },
      getTrackingIconClass (carrier) {
        let iconClass = ""

        switch (carrier) {
          case CarrierTracking.UPS_CARRIER:
            iconClass = 'ups-icon'
            break;
          case CarrierTracking.FEDEX_CARRIER:
            iconClass = 'fedex-icon'
            break;
          case CarrierTracking.ONTRAC_CARRIER:
            iconClass = 'ontrac-icon'
            break;
          default:
            iconClass = 'invalid-icon'
        }

        return iconClass
      },
      isValidCarrier (carrier) {
        return carrier != CarrierTracking.INVALID_CARRIER
      },
      getShippedFrom (allocatedWarehouse, expectedReceiptDate) {
        if (allocatedWarehouse) {
          if (allocatedWarehouse.trim().startsWith(',')) {
              allocatedWarehouse = allocatedWarehouse.substring(1)
          }
          allocatedWarehouse = allocatedWarehouse.trim()
        }
        allocatedWarehouse = allocatedWarehouse ? allocatedWarehouse +  ' - ' : ''
        let shippedFrom = allocatedWarehouse + (expectedReceiptDate ? this.formatDate(expectedReceiptDate) : 'Date Pending')
        return shippedFrom
      },
      formatDate (date) {
        return new Date(date).toLocaleDateString('en-us', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      },
      orderHasData () {
        return OrderHasData(this.Order)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .order-card {
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 350px) {
      font-size: 10px;
    }
  }

  .order-header {
    .header {
      padding: 6px 0;    
    }
    .order-number {
      &:hover {
        cursor: pointer;
        .hover-effects {
          text-decoration: underline;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 6px 24px;
    z-index: 2;

    .header-txt {
      padding-left: 5px;
      padding-right: 10px;
      flex-grow: 2;
    }

    .header-status {
      white-space: nowrap;
      display: inline-block;
      padding-top: 5px;
    }
    .order-info {
      display: flex;
      justify-content: space-between;
      
      .order-text {
        padding-left:10px;
        padding-top:5px;
      }
      
    }
    
  }
</style>
