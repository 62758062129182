import { httpInterceptor, authUtility } from '@burkhart/vue-components'
import { createStore } from 'vuex'
import Cart from './cart'
import tagRequests from './tagRequests'
import userData from './userData'
import { AuthorizationRoles } from '../utility/Constants.js'

const state = {
  customer: {},
  searchCriteria: null,    
  searchError: null,
  userRole: null,
  shoppingCartEnabled: false,
  pricingEnabled: false,
  isCustomerUser: false
}

const getters = {
  customer (state) {
    return state.customer
  },
  customerId (state) {
    return state.customer.CustomerID
  },    
  searchCriteria (state) {
    return state.searchCriteria
  },
  hasSearchError (state) {
    return (state.searchError && state.searchError.trim != '')
  },     
  searchError (state) {
    return state.searchError
  }, 
  userRole (state) {
    return state.userRole
  },
  shoppingCartEnabled (state) {
    return state.shoppingCartEnabled
  },
  pricingEnabled (state) {
    return state.pricingEnabled
  },
  isCustomerUser (state) {
    return state.isCustomerUser
  },   
}

const mutations = {
  setCustomer (state, customer) {
    state.customer = customer
  },
  setSearchCriteria (state, value) {
    state.searchCriteria = value
  },
  setSearchError (state, searchError) {       
    state.searchError = searchError      
  },
  setUserRole (state, role) {
    state.userRole = role
  },
  setShoppingCartEnabled (state, isEnabled) {
    state.shoppingCartEnabled = isEnabled
  },
  setPricingEnabled (state, isEnabled) {
    state.pricingEnabled = isEnabled
  },
  setIsCustomerUser (state, isCustomer) {
    state.isCustomerUser = isCustomer
  }  
}

const actions = {
  async loadCustomer ({ commit, dispatch, getters }, id, ignoreError = false) {      
    let errorMessage = null
    commit('setSearchError', null)
    if (id) {
      try {
        let response = await httpInterceptor.interceptor.get(`/Customer/customernumber/${id}`)
        commit('setCustomer', response.data)            
        if (!response.data) {              
          errorMessage = 'Customer not found'
        }          
      } catch (error) {
        console.log(error)
        errorMessage = 'Invalid customer number'          
      } finally {
        commit('setSearchCriteria', id)

        if (getters.customer) {

          await dispatch('toggleShoppingCart')
        }

        
        if (!ignoreError) {
          commit('setSearchError', errorMessage)            
        }
      }
      
    } else {                
      if (!ignoreError) {
        commit('setSearchError', 'Invalid search: missing customer id')        
      }
      commit('setCustomer', {})
    }
  },
  async toggleShoppingCart ({ commit, getters, dispatch }) {
    let territoryId = getters.customer?.TerritoryID        
    let enableCart = false      

    let managesTerritory = false

    if (authUtility.IsInRole(AuthorizationRoles.OrderSubmit) && getters.userRole == 'AccountManager') {
      
      try {
        let response = await httpInterceptor.interceptor.get(`user/territory/manages?emailAddress=${authUtility.email}&territoryId=${territoryId}`)            
        managesTerritory = response?.data
      } catch (error) {
        console.error(error)
      }
    } else if (authUtility.IsInRole(AuthorizationRoles.Customer)) {
      managesTerritory = true
    }

    enableCart = (authUtility.IsInRole(AuthorizationRoles.CustomerService) || managesTerritory)
    commit('setShoppingCartEnabled', enableCart)

    dispatch('clearCart')

    if (getters.shoppingCartEnabled && getters.customerId) {
      dispatch('updateCustomerCart', getters.customerId)
      dispatch('loadTagRequests', getters.customerId)
    } 

  },
  async loadAppPermissions({ commit }) {
    let isCustomerUser = authUtility.IsInRole(AuthorizationRoles.Customer)
    let pricingEnabled = authUtility.IsInRole(AuthorizationRoles.PricingView) 
      || authUtility.IsInRole(AuthorizationRoles.CustomerService)
      || isCustomerUser

    commit('setPricingEnabled', pricingEnabled)
    commit('setIsCustomerUser', isCustomerUser)
  }
}

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    cart: Cart,
    tagRequests: tagRequests,
    userData: userData
  }
})

export default store
export { getters, mutations, actions }