<template lang="pug">
.quick-order(@keyup.enter="addToCart()")
  .inputs
    input.item-number-input(
      v-model="itemNumber"
      @keyup="itemNumberChanged"
      placeholder="Quick Order"
      type="text"
      :class="{'invalid-item': invalidItem, 'valid-item': validItem}"
      ref='itemInputControl'      
      :disabled="addingToCart"
      size="10")
    .separator  
    input.quantity-input(      
      placeholder="Qty"
      min="1", 
      type="number"      
      v-model="quantity"      
      :disabled="addingToCart"
      @focus="selectAllText")
  .add-to-cart
    bd-button(v-show="!addingToCart" icon="add_shopping_cart" raised @click="addToCart()" :disabled="!isSubmittable || addingToCart")
    ui-spinner(:size="'medium'" active v-show="addingToCart")
</template>

<script setup>
import { inject, ref, computed } from "vue";
import { EventTypes } from "@burkhart/vue-components";
import { useStore } from "vuex";
import { debounce } from 'throttle-debounce'

const store = useStore()

const { interceptor } = inject("httpInterceptor");
const emitter = inject("emitter");
const ITEM_NUMBER_LENGTH = 8

const itemInputControl = ref()
const quantityInputControl = ref()

let itemNumber = ref('')
let invalidItem = ref(false)
let validItem = ref(false)

let quantity = ref(1)
let addingToCart = ref(false)

const props = defineProps({
  customerNumber:{
    type: String,
    required: true
  }
})

const isValidItemNumberLength = computed(() =>
itemNumber?.value?.length >= ITEM_NUMBER_LENGTH)

const customerNumberParameter = computed(() => {
  let customerNumber = props.customerNumber?.trim()
  customerNumber = !customerNumber ? 'NA' : customerNumber
  return `?customerNumber=${customerNumber}`
})

const isSubmittable = computed(() => quantity.value >= 1 && isValidItemNumberLength.value)

const itemNumberChanged = (event) => {    
  if (isValidItemNumberLength.value) {
    if (!addingToCart.value) {
      getItemDebounced()
    }
  } else {
    clearItemNumberCss()
  }
}

const clearItemNumberCss = () => {
  validItem.value = false
  invalidItem.value = false  
}

const isOrderable = (itemData) => (!!itemData?.itemNumber && !!itemData?.isOrderable)

const getItemDebounced = debounce(200, async function() {getItem()})
const getItem = async () => {
  let itemData = (await interceptor.get(`item/itemNumber/${itemNumber.value}${customerNumberParameter.value}`))?.data
  validItem.value = isOrderable(itemData)
  invalidItem.value = !validItem.value
  return itemData  
}

const addToCart = debounce(300, async function() {saveItem()})
const saveItem = async () => {
  if (isSubmittable.value && !addingToCart.value) {
    addingToCart.value = true

    try {
      let itemData = await getItem()
      if (isOrderable(itemData)) {      
        await store.dispatch('addItemToCart', {itemObject: {
            data: itemData, quantity: quantity.value}, 
            emitMessageAfterCartUpdate: true})
        itemNumber.value = ''
        quantity.value = 1
        clearItemNumberCss()
      } else {
        let errorMessage = ''
        if (!itemData?.itemNumber) {
          errorMessage = `Cannot quick order item ${itemNumber.value}. It does not exist`
        } else {
          errorMessage = `Item ${itemNumber.value} cannot be ordered`
        }
        emitter.emit(EventTypes.ErrorEvent, errorMessage)
      }
    } catch (error) {}

    addingToCart.value = false
    itemInputControl.value.focus()
  }    
    
}

const validateQuantity = () => {
  if (quantity.value <= 0){
    quantity.value = 1
  }
}

const selectAllText = (event) => {
  event.target.select()
}
</script>

<style lang="scss" scoped>
.quick-order {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 8px;
  flex: 1 1 auto;
  flex-wrap: wrap;  

  .inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $iced-slate;
    border-radius: 18px;
    flex: 1 1 auto;

    input {
      border: none;
      margin: 0 8px;
      &:focus {
        outline: none;
      }
      &.quantity-input {
        width: 50px;
      }
    }

    .valid-item {
      color: $spring;
    }

    .invalid-item {
      color: $error;
    }

    .separator {
      width: 1px;
      height: 100%;
      margin: 0 4px;
      background-color: $gray;
    }  
  }

  .add-to-cart {
    height: 40px;
    overflow: hidden;
  }
}
</style>
