<template lang="pug">
.filter-item(@click="filterUpdate") 
  input(type="checkbox" :checked="selected" @changed="filterUpdate")
  label {{ filter.label }}
</template>

<script setup>
import { computed, useCssVars } from 'vue'

const props = defineProps({
  filter: {
    type: Object,
    default: {}
  },
  disableCheckEventPropagation: {
    type: Boolean,
    default: false
  }  
})

const emit = defineEmits([  
  'filterUpdate'
])

const filterUpdate = (event) => {
  emit('filterUpdate', props.filter)
  if (props.disableCheckEventPropagation) {
    event.preventDefault()
  }
}

const indentation = computed({
  get () {
    return ((props.filter?.depth ?? 0) * 10) + 'px'
  }
})

let selected = computed({
  get () {
    return props.filter.value != null && props.filter.value != undefined
  }, 
  set (newValue) {
    props.filter.value = newValue
  }
})
</script>

<style lang="scss" scoped>

.filter-item {  
  width: 100%;  
  padding-left: v-bind(indentation);
  .heading {
    font-weight: 900;
  }
}
</style>
