<template lang="pug">
.product-display  
  .loading(v-if="loading") Loading Product {{ `#${itemNumber}` }}...
  .product(v-else-if="product || showNoResults")
    .head
      .close-button
        bd-button(icon='chevron_left' @click="emitCloseProductView" ref="closeProductButton")
      product-header(v-if="product" v-model="product")
      .not-found(v-if="showNoResults && !product") Product {{ `#${itemNumber}` }} not found
    .product-details(v-if="product")
      .left
        image-display(:modelValue="imageSource" :width="imageDimension" :height="imageDimension")    
        product-sds(v-model="product")   
        product-order-history(v-model="product" :customerId="customer.CustomerID" :orderHistoryPageSize="20")
      .purchase-info(v-if="customerNumber")
        product-purchase-info(v-model="product" :enablePricing="enablePricing" :isCustomerUser="isCustomerUser" :enableCart="enableCart")      
        product-availability(v-model="product" :customer="customer" :isCustomerUser="isCustomerUser" :compactView="false")
    product-description(v-model="product")
    product-coupon(v-model="product" )
</template>
  
<script setup>
  import ImageDisplay from '@/components/ImageDisplay'  
  import ProductHeader from '@/components/ProductHeader'
  import ProductPurchaseInfo from '@/components/ProductPurchaseInfo'
  import ProductDescription from '@/components/ProductDescription'
  import ProductSds from '@/components/ProductSDS'
  import ProductAvailability from '@/components/ProductAvailability'
  import ProductCoupon from '@/components/ProductCoupon'
  import ProductOrderHistory from '@/components/ProductOrderHistory'
  import { getProduct, getImageUrlFromProduct } from '../utility/Util.js'
  import { inject, ref, computed, onMounted, watch, onUnmounted } from 'vue'
  import { useStore } from 'vuex'  

  const largeImageSize = 300
  const mediumImageSize = 232
  const smallImageSize = 116
  
  const store = useStore()
  const { interceptor } = inject('httpInterceptor')
  const product = ref(null)
  const dataLoaded = ref(false)
  const imageDimension = ref(largeImageSize)

  const emit = defineEmits([  
    'closeProductView'
  ])


  const props = defineProps({
    customerNumber: {
      type: String
    },
    itemNumber: {
      type: String,
      required: true      
    }
  })

  watch(() => [props.customerNumber, props.itemNumber], 
    async ([newCustomerNumber, newItemNumber], [oldCustomerNumber, oldItemNumber]) => {
      await loadData()
  }) 


  onMounted(async () => {    
    await loadData()
  })

  onUnmounted(() => {
    window.removeEventListener('resize', setImageDimensions);
  })

  
  const showNoResults = computed(() => dataLoaded.value && !product?.value)
  const imageSource = computed(() => getImageUrlFromProduct(product.value))
  const enablePricing = computed(() => store.getters.pricingEnabled)
  const enableCart = computed(() => store.getters.shoppingCartEnabled)
  const isCustomerUser = computed(() => store.getters.isCustomerUser)
  const customer = computed(() => store.getters.customer)
  const loading = computed(() => !dataLoaded.value && !product?.value)

  const loadData = async () => {
    dataLoaded.value = false
    product.value = null
    product.value = await getProduct(interceptor, props.itemNumber, props.customerNumber)    
    dataLoaded.value = true    
  }

  const setImageDimensions = () => {
    const clientWidth = document.documentElement.clientWidth
    imageDimension.value = clientWidth >=500 ? largeImageSize 
    : (clientWidth < 350 ? smallImageSize : mediumImageSize)
  }

  const emitCloseProductView = () => {
    emit('closeProductView')
  }

  setImageDimensions()
  window.addEventListener('resize', setImageDimensions)

</script>
  
<style scoped lang="scss">
.product-display {
  display: flex;    
  flex-direction: column;
  align-items: center;
  background-color: $white;
  flex: 1 1 auto;
  width: 100%;
  padding: 8px;

  .product {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-left: 8px;

    @media only screen and (max-width: $phone-portrait) {
          padding: 0;          
    }     
    .head {
      width: 100%;
      display: flex;
      flex-direction: row;
      .header {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
    .product-details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      column-gap: 8px;
      
      .left {
        width: 100%;

        & > * {
          padding-top: 4px;
        }
        .image-display {
          align-self: center;
        }
        
      }
      .product-sds {
        padding: 16px 0;
      }
      .purchase-info {
        border-left: 1px solid $gray;        
        padding: 0 24px;
        @media only screen and (max-width: $phone-portrait) {
          padding: 0 8px;          
        } 
        .product-purchase-info {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.not-found, .loading {
  color: $sunrise;
  font-size: 20px;
  margin: 0 auto;
  padding: 8px;
  background-color: $white;
  width: 100%;
  display: flex;
  justify-content: center;      
  @media only screen and (max-width: $phone-portrait) {
    width: 100%;
  }            
  
}
</style>