<template>
  <input
    ref="inputRef"
    type="text"
    :value="formattedValue"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue'

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number, 
    options: Object
  },
  setup(props, setOptions) {
    const { formattedValue, inputRef, setValue } = useCurrencyInput(props.options, false)

    watch(
      () => props.modelValue, 
      (value) => {
        setValue(value)
      }
    )
    return { inputRef, formattedValue }
  },
  
}
</script>