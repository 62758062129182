<template lang="pug">
bd-slider(v-bind:modelValue="show" @closeSlider="closeSlider")
  template(v-slot:content="")      
    product-display(v-if="modelValue" :itemNumber="modelValue" :customerNumber = "customerNumber" @closeProductView="closeSlider")
</template>

<script setup>
import ProductDisplay from '@/components/ProductDisplay'

const props = defineProps({
  modelValue: {
    type: String
  },
  show: {
    type: Boolean,
    required: false,
    default: false      
  }, 
  customerNumber: {
    type: String
  }

})

const emit = defineEmits([
  'closeSlider'
])

const closeSlider = () => {
  emit('closeSlider')
}

</script>

<style scoped lang="scss">
</style>