<template lang="pug">
.pricing-override
    .undo-request(v-if="toggleUndo && !isRedemptionItem") 
        svg.price-reset-icon( @click="undoUpdate()" width="22" height="18" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg")
    .price
    currency-input(
            name="priceOverride"    
            v-model="inputValue",
            :options="{currency: 'USD', currencyDisplay: 'symbol', precision: 2, valueRange: { min: 0, max: 100000 }}"
            :class="'reactive'"
            :disabled="disabled"
            @change="update($event.target.value)"
            
        )
    .right-buffer
</template>

<script>
import CurrencyInput from './CurrencyInput'

    export default {
    name:'price-override-input',
    components: {CurrencyInput},
    props: {
        inputPrice: {
            type: Number,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isRedemptionItem: {
            type: Boolean,
            default: false
        },
        overridePrice: {
            type: Number
        },
        originalPrice: {
            type: Number
        }
    },
    data () {
        return {
            inputValue: this.inputPrice,
            priceUpdated: false
        }
    },
    emits: [
        'validPriceOverride',
    ],
    computed: {
        toggleUndo () { return this.overridePrice != null }
    },
    methods: {
        undoUpdate () {
            this.inputValue = this.originalPrice
            this.$emit('validPriceOverride', null)
            this.priceUpdated = false
        },
        update(newValue) {

            if(newValue == this.originalPrice)
            {
                this.undoUpdate()
            }
            else
            {
                this.priceUpdated = true
                this.$emit('validPriceOverride', +newValue)
                this.inputValue = Number(newValue)  
            }
            
        }
    }, 
}
</script>

<style scoped lang="scss">
    input.reactive {
        width: 85px;
        border-radius: 35px;
        border: 1px solid transparent;
        border-color: $iced-slate;
        background-color: $white;
        color: $deep-sea;
        padding: 0 0 0 15px;          
        min-height: 24px;    
        font-size: 12px;
        margin-left: 4px;
        appearance: textfield;
    
        &:focus {
        outline: none;
        }
    
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        } 
    }
    .price-reset-icon {
        background: url("../assets/price-reset.svg") no-repeat;
    }

    .pricing-override {
        display: flex;
        align-items: center;
        flex-flow: row;
        justify-content: space-between;

        .price{
            display: flex;
            align-items: center;
            height: 51px;
        }

        .undo-request {
            cursor: pointer;
            margin: 0 16px;
        }
        .right-buffer {
            width: 72px;
        }
    }

    input {
        border-radius: 35px;
        border: 1px solid transparent;
        border-color: $iced-slate;
        background-color: $white;
        color: $deep-sea;
        padding: 0 0 0 15px;          
        min-height: 24px;    
        font-size: 12px;
        width: 54px;
        margin-left: 4px;

        &:focus {
            outline: none;
        }
    }  
</style>