<template lang="pug">
.customer-info(v-bd-click-outside="hideCustomerInfoDropdown")
  .info-block
    bd-button.full.button(icon="account_box" darkmode @click="showCustomerInfoDropdown")
      .label
        .full {{ customer.CustomerID }} - {{ customer.Name }}
        .mobile {{ customer.CustomerID }}

  .customer-info-dropdown(v-bind:class="{show: showCustomerInfo}")
    .name.menu-item {{ customer.Name }}

    bd-button.menu-item(
      v-if="customer.ShipToPhoneNumber" 
      icon="contact_phone" 
      @click="openCustomerInfo('tel:' + customer.ShipToPhoneNumber)"
    ) 
      .label {{ customer.ShipToPhoneNumber }}

    bd-button.menu-item(
      v-if="customer.EmailAddress" 
      icon="contact_mail" 
      @click="openCustomerInfo('mailto:' + customer.EmailAddress)"
    ) 
      .label {{ customer.EmailAddress }}

    .column-group.menu-item
      .icon 
        ui-icon place
      .menu-item-container
        .address-container
          .address-line-1.label {{ customer.ShipToAddress1 }} {{ customer.ShipToAddress2 }}
          .address-line-2.label {{ customer.ShipToCity }} {{ customer.ShipToState }} {{ customer.ShipToZIp }}
    .internal-info(v-if="!isCustomerUser")
      .hr
      .column-group
        bd-button.menu-item(v-bind:class="{hide: isShippingLabelLoading, disabled: hasShippingLabelError}" icon="description" @click="createShippingLabel") Create Shipping Label
        .loading.menu-item(v-bind:class="{hide: !isShippingLabelLoading}")
          ui-spinner(v-if="isShippingLabelLoading" :size="'small'" active) 
        .loading-text.menu-item(v-if="isShippingLabelLoading") Generating Shipping Label
      .hr
      .column-group.menu-item
        .icon 
          ui-icon paid
        .menu-item-container(v-if="customer.FinancialSummary")
          .item
            .label Statement on {{ dateFormatter(customer.FinancialSummary.StatementDate)}}:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.StatementBalance || 0) }}
          .item(v-if="customer.FinancialSummary.LastPaymentDate")
            .label Last Payment on {{ dateFormatter(customer.FinancialSummary.LastPaymentDate) }}:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.LastPaymentAmount || 0) }}
          .item(v-else="") No payment info available
          .title Balance
          .item
            .label Future:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.Future || 0) }}          
          .item
            .label Current:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.Current || 0) }}
          .item(v-bind:class="{alert: customer.FinancialSummary.Over30 > 0}")
            .label Over 30:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.Over30 || 0) }}
          .item(v-bind:class="{alert: customer.FinancialSummary.Over60 > 0}")
            .label Over 60:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.Over60 || 0) }}
          .item(v-bind:class="{alert: customer.FinancialSummary.Over90 > 0}")
            .label Over 90:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.Over90 || 0) }}
          .item(v-bind:class="{alert: customer.FinancialSummary.Over120 > 0}")
            .label Over 120:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.Over120 || 0) }}            
          .item
            .label Total:
            .spacer
            .amount {{ currencyFormatter(customer.FinancialSummary.BalanceDue || 0) }}
        .item(v-else="") No accounting info available
      .hr 
      .column-group
        bd-button.menu-item(icon="summarize" @click="showPopOut(customerPopOuts.SalesReport)") 13 month category sales report
      .hr
      .column-group.menu-item
        .icon 
          ui-icon shopping_bag
        .menu-item-container(v-if="customer.PreferredStatus")
          .item
            .label Pricing Class
            .spacer
            .value {{ customer.PreferredStatus }}          
        .item(v-else="") No pricing class info available
      .hr
      .column-group.menu-item
        .icon 
          ui-icon receipt_long          
        .menu-item-container(v-if="customer.ContractName")
          .title Contract          
          .value {{ customer.ContractName }}
        .menu-item-container.item(v-else="") 
          .label No contract info available
    .hr.sales-territory-section(v-if="customer.SalesTerritory")
    .column-group.menu-item(v-if="customer.SalesTerritory")
      .icon
        ui-icon supervisor_account
      .menu-item-container
        .label.title Account Manager
        .label.account-manager(v-if="accountManager?.name") {{ accountManager.name }}
        bd-button.label(
          v-if="accountManager?.email" 
          icon="" 
          @click="openCustomerInfo('mailto:' + accountManager.email)"
        ) {{ accountManager.email }}
</template>

<script>  
  import { mapGetters } from 'vuex'
  import { inject } from 'vue'
  import { CustomerPopOuts } from '@/utility/Constants.js'


  export default {
    name:'CustomerInfo',
    props: {
      id: String
    },
    emits: [
      'showPopOut'
    ],
    setup () {
      const { interceptor } = inject('httpInterceptor')
      const currencyFormatter = inject('currencyFormatter')
      const dateFormatter = inject('dateFormatter')
      const customerPopOuts = CustomerPopOuts
      return {
        interceptor,
        currencyFormatter,
        dateFormatter,
        customerPopOuts
      }
    },
    data() {
      return {
        showCustomerInfo: false,
        isShippingLabelLoading: false,
        hasShippingLabelError: false
      }
    },
    methods: {
      showCustomerInfoDropdown () {
        this.showCustomerInfo = true
      },
      hideCustomerInfoDropdown () {
        this.showCustomerInfo = false
      },
      openCustomerInfo (link) {
         window.location.href = link
         this.hideCustomerInfoDropdown()
      },
      async createShippingLabel () {
        this.isShippingLabelLoading = true
        this.hasShippingLabelError = false
        
        await this.interceptor.get(`shippinglabel/customerNumber/${this.id}`)
          .then(response => {
            if (response.data && response.data.labelData && response.data.trackingNumber) {
              let downloadLink = document.createElement('a')
              downloadLink.target = '_blank'
              let binaryString = window.atob(response.data.labelData);
              let len = binaryString.length;
              let bytes = new Uint8Array(len);
              for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              var blob = new Blob([bytes], { type: `application/${response.data.labelFormatCode}` })
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob)
                return
              }        
              var URL = window.URL || window.webkitURL
              var downloadUrl = URL.createObjectURL(blob)
              
              downloadLink.href = downloadUrl
              downloadLink.download = `${response.data.trackingNumber}.${response.data.labelFormatCode}`
              document.body.appendChild(downloadLink)
              downloadLink.click()
              setTimeout(function() {
                //clean up
                document.body.removeChild(downloadLink)
                URL.revokeObjectURL(downloadUrl)
              }, 100)
            } else {
              this.hasShippingLabelError = true
            }
          })
          .catch(error => {
            console.log(error)
            this.hasShippingLabelError = true
          })

          this.isShippingLabelLoading = false
      },
      showPopOut (popOut) {
        this.hideCustomerInfoDropdown()
        this.$emit('showPopOut', popOut)
      }
    },
    computed: {
      ...mapGetters([
        'customer',
        'isCustomerUser'
      ]),
      accountManager () {        
        let territoryInfo = this.customer.SalesTerritory                

        let name = `${(territoryInfo?.FirstName ?? '')} ${(territoryInfo?.LastName ?? '')}`

        name = name.trim()

        let email = territoryInfo?.EmailAddress

        let info = {
          name: name,
          email: email
        }

        return info
      }      
    }
  }
</script>

<style scoped lang="scss">
  .customer-info {

    .alert {
      background-color: lighten($sunrise, 30);
    }

    .info-block {
      padding: 0 10px;
      white-space: nowrap;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      .label {
        display: flex;
          .full {
        display: block;

        @media only screen and (max-width: 319px) {
            display: none;
          }
        }

        .mobile {
          display: block;

          @media only screen and (min-width: 320px) {
            display: none;
          }
        }
      }

    }

    .customer-info-dropdown {
      justify-content: flex-end;
      background-color: $white;
      border: 1px solid $gray;
      box-shadow: 0px 3px 8px $shadow-color;
      position: absolute;
      padding: 0 8px;
      display: none;
      top: 76px;
      z-index: 1010;
      width: 350px;
      overflow-x: hidden;

      &.show{
        display: block;
      }

      .label {
        
        &.title {
          font-weight: bold;
        }
      }

      .menu-item {
        margin: 8px 0;
        color: $iced-slate;

      }

      .hr {
        height: 1px;
        background-color: $teal;
        margin: 3px 0;
      }

      .name {
        @media only screen and (min-width: 319px) {
          display: none;
        }
      }

      .column-group {
        display: flex;
        justify-content: flex-start;        
      }

      .menu-item-container {
        width: 100%;        

        .item {
          .spacer {
            flex-grow: 1;
            border-bottom: 1px dashed $gray;
            margin: 8px;
          }
          display: flex;
          justify-content: space-between;
        }
        .address-container {
          display:flex;
          flex-wrap: wrap;
          flex-direction: column;
        }        
      }
    }
    .material-icons {
      margin-right: 5px;
      margin-left: 5px;
    }
    
    .loading {
      .mdc-circular-progress {
        margin-right: 9px;
        margin-left: 5px;
        padding: 0px;
        height: 21px;
      }
      
    }

    .bd-button {
      padding: 0px;
      margin: 0px;
      color: $iced-slate;
      font-size: inherit !important;
    }

    .hide {
      display:none;
    }

    .disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: #c0c0c0;
      background-color: #ffffff;
    }
  }
</style>