<template lang="pug">
.product-coupon(v-if="hasCoupon")
  .label Coupon
  .date-range Start: {{ dateFormatter(coupon.data.StartDate) }}
  .date-range End: {{ dateFormatter(coupon.data.EndDate) }}
  .description {{ coupon.data.Description }}
</template>

<script setup>
import { inject, computed } from 'vue'
import { getCoupon, isPricingLoaded } from '../utility/Util.js'

const dateFormatter = inject('dateFormatter')

const props = defineProps({
  modelValue: {
    type: Object
  }, 
  waitForPricingToLoad: {
    type: Boolean,
    default: false
  }  
})

const coupon = computed(() => getCoupon(props.modelValue))
const hasCoupon = computed(() => !!coupon.value && isPricingLoaded(props.modelValue, props.waitForPricingToLoad))
</script>

<style scoped lang="scss">
.product-coupon {
  display: flex;
  flex-flow: column;
  gap: 4px;
  
  .description {
    padding-top: 4px;
  }
}

</style>