<template lang="pug">
.product-availability
  .stocking-class(class='out-of-stock' v-if="modelValue.isOnHold") Temporarily Unavailable                  
  .customer-availability(v-else-if="isCustomerUser")
    .stocking-class.no-spacing(:class="{'available': !isOutOfStock, 'out-of-stock': isOutOfStock}") {{ customerStockingStatus }}
  .account-manager-availability(v-else-if="hasWarehouseAvailability")    
    .header 
      .label Availability
      .stocking-class(:class="{[primaryStockingClass.replaceAll(' ', '-').toLowerCase()]: primaryStockingClass != ''}") {{ primaryStockingClass }}
    .dc-list(:class="{compact: compactView}")
      .dc(v-for="dc in availability" :key="dc.key")
        .location {{ dc.LocationDescription }}
        .quantity(v-if="dc.inventory > 0") {{ dc.inventory }} in stock
        .out(v-else="") out of stock
</template>

<script setup>
import { computed } from 'vue'

const PRIMARY_INVENTORY_INDEX = 0
const SECONDARY_INVENTORY_INDEX = 1

const props = defineProps({
  modelValue: {
    type: Object
  }, 
  customer: {
    type: Object
  }, 
  isCustomerUser: {
    type: Boolean,
    default: true
  },
  compactView: {
    type: Boolean,
    default: true
  }
})

const dcMapList = [
  {
    "LegacyBranch": "14",
    "LocationDescription": "Tacoma, WA",
    "WarehouseNumber": "DC1"
  },
    {
    "LegacyBranch": "16",
    "LocationDescription": "Reno, NV",
    "WarehouseNumber": "DC3"
  },
    {
    "LegacyBranch": "82",
    "LocationDescription": "Irving, TX",
    "WarehouseNumber": "DC2"
  },
    {
    "LegacyBranch": "18",
    "LocationDescription": "Irving, TX",
    "WarehouseNumber": "DC2"
  }
]




const isOutOfStock = computed(() => {
  return _getAvailableInventory(PRIMARY_INVENTORY_INDEX) == 0 
  && _getAvailableInventory(SECONDARY_INVENTORY_INDEX) == 0}
  )
const customerStockingStatus = computed(() => isOutOfStock.value ? 'Ships in 2-3 weeks' : 'Available')  
const availability = computed(() => {
  let warehouses = null  
  if (props.customer?.DCsMerchs) {    
    warehouses = props.customer.DCsMerchs.map(d => ({
        MerchDC: d.MerchDC,
        VMPosition: d.VMPosition,
        LocationDescription: ""
      }))
  
    warehouses.sort((a, b) => (a.VMPosition > b.VMPosition))
    warehouses.forEach((warehouse) => {
      let dcMap = dcMapList.find(obj => obj.LegacyBranch == warehouse.MerchDC)
      if (dcMap) {
        warehouse.LocationDescription = dcMap.LocationDescription
        warehouse.WarehouseNumber = dcMap.WarehouseNumber
      }
      let inventory = props.modelValue.inventories?.find(obj => obj.warehouseNumber == warehouse.WarehouseNumber)
      if (inventory) {
        warehouse.inventory = inventory.quantityAvailable
        warehouse.stockingClass = inventory.stockingClass
      } else {
        warehouse.inventory = 0
        warehouse.stockingClass = null
      }
      warehouse.key = props.modelValue.itemNumber + warehouse.MerchDC
      return warehouse
    })
  }
  return warehouses? warehouses.filter(warehouse => warehouse.LocationDescription) : warehouses  
})
const hasWarehouseAvailability = computed(() => !!availability.value)  
const primaryStockingClass = computed(() => hasWarehouseAvailability.value ? (availability.value[0].stockingClass ?? '') : '')

const _getAvailableInventory = (availabilityIndex) => hasWarehouseAvailability.value ? (availability.value[availabilityIndex]?.inventory ?? 0) : 0
</script>

<style scoped lang="scss">
.product-availability {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-direction: row;  
  .stocking-class {
    &.no-spacing {
      margin: 0px;
    }
    &.recurring-seller, &.available {
      color: $spring;
    }
    &.sporadic-seller {
      color: #b4ae27;
    }
    &.expect-vendor-delays, &.out-of-stock {
      color: $grapefruit;
    }
    &.special-order {
      color: $grapefruit;
    }  
  }

  .account-manager-availability {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .header {
      display: flex;
      flex-direction: column;      
    }
  }

  .dc-list {
    display: flex;
      
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;

    &.compact {
      flex-direction: row;
      font-size: 14px;
    }

    @media only screen and (width > $phone-portrait) {
      font-size: 14px;
    }        

    @media only screen and (max-width: $phone-portrait) {
      flex-direction: row;
    }    
    
    .location {
      width: 80px;
    }


    .out {
      color: $grapefruit;
    }
  }
}

</style>