<template lang="pug">
.help(v-bd-click-outside="hideHelp")
  bd-button(@click="showHelp" icon="help")    
  .help-dropdown(v-bind:class="{show: show}")
    .item Customer Service
    .item
      bd-button(icon="contact_phone" @click="call") 800.562.8176
    .item
      bd-button(icon="contact_mail" @click="email") Send Email
    .item 
      .appVersion {{ appVersionText }}

</template>

<script>
  export default {
    name:'Help',    
    props: {},
    data() {
      return {
        show: false
      }
    },
    computed: {
      appVersionText () {
        return `Toolkit Version ${process.env.VUE_APP_VERSION}`
      }
    },
    methods: {
      call () {
         this.navigateTo("tel:800-562-8176")            
      },
      email () {
        this.navigateTo("mailto:customerservice@burkhartdental.com")        
      },
      navigateTo(link) {
        window.location.href = link
        this.hideHelp()
      },
      showHelp () {
        this.show = true
      },
      hideHelp () {        
        this.show = false
      },
    },
  }
</script>

<style scoped lang="scss">

  .help {
    display: inline;
    
    .bd-button {
      color: $white;

      &:hover {
        background-color: $deep-sea !important;
      }
    }

    .help-dropdown {
      justify-content: flex-end;
      background-color: $white;
      border: 1px solid $gray;
      box-shadow: 0px 3px 8px $shadow-color;
      position: absolute;
      padding: 8px;
      display: none;
      top: 35px;
      right: 0px;
      z-index: 1010;
      
      &.show{
        display: block;
      }

      .item {
        padding: 3px;
        color: $deep-sea;
        font-weight: 400;
        font-size: 14px;        
        .bd-button {
          color: $iced-slate;

          &:hover {
            background-color: darken($white, 15) !important;
          }
        }

        .appVersion {
          font-size: 12px;
          padding-left: 8px;
        }
        
      }
    }
  }
</style>