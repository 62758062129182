<template lang="pug">
.search-header(v-bind:class="{ header: currentRouteName != 'Home' && !hasSearchError }")
  .search-container(v-bind:class="{ header: currentRouteName != 'Home' && !hasSearchError }")
    .search-block(v-if="auth.loggedIn && isUserAM")
      .search-by
        .select
          bd-tabs(v-slot="slotProperties" ref="tabs")
            bd-button(@click="slotProperties.onClick; searchBySelect(1);" icon="person" ref="myCustomers") My Customers 
            bd-button(@click="slotProperties.onClick; searchBySelect(2);" icon="people" ref="allCustomers") All Customers          
        .select.header
          bd-tabs(v-slot="slotProperties" ref="tabs2")
            bd-button(@click="slotProperties.onClick; searchBySelect(1);" icon="person" ref="myCustomers2") My Customers 
            bd-button(@click="slotProperties.onClick; searchBySelect(2);" icon="people" ref="allCustomers2") All Customers      
    .dataContainerAccountManager(v-if="auth.loggedIn" v-bind:class="{ relativePositioning: currentRouteName != 'Home' }")
      bd-auto-complete(
        :bdOptions="accounts"
        :addResultListBorder="false"
        ref="autocomplete"
        itemModelKey="customerNumber"
        v-model="searchInputString"
        @input="inputSearch"
        @optionSelect="goToCustomer"
        @focus="focusMobileSearch"
        @blur="blurMobileSearch"
        @update:searchText="clearError"
        @onerror="autoCompleteError"
        placeholder="Search by customer name, id, or phone #"
        :alwaysShowResults="true"
        :autoCompleteResultCssPosition="'static'"
        :enableIcon="true"
        iconOption="search"
        :enableClearInputButton="true"
        :enableSort="true"
        v-bind:class="{ hasLogo: isUserAM }"
      )
        template(v-slot:iconOptionSlot="")
          ui-spinner(:size="'small'" active v-if="loadingSearchData")
          ui-icon(v-else="") search
        template(v-slot:sortOptionsSlot="")
          .sortOptionsContainer
            .icon(v-bd-click-outside="hideSortOptions" @click="showSortOptions" v-html="currentlySelectedSort.icon")
          .dropdown(v-if="showSortOptionsMenu")
            .icon(v-for="sortOption in sortOptions" @click="updateSort(sortOption)" v-html="sortOption.icon")
        template(v-slot:bdAutocompleteItem="{item}")
          .itemContainer
            .topRowData 
              .topRowLeft
                ui-icon person
                .customerNumber {{ item.customerNumber }}
                ui-icon phone
                .customerPhone {{ item.phoneNumber }}
              .topRowRight(v-if="hasOpenCartsWithItems(item) && (isUserCS || isUserAM)")
                .lastModified {{ item.openCarts[0].modified.slice(0,5) }}
                bd-button(
                  icon="shopping_cart",
                  :alert="item.openCarts[0].lineItemCount",
                  :badgeColor="'#8ABF41'",
                  :centerBadge="true",
                  :disableClick="true"
                )
            .customerName {{ handleDisplayValue(item.name, 30, isMobileDevice) }}
      .loading(v-if="loadingMoreResults") {{ loadingMessage }}
      .noResults(v-if="noResults") No matching results

    search-input(
      v-if="!auth.loggedIn"
      :action="goToOrder"
      :qrAction="showCamera"
      v-model="orderNumber"
      placeholder="Search"
      @focus="focusMobileSearch"
      @blur="blurMobileSearch"
    )
    .camera-display(v-bind:class="{show: showEmbeddedQrcodeReader, absolutePositioning: currentRouteName != 'Home'}" )
      qr-code-reader(id="qrcodereader" v-on:decodeEvent="qrCodeDecodedEventHandler" :scan="showEmbeddedQrcodeReader" v-bd-click-outside="hideCamera")

    .error(v-if="hasSearchError") {{ this.searchError }}
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import { BrowserQRCodeReader } from '@zxing/library'
  import QrCodeReader from '@/components/QrCodeReader'
  import SearchInput from '@/components/SearchInput'
  import isMobile from 'is-mobile'
  import { inject, ref } from 'vue' 
  import { EmittedEvents } from '@/utility/Constants.js'
  import { EventTypes } from "@burkhart/vue-components";
  import { dateFormatter, handleDisplayValue } from "@/utility/Util.js";
  import { authUtility } from '@burkhart/vue-components';
  import { emitter } from '@burkhart/vue-components'
  import { debounce } from 'throttle-debounce'



  export default {
    name: 'Search',
    setup () {
      const authUtility = inject('authUtility')
      const auth = inject('auth')
      const tabs = ref(null)
      const allCustomers = ref(null)
      const myCustomers = ref(null)
      const tabs2 = ref(null)
      const allCustomers2 = ref(null)
      const myCustomers2 = ref(null)
      const {interceptor} = inject('httpInterceptor')
      const emitter = inject('emitter')
      const autocomplete = ref({filterTerm: ''})
      const sortOptions = [
        {
          name: 'sort_by_name_ascending',
          icon: 
            `<svg width="9" height="31" viewBox="0 6 9 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.312 12.496C4.74133 12.5227 4.26133 12.5733 3.872 12.648C3.48267 12.7173 3.17067 12.808 2.936 12.92C2.70133 13.032 2.53333 13.1627 2.432 13.312C2.33067 13.4613 2.28 13.624 2.28 13.8C2.28 14.1467 2.38133 14.3947 2.584 14.544C2.792 14.6933 3.06133 14.768 3.392 14.768C3.79733 14.768 4.14667 14.696 4.44 14.552C4.73867 14.4027 5.02933 14.1787 5.312 13.88V12.496ZM0.784 8.936C1.728 8.072 2.864 7.64 4.192 7.64C4.672 7.64 5.10133 7.72 5.48 7.88C5.85867 8.03467 6.17867 8.25333 6.44 8.536C6.70133 8.81333 6.89867 9.14667 7.032 9.536C7.17067 9.92533 7.24 10.352 7.24 10.816V16H6.344C6.15733 16 6.01333 15.9733 5.912 15.92C5.81067 15.8613 5.73067 15.7467 5.672 15.576L5.496 14.984C5.288 15.1707 5.08533 15.336 4.888 15.48C4.69067 15.6187 4.48533 15.736 4.272 15.832C4.05867 15.928 3.82933 16 3.584 16.048C3.344 16.1013 3.07733 16.128 2.784 16.128C2.43733 16.128 2.11733 16.0827 1.824 15.992C1.53067 15.896 1.27733 15.7547 1.064 15.568C0.850667 15.3813 0.685333 15.1493 0.568 14.872C0.450667 14.5947 0.392 14.272 0.392 13.904C0.392 13.696 0.426667 13.4907 0.496 13.288C0.565333 13.08 0.677333 12.8827 0.832 12.696C0.992 12.5093 1.19733 12.3333 1.448 12.168C1.69867 12.0027 2.00533 11.8587 2.368 11.736C2.736 11.6133 3.16267 11.5147 3.648 11.44C4.13333 11.36 4.688 11.312 5.312 11.296V10.816C5.312 10.2667 5.19467 9.86133 4.96 9.6C4.72533 9.33333 4.38667 9.2 3.944 9.2C3.624 9.2 3.35733 9.23733 3.144 9.312C2.936 9.38667 2.752 9.472 2.592 9.568C2.432 9.65867 2.28533 9.74133 2.152 9.816C2.024 9.89067 1.88 9.928 1.72 9.928C1.58133 9.928 1.464 9.89333 1.368 9.824C1.272 9.74933 1.19467 9.664 1.136 9.568L0.784 8.936Z" fill="#36667D"/>
              <path d="M6.872 20.608C6.872 20.7467 6.848 20.8827 6.8 21.016C6.752 21.1493 6.688 21.2587 6.608 21.344L2.712 26.48H6.752V28H0.416V27.176C0.416 27.0853 0.437333 26.9787 0.48 26.856C0.522667 26.728 0.586667 26.608 0.672 26.496L4.608 21.304H0.648V19.792H6.872V20.608Z" fill="#36667D"/>
            </svg>`,
          sortType: 0,
          ascending: true
        },
        {
          name: 'sort_by_name_descending',
          icon:
            `<svg width="9" height="31" viewBox="0 6 9 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.312 24.496C4.74133 24.5227 4.26133 24.5733 3.872 24.648C3.48267 24.7173 3.17067 24.808 2.936 24.92C2.70133 25.032 2.53333 25.1627 2.432 25.312C2.33067 25.4613 2.28 25.624 2.28 25.8C2.28 26.1467 2.38133 26.3947 2.584 26.544C2.792 26.6933 3.06133 26.768 3.392 26.768C3.79733 26.768 4.14667 26.696 4.44 26.552C4.73867 26.4027 5.02933 26.1787 5.312 25.88V24.496ZM0.784 20.936C1.728 20.072 2.864 19.64 4.192 19.64C4.672 19.64 5.10133 19.72 5.48 19.88C5.85867 20.0347 6.17867 20.2533 6.44 20.536C6.70133 20.8133 6.89867 21.1467 7.032 21.536C7.17067 21.9253 7.24 22.352 7.24 22.816V28H6.344C6.15733 28 6.01333 27.9733 5.912 27.92C5.81067 27.8613 5.73067 27.7467 5.672 27.576L5.496 26.984C5.288 27.1707 5.08533 27.336 4.888 27.48C4.69067 27.6187 4.48533 27.736 4.272 27.832C4.05867 27.928 3.82933 28 3.584 28.048C3.344 28.1013 3.07733 28.128 2.784 28.128C2.43733 28.128 2.11733 28.0827 1.824 27.992C1.53067 27.896 1.27733 27.7547 1.064 27.568C0.850667 27.3813 0.685333 27.1493 0.568 26.872C0.450667 26.5947 0.392 26.272 0.392 25.904C0.392 25.696 0.426667 25.4907 0.496 25.288C0.565333 25.08 0.677333 24.8827 0.832 24.696C0.992 24.5093 1.19733 24.3333 1.448 24.168C1.69867 24.0027 2.00533 23.8587 2.368 23.736C2.736 23.6133 3.16267 23.5147 3.648 23.44C4.13333 23.36 4.688 23.312 5.312 23.296V22.816C5.312 22.2667 5.19467 21.8613 4.96 21.6C4.72533 21.3333 4.38667 21.2 3.944 21.2C3.624 21.2 3.35733 21.2373 3.144 21.312C2.936 21.3867 2.752 21.472 2.592 21.568C2.432 21.6587 2.28533 21.7413 2.152 21.816C2.024 21.8907 1.88 21.928 1.72 21.928C1.58133 21.928 1.464 21.8933 1.368 21.824C1.272 21.7493 1.19467 21.664 1.136 21.568L0.784 20.936Z" fill="#36667D"/>
              <path d="M6.872 8.608C6.872 8.74667 6.848 8.88267 6.8 9.016C6.752 9.14933 6.688 9.25867 6.608 9.344L2.712 14.48H6.752V16H0.416V15.176C0.416 15.0853 0.437333 14.9787 0.48 14.856C0.522667 14.728 0.586667 14.608 0.672 14.496L4.608 9.304H0.648V7.792H6.872V8.608Z" fill="#36667D"/>
            </svg>`,
          sortType: 0,
          ascending: false
        },
        {
          name: 'sort_by_cart_descending',
          icon: 
            `<svg width="20" height="31" viewBox="0 -2 20 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5446 11C15.2943 11 15.9541 10.59 16.2939 9.97L19.8726 3.48C20.2425 2.82 19.7627 2 19.0029 2H4.20844L3.26879 0H0V2H1.99926L5.59792 9.59L4.24842 12.03C3.51869 13.37 4.47834 15 5.99777 15H17.9933V13H5.99777L7.09736 11H14.5446ZM5.15808 4H17.3036L14.5446 9H7.5272L5.15808 4ZM5.99777 16C4.89818 16 4.00851 16.9 4.00851 18C4.00851 19.1 4.89818 20 5.99777 20C7.09736 20 7.99703 19.1 7.99703 18C7.99703 16.9 7.09736 16 5.99777 16ZM15.9941 16C14.8945 16 14.0048 16.9 14.0048 18C14.0048 19.1 14.8945 20 15.9941 20C17.0937 20 17.9933 19.1 17.9933 18C17.9933 16.9 17.0937 16 15.9941 16Z" fill="#36667D"/>
            </svg>`,
          sortType: 1,
          ascending: false
        }
      ]
      const restrictedSortOptions = sortOptions.slice(0, 2)
      const requestQueue = []
    
      return {
        authUtility,
        auth,
        tabs,
        allCustomers,
        myCustomers,
        tabs2,
        allCustomers2,
        myCustomers2,
        interceptor,
        emitter,
        autocomplete,
        sortOptions,
        restrictedSortOptions,
        requestQueue
      }
    },
    components: {
      QrCodeReader,
      SearchInput
    },
    data () {
      return {
        errorProp: this.error,
        orderNumber: undefined,
        promise: undefined,
        useEmbeddedQrcodeReader: false,
        showEmbeddedQrcodeReader: false,
        cameraErrorMessage: undefined,
        showCameraErrorMessage: undefined,
        inputmin: false,
        generalErrorMessage: null,
        lastOrderNumber: undefined,
        refreshCount: 0,
        mobileSearchIsFocused: false,
        isMobileDevice: false,
        showQrReaderClicked: false,
        accounts: [],
        loadingMoreResults: false,
        loadingSearchData: false,
        loadingMessage: 'Loading more...',
        loadIndex: 0,
        searchInputString: '',
        showSortOptionsMenu: false,
        currentlySelectedSort: {},
        searchByAllCustomers: false,
        noMoreData: false,
        screenHeight: window.innerHeight
      }
    },
    props: {
      role: String
    },
    created () {
      if (this.errorProp !== undefined) {
        this.setSearchError(this.errorProp)
      }

      this.checkWebCam()
    },
    async mounted () {
      this.isMobileDevice = isMobile({tablet: false})
      window.addEventListener('resize', this.updateScreenHeight);

      
      // grab stored searchBy value if one exists for user
      const storedSearchBy = this.searchBy()

      if (!this.isUserAM) {
        this.searchByAllCustomers = true
        // default sortOption at load
        this.currentlySelectedSort = this.sortOptions[0]
        if (!this.isUserCS) {
          //if not CS and not AM, then restrict sort options to remove cart sort 
          this.sortOptions = this.restrictedSortOptions
        }
      } else {
        // default sortOption for AMs
        this.currentlySelectedSort = this.sortOptions[2]
        this.searchBySelect(storedSearchBy ? storedSearchBy : 1);
      }


      // attempt scroll listener just in case accounts already exist after last call
      this.attachScrollListener();      
    },
    beforeUnmount () {
      window.removeEventListener('resize', this.updateScreenHeight)
    },
    computed: {
      ...mapGetters([
        'customer',
        'searchCriteria',
        'hasSearchError',
        'searchError',
        'searchBy'
      ]),
      currentRouteName () {
        return this.$route.name;
      },
      noResults () {
        // this will only load when there's a filter term, no available options, and nothing else loading
        return ((this.autocomplete.filterTerm != '') && (this.autocomplete.filteredOptions.length == 0) && (!this.loadingMoreResults && !this.loadingSearchData))
      },
      isUserAM () {
        return (!this.authUtility.IsInRole('CUSTOMERSERVICE') && (this.authUtility.IsInRole('PRICINGVIEW') && this.authUtility.IsInRole('ORDERSUBMIT')))
      },
      isUserCS () {
        return this.authUtility.IsInRole('CUSTOMERSERVICE')
      },
      resultsContainerHeight () {
        if (this.isUserAM) {
          return `${this.screenHeight - 240}px`
        } else {
          return `${this.screenHeight - 175}px`
        }
      }
    },
    watch: {
      accounts(newValue) {
        // if accounts gets updated and has data in it, attach the scroll listener
        if (newValue.length > 0) {
          this.$nextTick(() => {
            this.attachScrollListener()
          })
        } 
      },
      'autocomplete.filterTerm': function (newValue) {
        if (newValue === '') {
          if (this.searchBy() === 1) {
            this.initialSearchMyCustomers();
          }
          this.resetData();
        }
      },
      'autocomplete.filteredOptions': function (newValue) {
        // in this instance, we really just want to make sure the filteredOptions always mimics the base data array
        // otherwise, you could get some hanging results in filteredOptions in between data resets
        // this will probably need to be pulled out when bdAutocomplete is updated
        if (newValue.length != this.accounts.length) {
          this.autocomplete.filteredOptions = this.accounts
        }
      }
    },
    methods: {
      ...mapMutations([
        'setSearchError',
      ]),
      ...mapActions([
        'updateSearchBy'
      ]),
      handleDisplayValue,
      inputSearch: debounce(300,
        async function () {
          if (this.requestQueue.length > 0) {
            while (this.requestQueue.length > 0) {
              let request = this.requestQueue.pop()
              request.abort()
            }
          }

          this.resetData();
          let params = {
            pageIndex: 0,
            pageSize: 100,
            searchText: this.autocomplete.filterTerm,
            allCustomers: this.searchByAllCustomers,
            ascending: this.currentlySelectedSort.ascending,
            sort: this.currentlySelectedSort.sortType
          };

          try {
            this.loadingSearchData = true;

            let controller = new AbortController()
            this.requestQueue.push(controller)

            const { data } = await this.interceptor.get(`/customer/search`, {
              params,
              signal: controller.signal
            });

            if (data.length > 0) {
              this.accounts = this.addFormattedDates(data);
              this.loadIndex++;
            }
            this.loadingSearchData = false;
          } catch (error) {
            this.resetData();
          }
        }
      ),
      hasOpenCartsWithItems (customerObject) {
        return (
          customerObject.openCarts &&
          customerObject.openCarts[0].lineItemCount >= 0
        )
      },
      updateScreenHeight() {
        this.screenHeight = window.innerHeight;
      },
      async loadMoreResults () {
        let params = {
          pageIndex: this.loadIndex,
          pageSize: 100,
          allCustomers: this.searchByAllCustomers,
          searchText: this.autocomplete.filterTerm,
          ascending: this.currentlySelectedSort.ascending,
          sort: this.currentlySelectedSort.sortType
        }

        try {
          this.loadingMoreResults = true;
          const { data } = await this.interceptor.get(`/customer/search`, { params });
          if (data.length > 0) {
            this.accounts.push(...(this.addFormattedDates(data)));
            this.loadIndex++;
          } else {
            this.noMoreData = true;
          }
        } catch (error) {
          console.log({ error });
          emitter.emit(EventTypes.ErrorEvent, `Error loading more results`);
          this.resetData()
        }
        this.loadingMoreResults = false;
      },
      async initialSearchMyCustomers () {
        let params = {
          pageIndex: 0,
          pageSize: 100,
          allCustomers: false,
          ascending: this.currentlySelectedSort.ascending,
          sort: this.currentlySelectedSort.sortType
        };
        
        try {
          this.loadingSearchData = true;
          const { data } = await this.interceptor.get(`/customer/search`, { params });
          if (data.length > 0) {
            this.accounts = this.addFormattedDates(data);
            this.loadIndex++;
          }
        } catch (error) {
          console.log({ error });
          emitter.emit(EventTypes.ErrorEvent, `Error loading your customers`);
          this.resetData()
          this.loadingSearchData = false;
        }
        this.loadingSearchData = false;
      },
      addFormattedDates (accountData) {
        accountData.forEach(account => {
              if (this.hasOpenCartsWithItems(account)) {
                for (let x = 0; x < account.openCarts.length; x++) {
                  account.openCarts[x].modified = dateFormatter(account.openCarts[x].modified);
                }
              }
            });
        return accountData
      },
      updateSort (sortOption) {
        this.currentlySelectedSort = sortOption
        this.resetData()
        this.inputSearch()
      },
      goHome () {
        if (this.currentRouteName != 'Home') {
          this.$router.push("/")
        }
      },
      goToCustomer (selected) {
        if (selected.customerNumber) {
          this.$router.push({ name: 'Customer', params: { id: selected.customerNumber } })
        }
      },
      autoCompleteError (message) {
        if (message) {
          this.setSearchError(message)
          this.goHome()
        }
      },
      clearError () {
          this.setSearchError(null)
      },
      focusMobileSearch () {
        this.mobileSearchIsFocused = true;
      },
      blurMobileSearch () {
        this.mobileSearchIsFocused = false;
      },
      openCustomerInfo (link) {
         window.location.href = link;
         this.hideCustomerInfoDropdown()
      },
      email (address) {
        window.location.href = address;
      },
      searchBySelect (value) {
        if (value == 1) {
          this.tabs.updateSlider(this.myCustomers)
          this.tabs2.updateSlider(this.myCustomers2)
          this.updateSearchBy(1)
          this.searchByAllCustomers = false;
          if (this.autocomplete.filterTerm != '') {
            this.inputSearch()
          } else {
            this.initialSearchMyCustomers()
          }
        } else if (value == 2) {
          this.tabs.updateSlider(this.allCustomers)
          this.tabs2.updateSlider(this.allCustomers2)
          this.updateSearchBy(2)
          this.searchByAllCustomers = true;
          if (this.autocomplete.filterTerm != '') {
            this.inputSearch()
          } else {
            this.resetData();
          }
        }
      },
      hideSortOptions () {
        this.showSortOptionsMenu = false;
      },
      showSortOptions () {
        this.showSortOptionsMenu = true;
      },
      goToOrder () {
        this.$parent.isOrderNumberKeyed = true

        if (!this.orderNumber) {
          return
        }

        this.showEmbeddedQrcodeReader = false

        let query = null

        if (this.lastOrderNumber == this.orderNumber) {
          //hack to refresh the route and prevent a NavigationDuplicated error
          this.refreshCount += 1
          query = {rc: this.refreshCount }
        } else {
          this.lastOrderNumber = this.orderNumber
        }

        this.navigateToRoute('Order', { id: this.orderNumber, keyed: true }, query);

      },
      navigateToRoute (name, params, query) {
        this.showEmbeddedQrcodeReader = false
        this.$router.push({ name: name, params: params, query: query }).catch(err=>{console.error(err);})
      },
      hideCamera (event) {
        if (!this.showQrReaderClicked) {
            this.showEmbeddedQrcodeReader = false
        } else {
          this.showQrReaderClicked = false
        }
      },
      showCamera (event) {
        this.inputmin = true
        if (this.useEmbeddedQrcodeReader == false) {
          this.showEmbeddedQrcodeReader = false
        }
        else {
          this.showEmbeddedQrcodeReader = !this.showEmbeddedQrcodeReader
          this.generalErrorMessage = ""
          if  (this.showEmbeddedQrcodeReader) {
            this.showQrReaderClicked = true
          }
        }
      },
      validateQrCodeUrl (url) {
        let validUrlRegex = process.env.VUE_APP_ALLOWED_URLS_REGEX
        let isValid = false

        if (!url) {
          return isValid
        }

        isValid = (url.match(validUrlRegex) != null)

        if (isValid) {
          this.generalErrorMessage = null
        } else {
          this.setSearchError("The QR Code does not contain a valid Burkhart url.")
        }


        return isValid
      },
      checkWebCam () {
        let codeReader = new BrowserQRCodeReader()
        this.useEmbeddedQrcodeReader = codeReader.isMediaDevicesSuported
      },
      qrCodeDecodedEventHandler(decodedValue){
        if (this.validateQrCodeUrl(decodedValue)) {
          this.showEmbeddedQrcodeReader = false
          window.location = decodedValue
        }

      },
      resetData () {
        this.accounts = []
        this.autocomplete.filteredOptions = []
        this.loadIndex = 0
        this.noMoreData = false
      },
      attachScrollListener () {
        //finds the specific div we need to listen for scroll activity on
        let autocompleteContainer = document.querySelector('.autocomplete-container');
        if (autocompleteContainer) {
          autocompleteContainer.addEventListener('scroll', this.scrollBottom);
        }
      },
      scrollBottom (event) {
        let autocompleteContainer = event.target
        const threshold = 3; // there's some scaling variability, so this helps make the scrolling more fluid
        if ((autocompleteContainer.scrollTop) && autocompleteContainer.scrollTop + autocompleteContainer.clientHeight >= autocompleteContainer.scrollHeight - threshold) {
          if (!this.noMoreData) {
            this.loadMoreResults();
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.loading, .noResults {
  margin-top: 4px;
  text-align: center;
}

:deep(.autocomplete-container) {
  width: 100%;
  margin: 32px auto 0 auto;
  padding: 0;
  border-radius: 8px !important;
  overflow-y: auto;
  background-color: $white !important;
  max-height: 800px;
  @media screen and (max-width: 520px) {
    border-radius: 0 !important;
  }
  @media screen and (max-height: 1100px) {
    max-height: v-bind(resultsContainerHeight);
  }
}

:deep(.autocomplete-input) {
  width: 100%;
}

:deep(.bd-input) {
  width: 100%;
  @media screen and (max-width: 520px) {
    width: 95%;
    margin: 0 auto;
  }
  &:focus-visible,
  &:active {
    outline: none !important;
  }
}

.search-block {
  display: fixed;
}

:deep(.autocomplete-container::-webkit-scrollbar-track) {
  border-radius: 0 8px 8px 0;
}

.itemContainer:hover {
  background-color: $gray;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $iced-slate;
  border-bottom: 1px solid $gray;
  padding: 8px;
  width: 100%;

  @media only screen and (max-width: 400px) {
    padding: 4px;
  }

  .topRowData {
    display: flex;
    width: 100%;
    justify-content: space-between;
    * {
      display: flex;
      align-items: center;
      height: 36px;
    }
    
    .topRowLeft {
      display: flex;
      width: 70%;
      
      .customerPhone {
        min-width: 108px;
        padding-right: 2px;
      }

    }
    .topRowRight {
      display: flex;

      .bd-button {
        @media only screen and (max-width: 400px) {
          margin: 0;
          padding: 2px;
        }
      }
    }
  }
}  
  .bd-button {
    color: $iced-slate;
    font-size: 14px;
  }
  .search-header {    
    width: 100%;
    &.header {
      background-color: lighten($iced-slate, 8);
    }

    .search-container {
      transition: .5s;
      width: 100%;   

      .qrcode-reader {
        padding: 0;
        min-width: 360px;

        &.relativePositioning {
          position: relative;
        }

          .camera-display {
            justify-content: space-between;
            background-color: $white;
            border: 1px solid $gray;
            box-shadow: 0px 3px 8px $shadow-color;
            padding: 8px;
            display: none;
            margin-top: 20px;
            z-index: 1010;


            &.show {
              display: block;

              &.absolutePositioning {
                position: absolute;
                width: 100%;
              }
            }
          }
      }

      .search-by {
        margin-bottom: 32px;
        .label {
          font-size: 12px;
          margin-left: 10%;

          @media only screen and (max-width: $phone-landscape) {
            margin-left: 0;
          }
        }

        .select {
          display: flex;
          justify-content: center;

          .button {
            margin-right: 10px;
          }
        }

        .select.header {
          display: none;
        }

        .slider {
          transition: all 0.2s linear;
          position: relative;
          height: 2px;
          background-color: $zest;
          width: 116px;
        }

        .slider.header {
          display: none;
        }
      }

      .qrcode-reader {
        padding: 18px 0;
      }

      &.header {
        background-color: lighten($iced-slate, 8);
        color: $white;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 8px;

        .logo-container {
          display: none;
        }

        .customer-info {
          display: flex;
        }

        .search-block {
          display: fixed;
        }

        .search-by {
          .label {
            display: none;
          }

          .select {
            display: none;
          }

          .slider {
            display: none;
          }

          .select.header {
            display: flex;
          }

          .slider.header {
            display: block;
          }
        }

        .qrcode-reader {
          padding: 0;
          min-width: 360px;

          @media only screen and (max-width: $phone-landscape) {
            min-width: 260px;
          }

          @media only screen and (max-width: 540px) {
            min-width: 89px;
          }

          .camera-display {
            position: absolute;
          }
        }
      }

      .error {
        color: $error;
      }
    }
  }

  .sortOptionsContainer {
    display: flex;
    max-width: 32px;
    padding: 0 8px;
  }

  .dropdown {
    position: absolute;
    background-color: $white;
    color: $iced-slate;
    top: 36px;
    right: 12px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    box-shadow: 0px 3px 8px $shadow-color;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 8px 8px;
    width: 40px;

    .icon {
      display: flex;
      justify-content: center;
      padding: 8px;
      width: 100%;
      &:hover {
        background-color: $grey;
        &:last-of-type {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }

  
</style>
