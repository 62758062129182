export default class CarrierTracking {

  static get FEDEX_REGEX() {
      return '^(([0-9]{20})|([0-9]{15})|([0-9]{12})|([0-9]{22}))'
  }
  static get UPS_REGEX() {
      return '^(1Z)[0-9A-Z]{16}$|^(T)+[0-9A-Z]{10}$|^[0-9]{9}$|^[0-9]{26}$'
  }
  static get ONTRAC_REGEX() {
      return '^[C|D]\\d{14}$'
  }
  static get FEDEX_CARRIER() {
      return 'FedEx'
  }
  static get UPS_CARRIER() {
      return 'UPS'
  }
  static get ONTRAC_CARRIER() {
      return 'Ontrac'
  }
  static get INVALID_CARRIER() {
      return 'Invalid'
  }

  

  shipmentCarrierGroups = []

  constructor(){

  }

  isVisible (shipmentNumber) {
    let shipmentCarrierTracking = this.getShipmentCarrierGroupInfo(shipmentNumber)
    return shipmentCarrierTracking && shipmentCarrierTracking.visible
  }

  hideOpen () {
    this.shipmentCarrierGroups.filter(sg => sg.visible).forEach(s => s.visible = false)
  }

  toggleVisibility (shipmentNumber) {

    //hide any other shipment tracking that are visible
    let currentlyVisible = this.shipmentCarrierGroups.find(s => s.visible && s.shipmentNumber != shipmentNumber)

    if (currentlyVisible) {
      currentlyVisible.visible = false
    }
    

    let toggleShipmentVisibility = this.shipmentCarrierGroups.find(s => s.shipmentNumber == shipmentNumber)
    if (toggleShipmentVisibility) {
      toggleShipmentVisibility.visible = !toggleShipmentVisibility.visible
    }
  }

  setUpTrackingGroups (shipments) {

    if (shipments && shipments.length > 0) {
      this.shipmentCarrierGroups = shipments.map(s => {
        let shipmentCarrier = {
          "shipmentNumber": s.shipmentNumber,
          "multipleCarriers" : false, 
          "visible": false
        }
        
        this.setCarrierTracking(shipmentCarrier, s.tracking)
        this.setMultipleCarriers(shipmentCarrier)

        return shipmentCarrier
      })
    }
    
    return this.shipmentCarrierGroups
  }
  
  setMultipleCarriers (shipmentCarrier) {
    shipmentCarrier.multipleCarriers = false
    
    if (shipmentCarrier && shipmentCarrier.tracking) {
      shipmentCarrier.multipleCarriers = (shipmentCarrier.tracking.filter(c => c.trackingLink).length > 1)
    }
  } 

  setCarrierTracking (shipmentCarrier, trackingNumber) {

      if (!trackingNumber) {
        shipmentCarrier.tracking = []
        return
      }

      //set the %OA to newline
      trackingNumber = trackingNumber.replace('%0A','\\n')

      let trackingNumbersArray = trackingNumber.split('\\n')

      let fedExTracking = this.setUpCarrierTracking(CarrierTracking.FEDEX_CARRIER)
      let upsTracking = this.setUpCarrierTracking(CarrierTracking.UPS_CARRIER)
      let upsTrackingCount = 0
      let ontracTracking = this.setUpCarrierTracking(CarrierTracking.ONTRAC_CARRIER)
      let invalidTracking = this.setUpCarrierTracking(CarrierTracking.INVALID_CARRIER)
      

      for (let trackingNumber of trackingNumbersArray) {
        
        if (trackingNumber.match(CarrierTracking.FEDEX_REGEX)) {
          fedExTracking.trackingLink = this.setTracking('https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=', fedExTracking.trackingLink, trackingNumber, ',')
        }
        else if (trackingNumber.match(CarrierTracking.ONTRAC_REGEX)) {
          ontracTracking.trackingLink = this.setTracking('https://www.ontrac.com/tracking.asp?trackingres=submit&tracking_number=', ontracTracking.trackingLink, trackingNumber, '%0D%0A')            
        }
        else if (trackingNumber.match(CarrierTracking.UPS_REGEX)) {
          upsTrackingCount++
          upsTracking.trackingLink = this.setTracking('https://wwwapps.ups.com/WebTracking/processRequest?', upsTracking.trackingLink, 'InquiryNumber' + upsTrackingCount + '=' + trackingNumber, '&')            
        }          
        else {            
          invalidTracking.trackingLink = this.setTracking('', invalidTracking.trackingLink, trackingNumber, '<br />')
        }        
      }


      let carrierTracking = []

      this.addTracking(carrierTracking, fedExTracking)
      this.addTracking(carrierTracking, upsTracking)
      this.addTracking(carrierTracking, ontracTracking)
      this.addTracking(carrierTracking, invalidTracking)


      shipmentCarrier.tracking = carrierTracking

    }

    addTracking (carrierTrackingList, carrierTracking) {
      if (carrierTracking.trackingLink) {
        carrierTrackingList.push(carrierTracking)
      }
    }

    setTracking (baseLink, currentTrackingLink, trackingNumber, separator) {
      let trackingList = ""

      if (!currentTrackingLink) {
        trackingList = baseLink + trackingNumber
      } else {
        trackingList = currentTrackingLink + separator + trackingNumber
      }
      return trackingList
    }

    setUpCarrierTracking (carrier) {
      return {"carrier": carrier, "trackingLink": ""}
    }
      
    hasMultipleCarriers (shipmentNumber) {

      let result = false

      let shipmentCarrierTracking = this.getShipmentCarrierGroupInfo(shipmentNumber)

      result = (shipmentCarrierTracking && shipmentCarrierTracking.multipleCarriers)      

      return result
    }

    getShipmentCarrierGroupInfo (shipmentNumber) {
      let shipmentCarrierInfo = null

      if (this.shipmentCarrierGroups && this.shipmentCarrierGroups.length > 0) {
        
        shipmentCarrierInfo = this.shipmentCarrierGroups.find(c => c.shipmentNumber == shipmentNumber)
      }      

      return shipmentCarrierInfo
    }

    getShipmentCarrierTrackingSingle (shipmentNumber) {
      let carrierGroupInfo = this.getShipmentCarrierGroupInfo(shipmentNumber)
      console.info(carrierGroupInfo)
      let trackingLink = null      

      if (carrierGroupInfo) {
        trackingLink = carrierGroupInfo.tracking.find(c => c.trackingLink).trackingLink
      }

      return trackingLink
    }    

    getShipmentCarrierTracking (shipmentNumber) {
      let carrierGroupInfo = this.getShipmentCarrierGroupInfo(shipmentNumber)

      return carrierGroupInfo ? carrierGroupInfo.tracking : []
    }
    
    getInvalidCarrier (shipmentNumber) {
      let carrierGroupInfo = this.getShipmentCarrierGroupInfo(shipmentNumber)

      let invalidCarrier = null
      if (carrierGroupInfo) {
        invalidCarrier = carrierGroupInfo.tracking.find(t => t.carrier == CarrierTracking.INVALID_CARRIER)
      }

      return invalidCarrier
    }     
}