<template lang="pug">
.order-view(v-if="!hasSearchError")
  .waitMessageContainer(v-if="isLoading === true")
    .waitText Retrieving order details...
    ui-spinner(:size="medium" active)
  .order-container(v-else="")
    order-card.order(:Order="Order")
</template>

<script>
  import OrderCard from '@/components/OrderCard'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { OrderHasData } from '../utility/Util.js'
  import { inject } from 'vue'

  export default {
    name: 'order',
    components: {
      OrderCard
    },
    setup () {
      const { interceptor } = inject('httpInterceptor')

      return {
        interceptor
      }
    },
    props: {
      id: String,
      shipid: String,
      index: String
    },
    data () {
      return {
        Order: null,
        isLoading: true,
        orderId: this.id
      }
    },
    async created () {
      this.customerLoaded = false
      await this.getData()
    },
    async beforeRouteUpdate (to, from, next) {
      if (to.params.id) {
        if (to.params.id !== this.orderId) {
          this.orderId = to.params.id
          await this.getData()
        }
        this.shipidProp = to.params.shipid
        next()
      } else {
        //cancel the navigation since the id is not present
        next(false);
      }
    },
    computed: {
      ...mapGetters([        
        'hasSearchError'
      ])   
    },
    watch: {
      'auth.loggedIn': function (newValue, oldValue) {        
        if (newValue) {
          if (this.Order?.contactInfo?.customerNumber && !this.customerLoaded) {            
            this.loadCustomerData()
          }
        }
      }

    },
    methods: {
      ...mapActions([
        'loadCustomer'        
      ]),
      ...mapMutations([
        'setSearchCriteria',
        'setSearchError',
        'setCustomer'
      ]),
      goHome () {
        this.$router.push("/")
      },
      async getData () {        
        let errorMessage = null
        this.isLoading = true
        this.customerLoaded = false        
        this.setSearchError(null)

        if (Number.isSafeInteger(+this.orderId)) {
          await this.interceptor.get(`/Order/calculated/${this.orderId}`)
            .then(response => {
              this.Order = response.data
            })
            .catch(error => {              
              errorMessage = 'Invalid order number'
            })
        } else {
          errorMessage = 'Invalid order number'
          this.Order = null
        }

        this.setSearchCriteria(this.orderId)

        if (OrderHasData(this.Order)) {
          if (this.Order?.contactInfo?.customerNumber && this.auth.loggedIn) {                
            await this.loadCustomerData()
          } else {
            this.setCustomer({})
          }
        } else {
          errorMessage = 'Order not found'
        }

        this.isLoading = false

        if (errorMessage || this.hasSearchError) {
          if (errorMessage) {
            this.setSearchError(errorMessage)
          }
          this.goHome()  
        }  
      },
      async loadCustomerData () {
            await this.loadCustomer(this.Order.contactInfo.customerNumber, true)
            this.customerLoaded = true        
      }
    }
  }
</script>

<style lang="scss" scoped>
  .waitMessageContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100%;
  }

  .waitText {
    text-align: center;
    padding: 6px 0 0 0;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  .md-progress-spinner {
    align-self: center;
  }

  .order-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .order {
      max-width: 800px;
      width: 100%;
      margin: 10px;
      flex-grow: 1;
    }
  }
</style>
