<template lang="pug">
.products(v-if="filtersLoaded")
  .container  
    product-slider(v-bind:modelValue="itemNumber" :show="showProductSlider" @closeSlider="closeProductSlider")
    filter-cache-list(
      :goToBasePath=null
      :quickSearchApi="`/item/quickSearch?quickSearchValue=`"
      :getDataApi="`/item`"
      :searchPlaceholder="'Search by Product number / description'"
      :activePath="activePath"
      :searchQueryString="'searchText'"
      :blankSearchAllowed="false"
      :noRecordsMessage="'No Products Found'"
      :enableQrCodeButton="false"
      :enableViewOptions="true"
      ref="filteredList"
      @updateCache="updateCache"
      :disabledValue="disabledValue"
      :excludedFilters="['customernumber', excludePricingFilterName]"
      :cachedFilters="productFilters()"
      :disableFilterCacheLoadOnCreated="disableFilterCacheLoadOnCreated",
      :disableQuickSearchResults="true",
      :compactView="compactView"
      @dataUpdate="onDataUpdate"
    )
      template(v-slot:viewOptionsSlot="")
        ui-icon(@click="swapView") {{ currentlySelectedView.icon }}
      template(v-slot:filters="")
        bd-chips(v-bind:modelValue="chips" @delete="removeFilter" @toggle="toggleFilter" :formatter="formatFilter" enableChipToggle)
      template(v-slot:filterList="")
        filters(v-model="filters" @filterUpdate="applyFilter")
      template(v-slot:default="{data:product}")
        compact-product-card.product.compact(v-if="compactView" :product="product" :key="product.id" :enableCart="enableCart" :enablePricing="enablePricing" :waitForPricingToLoad="true" :enableClickToOpenProduct="true" :customerId="customerId")
        product-card.product(v-else="" :product="product" :key="product.id" :enableCart="enableCart" :enablePricing="enablePricing" :waitForPricingToLoad="true")
      template(v-slot:defaultData="")      
        .tiles
          tile(v-for="tile in tiles" :key="tile.id" @click="onTileClick" :title="tile.name" :img="tile.url" :id="tile.id" :clickedValue="tile.clickedValue" )
</template>

<script>
  import ProductCard from '@/components/ProductCard'  
  import CompactProductCard from '@/components/CompactProductCard.vue'
  import FilterCacheList from '@/components/FilterCacheList'
  import Tile from '@/components/Tile'
  import {createFilter, 
          createDependentValue,          
          DISABLED_FILTER_VALUE } from '../utility/Util.js'
  import Filters from '@/components/Filters'
  import { provide, inject, ref } from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import { debounce } from 'throttle-debounce'  
  import { publicProductViewInternalRouteName } from '@/router/index.js'
  import ProductSlider from '@/components/ProductSlider'
  export default {
    name:'Products',
    components: {
      ProductCard,
      Filters,
      FilterCacheList,
      Tile,
      ProductSlider,
      CompactProductCard
    },
    props: {
      customerId: String,
      customerDCs: {
        default: null,
        type: Array
      },
      enableCart: {
        default: false, 
        type: Boolean
      },
      enablePricing: {
        default: false,
        type: Boolean
      },
      disableFilterCacheLoadOnCreated: {
        default: false,
        type: Boolean
      }, 
      goHomeIfLoggedIn: {
        default: true,
        type: Boolean
      }, 
      activePath: {
        default: '',
        type: String
      }
    },
    setup () {
      const { interceptor } = inject("httpInterceptor")
      const filteredList = ref(null)
      const disabledValue = DISABLED_FILTER_VALUE
      const filters = ref([])
      const previouslyOrderedFilterName = 'previouslyOrdered'
      const previouslyOrderedLabel = 'Previously Ordered'
      const hasCouponFilterName = 'hasCoupon'
      const hasCouponLabel = 'Has Coupon'
      const CategoriesSectionName = 'Categories'
      const auth = inject('auth')
      const authUtility = inject('authUtility')
      const viewOptions = [
        {
          name: 'card_view',
          icon: 'view_agenda'
        },
        {
          name: 'compact_view',
          icon: 'reorder'
        },
      ]
      const excludePricingFilterName = 'excludePricing'

      provide('filters', filters)
      return {        
        interceptor,
        filteredList,
        disabledValue, 
        filters, 
        previouslyOrderedFilterName,
        previouslyOrderedLabel,
        hasCouponFilterName,
        hasCouponLabel,
        CategoriesSectionName, 
        auth,
        authUtility,
        viewOptions,
        excludePricingFilterName
      }
    },
    //continues navigation only if the product slider is not shown
    beforeRouteUpdate(to, from) {
      if (to.name == publicProductViewInternalRouteName) {
        this.showProductSlider = true
        this.itemNumber = to.params['itemNumber']
      } else {
        this.showProductSlider = false
      }
      return !this.showProductSlider
    },
    async created () {      
      this.signInTileId = 'signIn'
      this.filters = await this.createFilters()      
      this.filtersLoaded = true      
    },
    data () {
      return {   
        currentlySelectedView: {},     
        filtersLoaded: false,
        showProductSlider: false,
        itemNumber: null,
        compactView: false,
      }
    },
    mounted () {
      this.setCompactView(this.compactListView())
    },
    watch: {
      async customerId (newId, oldId) {

        if (this.filtersLoaded) {
          this.setStockWarehouseFilterAndLabel(this.filters)
          const customerNumberFilter = this.filters.find(f=> f.name == 'customerNumber')
          customerNumberFilter.value = newId
          this.filteredList.setCachedDataLoaded(false)
        }        

      },
      'auth.loggedIn': async function (value) {
        if (value && this.goHomeIfLoggedIn) {          
          await this.$router.push({name: 'Home'})
        }
      }
    },
    computed: {
      ...mapGetters(['isCustomerUser', 'productFilters', 'compactListView']),
      chips () {
        return this.filters.filter(f => (f.value || f.disabled) && f.visible)
      }, 
      tiles () {
        let tiles = []
        const imageSource = (imagePath) => {return require('@/assets' + imagePath)};
        
        const categoryTiles = [...this.filters.filter(f => f.section==this.CategoriesSectionName && f.url)
            .map(c => {
              return this.createTile(c.label, c.name, imageSource(c.url), c.name)
            })]
        
        const standardFilterTiles = []

        if (this.customerId) {
          standardFilterTiles.push(this.createTile(this.previouslyOrderedLabel, this.previouslyOrderedFilterName, imageSource('/tile-images/previously-ordered.jpg'), true))
        }

        standardFilterTiles.push(this.createTile(this.hasCouponLabel, this.hasCouponFilterName, imageSource('/tile-images/has-coupon.jpg'), true))  

        tiles = [...standardFilterTiles, ...categoryTiles]

        if (!this.auth.loggedIn) {
          const signInTile = this.createTile('Sign in', this.signInTileId, imageSource('/tile-images/login.jpg'), true)
          tiles.unshift(signInTile)
        }

        return tiles
      }
    },
    methods: {
      ...mapActions(['updateProductFilters', 'updateCompactListView']),
      createTile(name, id, url, clickedValue) {
        return {name, id, url, clickedValue}
      },
      async createFilters () {        
        let filters = []
        this.addStandardFilters(filters)
        await this.addProductCategoryFilters(filters)
        await this.addManufacturerFilters(filters)
        return filters
      },
      setCompactView (compactView) {
        if (compactView) {
          this.currentlySelectedView = this.viewOptions[1]
          this.compactView = true
        } else {
          this.currentlySelectedView = this.viewOptions[0]
          this.compactView = false
        }
      },
      swapView () {
        if (this.currentlySelectedView.name === this.viewOptions[0].name) {
          this.setCompactView(true)
          this.updateCompactListView(true)
        } else {
          this.setCompactView(false)
          this.updateCompactListView(false)
        }
      },
      addStandardFilters(filters) {

        if (this.customerId) {
          filters.push(createFilter(this.previouslyOrderedFilterName, this.previouslyOrderedLabel, 'previouslyOrderedOnly', 'checkbox'))
          this.setStockWarehouseFilterAndLabel(filters)
        }
        filters.push(createFilter(this.hasCouponFilterName, this.hasCouponLabel, 'hasCoupon', 'checkbox'))
        filters.push(createFilter('customerNumber', null, 'customerNumber', null, false, this.customerId, false, true))
        filters.push(createFilter(this.excludePricingFilterName, null, this.excludePricingFilterName, null, false, true, false, false))
      },

      setStockWarehouseFilterAndLabel (filters) {
        let DCs = []
        if (this.customerDCs) {
          DCs = this.customerDCs.reduce((accumulatedValues, currentValue) => {
              if (currentValue) {
                accumulatedValues.push(createDependentValue('stockWarehouses', currentValue))
              }
              return accumulatedValues
          }, [])
        }

        const inStockLabel = this.isCustomerUser ? 'Available' : 'In Stock'

        const inStockFilterName = 'inStockOnly'

        const existingFilter = filters.find(f => f.name == inStockFilterName)

        if (existingFilter) {
          existingFilter.dependentQueryStringValues.length = 0
          existingFilter.dependentQueryStringValues = DCs
        } else {
          filters.push(createFilter(inStockFilterName, inStockLabel, "inStockOnly", "checkbox", true, null, true, true, DCs))
        }
      },
      
    
      async addProductCategoryFilters (filters) {
        
        let categories = []
        try {
          categories = (await this.interceptor.get(
            `itemcategory/descendants/${process.env.VUE_APP_ITEM_CATEGORY_ROOT}?maxdepth=${process.env.VUE_APP_ITEM_CATEGORY_MAX_DEPTH}`
          )).data

        } catch (error) {
          console.error('Error fetching categories ->' + error)
        }

        categories?.forEach((category, index) => {
          filters.push(createFilter(category.key, category.description,
            'categories', 'array', index != 0, null, true, true, null, category.depth - 1,
            this.CategoriesSectionName, 'checkbox', category.url))
        })        

        return categories
      },

      async addManufacturerFilters (filters) {
        
        let manufacturers = []
        
        try {
          manufacturers = (await this.interceptor.get('/vendor/filterInfo?pageIndex=0&pageSize=100000&getAll=true')).data
        } catch (error) {
          console.error('Error fetching vendors ->' + error)
        }
        
        manufacturers?.forEach((vendor, index) => {
          filters.push(createFilter(vendor.vendorId, vendor.name, 'vendorids', 'array', true, null, true, true, null, 1, 'Manufacturers', 'query-list'))
        })

        return manufacturers
      },

      applyFilter (filter) {
        this.updateFilter(filter, filter.value, false, null)
      },

      removeFilter (filter) {
        this.updateFilter(filter, null, false, null)
        
        if (this.chips?.length == 0) {
          this.updateCache(null)
        }
      },
      
      toggleFilter (filter) {
        // toggle disabled        
        const isDisabled = !filter.disabled
        // store current filter value so that it can be restored if it has
        // been disabled
        const disabledValue  = isDisabled ? filter.value : null        
        // restore the filter value if the filter is not disabled anymore
        let restoredValue = (filter.disabledValue ?? filter.value)

        restoredValue = restoredValue?.toString().replace(this.disabledValue, '')
        
        const filterValue = !isDisabled ? restoredValue
          : `${this.disabledValue}${filter.value}`
        
        // set the filter values
        this.updateFilter(filter, filterValue, isDisabled, disabledValue)
      },

      updateFilter (filter, filterValue, isDisabled, disabledValue) {
        const storedFilter = this.filters.find(f => f.name == filter.name)

        if (storedFilter) {
          storedFilter.disabled = isDisabled
          storedFilter.disabledValue = disabledValue
          storedFilter.value = filterValue
          this.filteredList.updateFilteredList()
        } else {
          console.error(`filter name ${filter?.name} not found`)
        }
      },

      formatFilter (filter) {
        return filter?.label
      },
      updateCache (cacheableFilters) {
        this.updateProductFilters(cacheableFilters)          
      },
      onTileClick: debounce(300, function ({id, clickedValue}) { this.tileClicked(id, clickedValue) }),

      tileClicked (id, clickedValue) {
        if(id == this.signInTileId) {
          this.authUtility.signIn()
        } else {
          const filter = this.filters.find(f => f.name == id)
          if (filter) {
            filter.value = clickedValue          
            this.applyFilter(filter)

          } else {
            console.error(`Tile filter name ${id} not found`)
          }
        }
      },

      closeProductSlider () {
        this.showProductSlider = false
      },

      async onDataUpdate (updatedData) {
        const data = updatedData?.data
        if (data?.length > 0 && this.enablePricing) {
          try {
            const requestData = data.map(d => {
              return {
                ProductNumber: d.itemNumber,
                Quantity: 1
              }
            })

            const request = {
              CustomerNumber: `${this.customerId}`,
              ProductList: {
                Item: [...requestData]
              }
            }
            
            const {data:pricing} = await this.interceptor.post(`pricing/request`, 
              request
            )
            const pricingData = pricing?.productList?.item
            if (pricingData?.length > 0) {
              data.forEach(d => {
                const pricingItem = pricingData.find(p => p.productNumber == d.itemNumber)
                if (pricingItem) {
                  d.price = pricingItem.price
                  d.pricingLoaded = true
                  d.suppressCoupon = pricingItem.suppressCouponFlag == 1
                }
              })
            }
          } catch (error) {
            console.error(error)
          }
        }
      }

    }

  }
</script>

<style scoped lang="scss">
  :deep(.bd-filtered-list-head-content) {
    @media screen and (min-width: 800px) {
      margin-left: -12px;
    }
  }

  .products {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      position: relative;
      width: 100%;
    }

    :deep(.viewOptions) {
      padding-right: 0.4rem;
    }

    .data-list {
      flex: 1 1 auto;
      overflow: auto;

      .product {
        max-width: 800px;
        width: 100%;
        margin: 10px;
        flex: 1 0;
        margin: auto;
        padding: 18px 2px;
      }      
    }

    .tiles {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;      
      justify-content: center;      
    }     

  }
</style>