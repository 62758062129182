import { getImageUrlFromProduct } from '../utility/Util.js'

export default class CartItem {

  cartItem = {}
  constructor(searchItem, quantity, type, rewardValue, imageUrl = null, 
      itemNumberOverride = null, descriptionOverride = null, isRedemptionBurkhartProduct=false, 
      couponOverride = null, overridePrice = null){
        
    const coupon = !searchItem?.suppressCoupon ? 
                    couponOverride ?? searchItem?.coupon : null
    this.cartItem = {
      "itemKey": searchItem?.itemKey,
      "itemNumber": itemNumberOverride ?? searchItem?.itemNumber,
      "description": descriptionOverride ?? searchItem?.description,
      "price": searchItem?.price,      
      "overridePrice": overridePrice,
      "quantity": quantity,
      "type": type,
      "coupon": type ==  0 ? coupon : {id: coupon?.id},
      "distributionCenterOverride": null, 
      "couponKey": coupon?.key,
      "legacyCouponOfferId": coupon?.data.LegacyCouponOfferID,
      "rewardValue": rewardValue,
      "imageUrl": imageUrl ?? getImageUrlFromProduct(searchItem),
      "isRedemptionBurkhartProduct": isRedemptionBurkhartProduct
    }
  }

  getCartItem () {
    return this.cartItem
  }
     
}

export class CartItemUpdate {
  cartItemUpdate = {}

  constructor(updatedItemNumber, isRedemptionItem, couponId, isItemDeleted) {
    this.cartItemUpdate = {
      updatedItemNumber, isRedemptionItem, couponId, isItemDeleted
    }
  }

  get () {
    return this.cartItemUpdate
  }
}