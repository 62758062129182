import { authUtility } from '@burkhart/vue-components'

const state = () => ({
  orderFilters: null, 
  productFilters: null,
  searchBy: null,
  compactListView: null
})

function _saveData(context, mutation, value) {
  context.dispatch('loadUserState')
  context.commit(mutation, value)
  context.commit('saveState')
}

function _getDataElement (dataElement, storedData = null) {
  let result = null
  storedData ??= _getStoredData()
  if (storedData) {
    result = storedData[dataElement]
  }
  return result
}

function _getStoredData () {
  const localStorageData = localStorage.getItem(authUtility.email)
  let storedData = null
  if (localStorageData) {
    try {
      storedData = JSON.parse(localStorageData)            
    } catch (e) {
      console.error(`Error fetching data for ${authUtility.email} from local storage. Error: ${e}`)
    }
  }
  return storedData
}

const getters = {  
  orderFilters: (state) => (storedData) => {
    return _getDataElement('orderFilters', storedData)
  },
  productFilters: (state) => (storedData) => {
    return _getDataElement('productFilters', storedData)
  },
  compactListView: (state) => (storedData) => {
    return _getDataElement('compactListView', storedData)
  },
  searchBy: (state) => (storedData) => {
    return _getDataElement('searchBy', storedData)
  }
}

const mutations = {   
  setOrderFilters (state, value) {
    state.orderFilters = value    
  },
  setProductFilters (state, value) {
    state.productFilters = value        
  },
  setCompactListView (state, value) {
    state.compactListView = value
  },
  setSearchBy (state, value) {
    state.searchBy = value
  },
  saveState (state) {
    localStorage.setItem(authUtility.email, JSON.stringify(state))
  }
}

const actions = {
  updateOrderFilters (context, value) {    
    _saveData(context, 'setOrderFilters', value)
  }, 
  updateProductFilters (context, value) {
    _saveData(context, 'setProductFilters', value)
  },
  updateCompactListView (context, value) {
    _saveData(context, 'setCompactListView', value)
  },
  updateSearchBy (context, value) {
    _saveData(context, 'setSearchBy', value)
  },
  loadUserState (context) { 
    const storedData = _getStoredData()    
    const orderFilter = context.getters.orderFilters(storedData)
    const productFilter = context.getters.productFilters(storedData)   
    const compactListView = context.getters.compactListView(storedData)
    const searchBy = context.getters.searchBy(storedData) 
    context.commit('setOrderFilters', orderFilter)
    context.commit('setProductFilters', productFilter)
    context.commit('setCompactListView', compactListView)
    context.commit('setSearchBy', searchBy)
  }
}


export default { state, getters, mutations, actions }