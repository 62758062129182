<template lang="pug">
.qr-code-reader(v-if="scan")
  .switch-camera-container
    bd-button.full.button(@click="switchCamera" v-if="cameraCount > 1") Switch Camera
  video(id="qrCodeVideo")
</template>

<script>
  import { BrowserQRCodeReader } from '@zxing/library'  

  export default {
    name:'QrCodeReader',    
    props: {
      scan: Boolean
    },
    data () {
      return {        
        cameraList: [],
        selectedCameraIndex: 0,
        cameraCount: 0,
        deviceId: undefined,
        codeReader: new BrowserQRCodeReader()        
      }
    },
    created (){
      
      //get a  list of cameras
      this.codeReader
        .listVideoInputDevices()
          .then(devices => {
            if (devices.length > 0) {              
              this.cameraList = devices.map(d => d.deviceId)
              //default to the last camera which should be the back camera on mobile devices
              this.selectedCameraIndex = devices.length - 1
              this.deviceId = this.cameraList[this.selectedCameraIndex]
              this.cameraCount = devices.length
            }            
          })
          .catch(err => console.error(err))
    },
    watch: {
      scan: function(value){
        if(value == true) { 
          this.commenceScan()
        }
        else { 
          this.codeReader.stopContinuousDecode()
          this.codeReader.reset() //stops access to the camera
        }        
      }
    },
    methods: {
      commenceScan () {
        this.codeReader        
          .decodeFromVideoDevice(this.deviceId, 'qrCodeVideo', (result, err) =>{
            if(result){              
              this.$emit("decodeEvent", String(result))
            }
          })
      },
      switchCamera () {
        
        this.codeReader.stopContinuousDecode()
        this.selectedCameraIndex = (this.selectedCameraIndex + 1) % this.cameraCount        
        this.deviceId = this.cameraList[this.selectedCameraIndex]
        this.commenceScan()
      }
    },
  }
</script>

<style scoped lang="scss">
  .qr-code-reader {
    
    display: flex;
    align-content: center;
    align-self: center;
    flex-direction: column;    
    justify-content: center;    
    position: relative;
       
    .switch-camera-container {              
      display: flex;
      align-content: center;
      align-self: center;
      flex-direction: column;    
      justify-content: center;
      

      .md-button {
        min-width: 0.1%;      
        margin: 1px;      
        width: 150px;
      }
   
    }
  }
</style>