<template lang="pug">
.collapsible(ref="collapsible")
  slot
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'CollapsibleDiv',
  setup() {
    const collapsible = ref(null)

    return {
      collapsible
    }
  },
  data() {
    return {
      collapsed: false
    }
  },
  methods: {
    toggleCollapse(event) {
      if (this.collapsible.clientHeight) {
        this.collapse()
      } else {
        this.expand()
      }
    },
    expand() {
      this.collapsible.style.height = this.collapsible.scrollHeight + 'px'
      this.collapsed = false
    },
    collapse() {
      this.collapsible.style.height = 0
      this.collapsed = true
    }
  }
}
</script>

<style scoped lang="scss">
.collapsible {
  transition: height .4s;
  overflow: hidden;
  height: 0;
}
</style>
