<template lang="pug">
.filters
  .filter-sections
    .section(v-for="(section, index) in filterSections" :key="section" :class="{selected: section == selectedSection}")
      bd-button(@click="sectionClicked(section)" darkmode) {{ section }}
  .filter-content(v-for="(sectionData, index) in filterSectionData" :key="sectionData.key")
    .content(v-show="sectionData.key == selectedSection")
      .query-list(v-show="sectionData.display == 'query-list'")
        bd-auto-complete(
          :bd-options="sectionData.data" 
          :itemModelKey="'name'"
          :filterKeys="['label']" 
          @optionSelect="filterUpdate" 
          :autoCompleteResultCssPosition="'static'"
          :addResultListBorder="false"
          v-model="autoCompleteInput"
          :alwaysShowResults="true"
          :clearOnSelect="false"
          :placeholder="'Search by Manufacturer'"
          showClear)
          template(v-slot:bdAutocompleteItem="{item}")
            .query-list-filter
              .filter
                filterItem(:filter="item" disableCheckEventPropagation)
      .filter-list(v-show="sectionData.display != 'query-list'")
        .filter(v-for="(filter, index) in sectionData.data" :key="filter.name")    
          filterItem(:filter="filter" @filterUpdate="filterUpdate")
</template>

<script setup>
import FilterItem from "@/components/FilterItem";
import { computed, onMounted, ref } from 'vue'
import { createLogger } from "vuex";
import { debounce } from 'throttle-debounce'

let selectedSection = ref('')
let autoCompleteInput = ""

const props = defineProps({
  modelValue: {
      type: Array,
      default: []
    },  
})

const emit = defineEmits([  
  'filterUpdate'
])

onMounted(() => {
  selectedSection.value = filterSections?.value?.length > 0 ? filterSections.value[0] : null
})

const filters = computed({
  get () {
    return props.modelValue?.filter(f => f.visible) ?? []
  } 
})

const filterSections = computed({
  get () {
    return [...new Set(filters.value.map(f => f.section))]
  } 
})

const filterUpdate = debounce(300, (filter) => {
  if (filter.type) {
    const checkedValue = filter.type == 'array' ? filter.name : 'true'
    filter.value = filter.value ? null : checkedValue
    emit('filterUpdate', filter)
  }
}, {atBegin: true})

const sectionClicked = (sectionName) => {
  selectedSection.value = sectionName
}

const filterSectionData = computed({
  get () {
    let filterSectionData = []
    filterSections.value.forEach(section => {
      let data = filters.value.filter(f => f.section == section)

      if (data?.length > 0) {
        filterSectionData.push({
          key: section, 
          data: data, 
          display: data[0].display})
      }
    })

    return filterSectionData
  }
})
</script>

<style lang="scss" scoped>

.filters{
  padding: 10px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 100%;
  min-width: 400px;
  font-size: 14px; 
  max-height: 400px;

  .filter-sections {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    margin-bottom: 8px;    

    .section {
      margin: 5px 0px;
      &.selected {
        border-bottom: 1px solid $spring;       
      }
    }
  }

  .filter-content {
    display: flex;
    flex-direction: column;          
    align-self: flex-start;
    overflow: auto;
    width: 100%;
    padding-left: 8px;
    .filter {
      padding: 4px 0px;
      & * :hover, :hover {
        cursor: pointer;
        background-color: $teal;                    
      }
    }
    .content {
      .query-list {
        .query-list-filter {
          display: flex;
          flex-direction: row;
          input {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
