<template lang="pug">
.couponItem(:class="{ redemptionItem: isRedemptionItem, hascoupon: coupon, lastItem: isLastItem }")
  .info-block
    .img-info
      image-display(:modelValue="item.imageUrl" :width="75" :height="75" isThumbnail)                  
      .info
        .burkhartNumber(v-if="!isRedemptionItem || (isRedemptionItem && item.isRedemptionBurkhartProduct)" @click="goToProduct(router, customerId, item.itemNumber)") {{ '#' + item.itemNumber }}
        .title {{ item.description }}
    .actions(v-if="!priceOverrideActive")
      .tag-request.button-padding
        svg(v-if="item.type == 0" width="22" height="21" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg" @click="tagRequestClicked(item.itemNumber)")
          path(v-if="isTagRequested" d="M20.2 31.5L9 20.3L11.15 18.15L20.2 27.2L39.4 8L41.55 10.15L20.2 31.5Z" fill="#36667D")
          path(:class="{ 'tag-icon': !isTagRequested }" d="M23.95 39.15C23.35 39.75 22.625 40.05 21.775 40.05C20.925 40.05 20.2 39.75 19.6 39.15L0.85 20.4C0.516667 20.0667 0.291667 19.7167 0.175 19.35C0.0583333 18.9833 0 18.6 0 18.2V3C0 2.13333 0.283333 1.41667 0.85 0.85C1.41667 0.283333 2.13333 0 3 0H18.2C18.6 0 19 0.0583333 19.4 0.175C19.8 0.291667 20.1667 0.516667 20.5 0.85L39.15 19.5C39.7833 20.1333 40.1 20.875 40.1 21.725C40.1 22.575 39.7833 23.3167 39.15 23.95L23.95 39.15ZM21.9 37.1L37.1 21.9L18.2 3H3V18.2L21.9 37.1ZM8.25 10.8C8.95 10.8 9.55833 10.5417 10.075 10.025C10.5917 9.50833 10.85 8.9 10.85 8.2C10.85 7.5 10.5917 6.89167 10.075 6.375C9.55833 5.85833 8.95 5.6 8.25 5.6C7.55 5.6 6.94167 5.85833 6.425 6.375C5.90833 6.89167 5.65 7.5 5.65 8.2C5.65 8.9 5.90833 9.50833 6.425 10.025C6.94167 10.5417 7.55 10.8 8.25 10.8Z" fill="#173442")
          
      .quantity-amount
        .quantity-label
          quantity-input(v-model="item.quantity" @validInput="updateQuantity" :max="maximumValue")
        .amount 
          ui-icon.icon(v-if="arrowIconVisible" :class="priceIncrease ? 'price-increase': 'price-decrease'") {{ arrowIcon }}
          .currency {{ currencyFormatter(amount || 0) }}
      bd-button.button-padding(icon="remove_shopping_cart" @click="removeItem()")
    .actions(v-if="!isCustomerUser && priceOverrideActive")
      price-override-input(v-model="item.priceOverride" @validPriceOverride="overridePrice" :inputPrice="itemAmount" :disabled='isRedemptionItem' :isRedemptionItem='isRedemptionItem' :overridePrice="actualOverridePrice" :originalPrice="item.price")
</template>

<script>
import QuantityInput from '@/components/QuantityInput'
import PriceOverrideInput from '@/components/PriceOverrideInput'
import { mapActions, mapGetters } from 'vuex'
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { debounce } from 'throttle-debounce'
import ImageDisplay from '@/components/ImageDisplay'

export default {
  name: 'CouponItem',
  components: {
    QuantityInput, 
    PriceOverrideInput,
    ImageDisplay
  },
  props: {
    item: Object,
    isLastItem: {
      type: Boolean,
      default: false
    },
    isRedemptionItem: {
      type: Boolean,
      default: false
    },
    redemptionRemaining: {
      type: Number,
      default: null
    },
    priceOverrideActive: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const currencyFormatter = inject('currencyFormatter')
    const goToProduct = inject('goToProduct')
    const router = useRouter()
    return {
      currencyFormatter,
      goToProduct,
      router
    }
  },
  methods: {
    ...mapActions(['removeItemFromCart', 'changeQty', 'overridePriceStore', 'updateRedemptionQuantity', 
        'toggleTagRequest', 'removeTagRequest']),
    overridePrice(value) {

      let updateData = {
        itemNumber: this.item.itemNumber, 
        overridePrice: value
      }
      this.overridePriceStore(updateData)
    },     
    updateQuantity: debounce(300, function (value) {
      let updateData = {
        itemNumber: this.item.itemNumber, 
        quantity: value, 
        couponId: this.item.coupon?.id}
      if (this.isRedemptionItem) {
        this.updateRedemptionQuantity(updateData)
      } else {
        this.changeQty(updateData)
      }
    }), 
    removeItem () {      
      this.removeItemFromCart({
        itemNumber: this.item.itemNumber, 
        couponId: this.item.coupon?.id,
        isRedemptionItem: this.isRedemptionItem
        })
      if (!this.isRedemptionItem) {
        this.removeTagRequest(this.item.itemNumber)
      }
    },
    tagRequestClicked: debounce(100, 
      function (itemNumber) { this.toggleTagRequest(itemNumber)})
  },
  computed: {
    ...mapGetters(['customerId', 'hasTagRequest', 'isCustomerUser', 'actualItemPrice']),
    maximumValue () {
      let value = this.isRedemptionItem ? this.item.quantity + this.redemptionRemaining : null
      return value
    },
    isTagRequested () {
      return this.hasTagRequest(this.item.itemNumber)
    },
    itemQty () {
      return this.item.quantity
    },
    priceIncrease () { return this.item.overridePrice >= this.item.price },
    arrowIcon () { return this.priceIncrease ? 'arrow_upward' : 'arrow_downward' },
    coupon () {
      return this.item.coupon
    },
    amount () {
      return this.item.quantity * this.itemAmount
    },
    itemAmount () {
      return this.actualItemPrice(this.item)
    },
    arrowIconVisible () {            
      return this.itemAmount != this.item.price && !this.isCustomerUser && !this.isRedemptionItem
    }, 
    actualOverridePrice () {
      return this.item.hasPriceOverride ? this.item.overridePrice : null
    }
  }
}
</script>

<style scoped lang="scss">
.amount {
  display: flex;
  align-items: center;
}
.price-decrease {
  font-size: 12px;
  color: $grapefruit;
}
.price-increase {
  font-size: 12px;
  color: $spring;
}
.couponItem {
  display: flex;
  flex-flow: row;
  background-color: $white;
  flex: 1 1 auto;

  &.hascoupon {
    &.redemptionItem {
      margin: 0 0 0 12px;
      border-top: 1px dashed $fog;
      border-radius: 0;
    }

    &.lastItem {
      border-radius: 0 0 0 4px;
    }
  }

  .info-block {
    padding: 8px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex: 1 1 auto;

    .info {
      padding-left: 8px;

      .burkhartNumber {
        cursor: pointer;
      }
    }

    .img-info {
      display: flex;
      flex-flow: row;
      width: 100%;
    }

    .actions {
      display: flex;
      align-items: center;
      flex-flow: row;
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: flex-end;
      width: 100%;

      .tag-request {
        width: 38px;
        padding: 8px;
        cursor: pointer;
      }

      .quantity-amount {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 8px;

        .quantity-label {
          display: flex;
          flex-flow: row;
          flex: 1 1 auto;

          label {
            font-size: 12px;
            color: gray;
            white-space: nowrap;
            align-self: center;
          }
        }
      }

      .button-padding {
        margin: 0 16px;
      }

      input {
        margin-top: 4px;
      }
    }
  }

  input {
    border-radius: 35px;
    border: 1px solid transparent;
    border-color: $deep-sea;
    background-color: $white;
    color: $deep-sea;
    padding: 0 0 0 10px;
    width: 52px;
    height: 24px;
    font-size: 12px;

    &:focus {
      outline: none;
    }
  }

  .tag-icon {
    fill: $fog;
  }
}
</style>