<template lang="pug">
.order-group(:class="'order-' + type" v-if="!disable")
  .header(@click="toggleCollapse" v-if="type == 'submitted'")
    ui-icon.collapse-icon(:class="{ collapsed: collapsed }") expand_more
    ui-icon {{ getOrderStatusIcon(type) }}
    .header-txt Submitted

  .header(@click="toggleCollapse" v-else-if="type == 'pending'")
    ui-icon.collapse-icon(:class="{ collapsed: collapsed }") expand_more
    ui-icon {{ getOrderStatusIcon(type) }}
    .header-txt Pending

  .header(@click="toggleCollapse" v-else-if="type == 'processing'")
    ui-icon.collapse-icon(:class="{ collapsed: collapsed }") expand_more
    ui-icon {{ getOrderStatusIcon(type) }}
    .header-txt Processing
    .header-status {{ status }}

  .header(@click="toggleCollapse" v-else-if="type == 'shipment'")
    ui-icon.collapse-icon(:class="{ collapsed: collapsed }") expand_more
    ui-icon {{ getOrderStatusIcon(type) }}
    .header-txt {{ value.shipmentNumber }}
    .header-status(v-if="value.tracking || value.shippedFrom")
      .shipped-from {{ getShippedFrom(value.shippedFrom, value.shipmentDate) }}
      .header-button
        .tracking(
            v-for="(trackingInfo, index) in carrierTracking.getShipmentCarrierTracking(value.shipmentNumber)" :key="index"
            :class="getTrackingIconClass(trackingInfo.carrier)"
            v-if="value.tracking" @click="showTracking($event, value.shipmentNumber, trackingInfo.carrier, trackingInfo.trackingLink)"
          )
          svg(v-if="isValidCarrier(trackingInfo.carrier)" width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg")
            path(class="outer" fill-rule="evenodd" clip-rule="evenodd" d="M14.8292 20.1199C17.6031 16.5637 20 13.4907 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 13.4907 2.39689 16.5637 5.17075 20.1199C6.78559 22.1902 8.52818 24.4243 10 27C11.4718 24.4243 13.2144 22.1902 14.8292 20.1199ZM10.0001 17.9998C14.4184 17.9998 18.0001 14.4181 18.0001 9.99981C18.0001 5.58153 14.4184 1.99981 10.0001 1.99981C5.58184 1.99981 2.00011 5.58153 2.00011 9.99981C2.00011 14.4181 5.58184 17.9998 10.0001 17.9998Z")
            path(class="inner" d="M9.2 12.4H10.8V14H9.2V12.4ZM9.2 6H10.8V10.8H9.2V6ZM9.992 2C5.576 2 2 5.584 2 10C2 14.416 5.576 18 9.992 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 9.992 2ZM10 16.4C6.464 16.4 3.6 13.536 3.6 10C3.6 6.464 6.464 3.6 10 3.6C13.536 3.6 16.4 6.464 16.4 10C16.4 13.536 13.536 16.4 10 16.4Z")
            path(class="boxLeft" fill-rule="evenodd" clip-rule="evenodd" d="M9.5 15L4.69312 13V7.53149L9.5 9.5V15Z")
            path(class="boxRight" fill-rule="evenodd" clip-rule="evenodd" d="M15.41 7.49146L10.6 9.49146V14.96L15.41 12.9915V7.49146Z")
            path(class="boxTop" fill-rule="evenodd" clip-rule="evenodd" d="M10.1259 9L15.5 6.5L10.1259 4.10791L4.5 6.49976L10.1259 9Z")
      .seperator(v-if="value.hasBillTrustInvoice && auth.loggedIn")
      .header-button(v-if="value.hasBillTrustInvoice && auth.loggedIn")
        .invoice
          ui-icon(v-bind:class="{hide: loading}" @click="getInvoice(value.shipmentId, customerNumber)") receipt
          .loading(v-bind:class="{hide: !loading}")
            ui-spinner.md-accent.loading(v-if="loading" :size="'small'" active)                        
    .shipment-dropdown(
        v-if="carrierTracking.isVisible(value.shipmentNumber)"
        v-bd-click-outside="hideOpenTrackingDropDown"
      )
      .shipment-text(ref="messageDropdown" v-html="getTrackingText(value.shipmentNumber)")
    .shipment-dropdown(v-if="errorInvoiceId == value.shipmentId" v-bd-click-outside="hideInvoiceErrorDropDown")
      .shipment-text(ref="messageDropdown") We&lsquo;re sorry but the invoice for shipment <strong>{{value.shipmentNumber}}</strong> is currently unavailable

  .header(@click="toggleCollapse" v-else-if="type == 'backordered'")
    ui-icon.collapse-icon(:class="{ collapsed: collapsed }") expand_more
    ui-icon {{ getOrderStatusIcon(type) }}
    .header-txt Backordered

  .header(@click="toggleCollapse" v-else-if="type == 'canceled'")
    ui-icon.collapse-icon(:class="{ collapsed: collapsed }") expand_more
    ui-icon {{ getOrderStatusIcon(type) }}
    .header-txt Canceled

  .collapsible(ref="collapsible")
    OrderLine(v-for="(line, index) in lines" v-model:value="lines[index]" :type="type")
</template>

<script>
  import OrderLine from '@/components/OrderLine'
  import CarrierTracking from '../utility/CarrierTracking.js'
  import { getOrderStatusIcon } from '../utility/Util.js'
  import { ref, inject } from 'vue'

  export default {
    name:'OrderGroup',
    model: {
      prop: 'value',
      event: 'update'
    },
    setup () {
      const messageDropdown = ref(null)
      const collapsible = ref(null)
      const auth = inject('auth')
      const { interceptor } = inject('httpInterceptor')

      return {
        messageDropdown,
        collapsible,
        auth,
        interceptor
      }

    },
    props: {
      value: {
        type: [Object, Array],
        required: true
      },
      type: {
        type: String,
        required: true,
        validator: (value) => {
          return ['submitted', 'pending', 'processing', 'shipment', 'backordered', 'canceled'].includes(value)
        }
      },
      trackingNumberCarrierGroups: {
        type: Array,
        required: false
      },
      carrierTracking: {
        type: Object,
        required: false
      },
      status: {
        type: String,
        required: false
      },
      customerNumber: {
        type: String,
        required: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      OrderLine
    },
    data() {
      return {
        collapsed: false,
        loading: false,
        errorInvoiceId: null
      }
    },
    mounted () {
      if(!this.disable) {
        this.expand()
      }
    },
    computed: {
      lines () {
        let lineData = []

        switch(this.type) {
          case 'processing':
            lineData =  this.value.filter(f => f.status == this.status)
            break
          case 'shipment':
            lineData = this.value.lines
            break
          // submitted, backordered, canceled, and pending
          default:
            lineData = this.value
            break
        }

        return lineData
      },
      orderDate () {
        return this.formatDate(this.Order.orderDate)
      }
    },
    methods: {
      getOrderStatusIcon (status) {
        return getOrderStatusIcon(status)
      },
      toggleCollapse (event) {
        if (!event.target.closest('.header-button')) {
          if(this.collapsible.clientHeight) {
            this.collapse()
          } else {
            this.expand()
          }
        }
      },
      expand () {       
        if (this.collapsible) {
          this.collapsible.style.height = this.collapsible.scrollHeight + 'px'
        }
        this.collapsed = false
      },
      collapse () {
        if (this.collapsible) {
          this.collapsible.style.height = 0
        }
        this.collapsed = true
      },
      hideOpenTrackingDropDown (event) {
        if (event.target.className != 'tracking invalid-icon') {
          this.carrierTracking.hideOpen()
        }
      },
      hideInvoiceErrorDropDown (event) {
        this.errorInvoiceId = null
      },
      showTracking (event, shipmentNumber, carrier, trackingLink) {
        if (this.isValidCarrier(carrier)) {
          window.open(trackingLink, '_blank')
        } else {
          this.carrierTracking.toggleVisibility(shipmentNumber)
          if (this.carrierTracking.isVisible(shipmentNumber)) {
            this.$nextTick(() => {
              this.messageDropdown.scrollIntoView()
            })
          }          
        }
      },
      getTrackingText (shipmentNumber) {
        let invalidCarrier = this.carrierTracking.getInvalidCarrier(shipmentNumber)

        return invalidCarrier ? invalidCarrier.trackingLink : ""
      },
      getTrackingIconClass (carrier) {
        let iconClass = ""

        switch (carrier) {
          case CarrierTracking.UPS_CARRIER:
            iconClass = 'ups-icon'
            break;
          case CarrierTracking.FEDEX_CARRIER:
            iconClass = 'fedex-icon'
            break;
          case CarrierTracking.ONTRAC_CARRIER:
            iconClass = 'ontrac-icon'
            break;
          default:
            iconClass = 'invalid-icon'
        }

        return iconClass
      },
      isValidCarrier (carrier) {
        return carrier != CarrierTracking.INVALID_CARRIER
      },
      getShippedFrom (allocatedWarehouse, expectedReceiptDate) {
        if (allocatedWarehouse) {
          if (allocatedWarehouse.trim().startsWith(',')) {
              allocatedWarehouse = allocatedWarehouse.substring(1)
          }
          allocatedWarehouse = allocatedWarehouse.trim()
        }
        allocatedWarehouse = allocatedWarehouse ? allocatedWarehouse +  ' - ' : ''
        let shippedFrom = allocatedWarehouse + (expectedReceiptDate ? this.formatDate(expectedReceiptDate) : 'Date Pending')
        return shippedFrom
      },
      formatDate (date) {
        return new Date(date).toLocaleDateString('en-us', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      },
      async getInvoice(shipmentId, customerNumber) {    

        if (this.errorInvoiceId == shipmentId) {
          //don't attempt to fetch the invoice since the invoice not found message is being displayed         
          return
        }

        this.loading = true    

        let hasError = false

        await this.interceptor.get(`/invoice/shipmentId/${shipmentId}/customerNumber/${customerNumber}`, {
          responseType: 'arraybuffer'
        })
          .then(response => {                     
            if(response.data && response.data.byteLength > 0){
              let downloadLink = document.createElement('a')
              downloadLink.target = '_blank'

              var blob = new Blob([response.data], { type: 'application/pdf' })

              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob)
                return
              }

              var URL = window.URL || window.webkitURL
              var downloadUrl = URL.createObjectURL(blob)
              
              downloadLink.href = downloadUrl
              downloadLink.download = `${this.value.shipmentNumber}.pdf`

              document.body.appendChild(downloadLink)

              downloadLink.click()

              setTimeout(function() {
                //clean up
                document.body.removeChild(downloadLink)
                URL.revokeObjectURL(downloadUrl)
              }, 100)
            }
            else {
              hasError = true
            }
          })
          .catch(error => {
            hasError = true
            console.error(error)
          })

          this.loading = false

          if (hasError) {
            this.errorInvoiceId = shipmentId
            this.$nextTick(() => {
              this.messageDropdown.scrollIntoView()
            })
          }
      }
    }
  }
</script>

<style scoped lang="scss">
.collapsible {
    transition: height .4s;
    overflow: hidden;
  }

  .collapse-icon {
    transition: transform .4s;
    margin-right: 10px;

    &.collapsed {
      transform: rotate(-90deg);
    }
  }

  .order-number {
    .header {
      padding: 6px 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    border-bottom: 3px solid $white;
    flex-wrap: wrap;

    .header-txt {
      padding: 5px 10px 0px;
      flex-grow: 2;
    }

    .header-status {
      white-space: nowrap;
      display: inline-block;

      .shipped-from {
        padding-top: 5px;
      }
    }

    .seperator {
      margin-top: 2px;
      width: 1px;
      height: 25px;
      margin: 0 4px;
      background-color: darken($teal, 15%);
    }

    .header-button {
      cursor: pointer;
      display: flex;
      .invoice {
        color: $white;
        .loading {
          width: 24px;
        } 
      }      
    }
  }

  .order-submitted {
    .header {
      background-color: $pending
    }    
  }
  .order-processing {
    .header {
      background-color: $pending
    }
  }
  .order-pending {
    .header {
      background-color: $pending
    }
  }

  .order-shipment {
    .header {
      background-color: $shipment;
      justify-content: flex-end;
      position: relative;
    }
    .header-status {
      display: flex;
    }
  }

  .order-backordered {
    .header {
      background-color: $backordered;
    }    
  }
  .order-canceled {
    .header {
      background-color: $canceled;
    }
  }

  .tracking {
    height: 27px;
    width: 20px;
    cursor: pointer;
    margin: 0 5px 0 15px;
  }

  .single-carrier-icon {
    background: url("../assets/tracking.svg") no-repeat;
  }

  .ups-icon {
      .outer {
        fill: #330000;
      }
      .boxTop, .boxLeft, .boxRight {
        fill: #F9BE02;
      }
      .inner {
        fill: transparent;
      }
  }

  .fedex-icon {
      .outer {
        fill: #4D148C;
      }
      .boxTop {
        fill: #F46604;
      }
      .boxLeft, .boxRight {
        fill: #FFFFFF;
      }
      .inner {
        fill: transparent;
      }
  }

  .ontrac-icon {
      .outer, .boxTop {
        fill: #0C2A68;
      }
      .boxLeft, .boxRight {
        fill: #F9CB0f;
      }
      .inner {
        fill: transparent;
      }
  }

  .invalid-icon {
      background: url("../assets/errorPin.svg") no-repeat;
  }

  .hide {
    display:none;
  }

  .shipment-dropdown {
    justify-content: space-between;
    background-color: $white;
    border: 1px solid $gray;
    box-shadow: 0px 3px 8px $shadow-color;
    position: absolute;
    padding: 8px;
    display: block;
    z-index: 50;
    color: $deep-sea;
    width: 200px;
    overflow-wrap: break-word;
    top: 30px;

    &.show{
      display: block;
    }

    .row {
      display: flex;
      justify-content: space-between;
      background-color: $white;
      color: $deep-sea;
      .shipment-text {
        padding: 3px;
        display: inline-flex;
        font-weight: bolder;

        .ui-icon {
          margin-right: 8px;
        }

      }
    }

  }
</style>