<template lang="pug">
filter-cache-list(
  :filters="filters"
  :goToBasePath="`/customer/${this.customerId}/orders?search=`"
  :quickSearchApi="`/Order/quickSearch?searchText=`"
  :getDataApi="`/Order/customer/${this.customerId}`"
  :searchPlaceholder="'Search by order number'"
  :activePath="'orders'"
  :searchQueryString="'search'"
  :blankSearchAllowed="true"  
  :noRecordsMessage="'No Orders Found'"
  :enableQrCodeButton="true"
  @updateCache="onUpdateCache"
  @filteredListDataUpdated="onFilteredListDataUpdated"
  :excludedFilters="['search', 'customernumber']"
  :cachedFilters="orderFilters()"  
  ref="filteredList"
  :disableFilterCacheLoadOnCreated="disableFilterCacheLoadOnCreated"
)
  template(v-slot:filters="{update, addDateFilterRef, dateFilterUpdate}")
    .filter(class="filter-label") Filters:
    label {{filters[FilterIndexes.BACK_ORDERED].label}}    
    .filter
      input(type='checkbox' v-model="filters[ FilterIndexes.BACK_ORDERED ].value" @change="update()")
    label {{filters[FilterIndexes.PENDING].label}}    
    .filter
      input(type='checkbox' v-model="filters[ FilterIndexes.PENDING ].value" @change="update()")
    label {{filters[FilterIndexes.CANCELED].label}}    
    .filter
      input(type='checkbox' v-model="filters[ FilterIndexes.CANCELED ].value" @change="update()")
    label {{filters[FilterIndexes.SUBMITTED].label}}    
    .filter
      input(type='checkbox' v-model="filters[ FilterIndexes.SUBMITTED ].value" @change="update()")      
    label {{filters[FilterIndexes.ORDER_DATE].label}}    
    .filter           
      bd-date-picker(@change="dateFilterUpdate(FilterIndexes.ORDER_DATE, $event)" v-model:value="filters[ FilterIndexes.ORDER_DATE ].value" :ref="el => addDateFilterRef(filters[ FilterIndexes.ORDER_DATE ].name, el)")
  template(v-slot:default="{data:order}")
    order-card.order(ref="orderCard" :Order="order" :isInListView="true" @goToOrderEvent="goToPath" :collapseGroups="groupsToCollapse" :key="order.orderNumber")
</template>

<script>  
  import OrderCard from '@/components/OrderCard'
  import FilterCacheList from '@/components/FilterCacheList'  
  import { createFilter, 
           createDateFilter,            
           appendDirectLinkParameter } from '../utility/Util.js'
  import { ref, inject, provide } from 'vue'
  import { EmittedEvents } from '../utility/Constants.js'
  import { mapGetters, mapActions } from 'vuex'  

  const FilterIndexes = {
    BACK_ORDERED: 0,
    PENDING: 1,
    CANCELED: 2,
    SUBMITTED: 3,
    ORDER_DATE: 4,
    CUSTOMER_NUMBER: 5
  }

  export default {
    name:'Orders',
    directives:  {},
    pageSize: 10,
    components: {
      OrderCard,
      FilterCacheList      
    },
    setup () {
      const filteredList = ref(null)
      const emitter = inject('emitter')
      const filters = ref([])

      provide('filters', filters)
      return {
        filteredList,
        emitter,
        FilterIndexes,
        filters
      }

    },
    props: {
      id: String,
      disableFilterCacheLoadOnCreated: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        groupsToCollapse: [],
        customerId: this.id,         
        triggerOrderRefresh: false
      }
    },
    beforeMount () {
      this.emitter.on(EmittedEvents.OrderRefreshEvent, () => this.triggerOrderRefresh = true)
    },
    beforeUnmount (){
      this.emitter.off(EmittedEvents.OrderRefreshEvent)
    },
    created () {
      this.filters = this.createFilters()
    },
    activated () {
      if (this.triggerOrderRefresh) {
        this.refreshOrders()
        this.triggerOrderRefresh = false
      }
    },
    watch: {
      id (newId, oldId) {
        this.customerId = newId        
        this.filters[FilterIndexes.CUSTOMER_NUMBER].value = this.customerId        
      }
    },
    computed: {
      ...mapGetters(['orderFilters'])
    },
    methods: {
      ...mapActions(['updateOrderFilters']),
      refreshOrders () {
        this.filteredList.reloadData()
      },
      onUpdateCache (cacheableFilters) {
        this.updateOrderFilters(cacheableFilters)
        
      },
      onFilteredListDataUpdated (activeFilters) {        
        this.setCollapseGroups(activeFilters)
      },
      setCollapseGroups (activeFilters) {
        let collapseGroups = ['pending', 'processing', 'shipment', 'canceled', 'backordered']

        activeFilters = activeFilters?.filter(filter => filter.visible && filter.value)
          .map(filter => filter.name)

        if (activeFilters.length > 0) {
          collapseGroups = collapseGroups.filter((item) => activeFilters.findIndex(filter => filter == item) < 0)
          this.groupsToCollapse = collapseGroups
        } else {
          this.groupsToCollapse = []
        }
      },
      createFilters() {        
        let filters = []       
        
        filters.push(createFilter("backordered", "Back Ordered", "hasBackOrderedItems", "checkbox"))
        filters.push(createFilter("pending", "Pending", "isPending", "checkbox"))
        filters.push(createFilter("canceled", "Canceled", "hasCanceledItems", "checkbox"))
        filters.push(createFilter("submitted", "Submitted", "isInSubmittedState", "checkbox"))        
        filters.push(createDateFilter("orderDate", "", "startDate", "endDate", true, true))        
        filters.push(createFilter("customerNumber", null, "customerNumber", null, false, this.id, true, false))        
        
        return filters
      },

      goToPath (searchValue) {
        
        const query = appendDirectLinkParameter({search: `${searchValue}`})
        
        this.$router.push({path: `/customer/${this.customerId}/orders`, query })
          .catch(err => {})

      }


    }
  }
</script>

<style lang="scss" scoped>
  .data-list {
    flex: 1 1 auto;
    overflow: auto;

    .order {
      max-width: 800px;
      width: 100%;
      margin: 10px;
      flex: 1 0;
      margin: auto;
      padding: 18px 2px;
    }
  }
  .filter-label {
    font-weight: bolder;
    @media only screen and (max-width: 605px) {
      padding: 0;
      margin-right: 5px;
      font-size: 12px;
    }
  }
</style>