import ImagePlaceholder from '@/assets/missing-image.png'

export function OrderHasData(order) {
  return (order
    && (order.submitted.length > 0
      || order.pending.length > 0
      || order.processing.length > 0
      || order.canceled.length > 0
      || order.shipments.length > 0
      || order.backordered.length > 0))
}

export const DISABLED_FILTER_VALUE = '_d_';

export function createFilter(name, label, query, type, visible = true, value = null, allowQueryStringUpdates = true,
  addToQueryString = true, dependentQueryStringValues = null, depth = 0, section = null, display = 'checkbox', url = null) {
  return {
    "name": name,
    "label": label,
    "query": query,
    "value": value,
    "type": type,
    "visible": visible,
    "allowQueryStringUpdates": allowQueryStringUpdates,
    "addToQueryString": addToQueryString,
    "dependentQueryStringValues": dependentQueryStringValues,
    "depth": depth,
    "section": section ?? 'Filters',
    "display": display, 
    "disabled": false,
    "disabledValue": null,
    "url": url
  }
}
export function createDateFilter(name, label, startDateQuery, endDateQuery, allowQueryStringUpdates = true, 
  addToQueryString = true, dependentQueryStringValues = null, depth = 0, section = null) {
  let filter = createFilter(name, label, null, "daterange", true, null, allowQueryStringUpdates, 
    addToQueryString, dependentQueryStringValues, depth, section)

  filter["startQuery"] = startDateQuery
  filter["endQuery"] = endDateQuery
  filter["value"] = {}        
  return filter
}

export function createDependentValue(name, value) {
  return {
    "queryStringName": name,
    "value": value
  }
}

export function getOrderStatusIcon (status) {
  let icon = null;

  switch (status?.toLowerCase()) {
    case "submitted":
      icon = "send"
      break
    case "processing":
    case "pending":
      icon = "loop"
      break
    case "shipment":
    case "shipped":      
      icon = "local_shipping"
      break
    case "backordered":
    case "canceled":      
      icon = "warning"
      break                        
    default:
      icon = null
      break
  }
  return icon 
}

export function isNumber (value) {  
  return value && !isNaN(value)
}

export function isObjectEmpty (obj) {  
  const checkObject = obj ?? {}
  return checkObject && Object.keys(checkObject).length == 0
}

export function getImageUrlFromProduct (product) {
  let url = null

  if (product?.attributes?.length > 0) {    
    url = _getMedia(product, "image")    
    if (!url) {
      let attribute = product.attributes.find(a => a?.attributeName == "WebMedia")
      url = attribute?.data?.WebImageUrl      
    }
  }

  return url ? url : ImagePlaceholder
}

export function getSdsUrlFromProduct (product) {
  return _getMedia(product, "document")
}

function _getMedia(product, mediaType) {
  let url = null
  if (product?.attributes?.length > 0) {
    let mediaAttribute = product.attributes.find(a => a.attributeName?.includes('MediaAttribute'))
    if (mediaAttribute) {
      let primaryMedia = mediaAttribute.data?.Data?.find(i => i.Data?.Primary && i.Data?.MediaType?.toUpperCase() == mediaType.toUpperCase() && !i.Data?.DeletedOn)
      url = (primaryMedia?.Data?.Url) ?? null
    }
  }
  return url  
}

export function getCoupon (product) {
  return (!!product?.suppressCoupon) ? null : product?.coupon
}

export function isPricingLoaded (product, waitForPricingToLoad) {
  return product?.pricingLoaded || !waitForPricingToLoad
}

export function currencyFormatter (value) {
  return decimalFormatter(value, 2, 'currency')
}

export function decimalFormatter(value, decimalDigits=2, style='decimal') {

  if (typeof value != "number") {
    return value
  }

  let properties = {
    style: style,
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits
  }

  if (style=='currency') {
    properties.currencyDisplay = 'symbol'
    properties.currency= 'USD'
  }

  let formatter = new Intl.NumberFormat('en-US', properties)

  return formatter.format(value);
}

export function dateFormatter (value) {
  return new Date(value).toLocaleDateString('en-us', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })    
}

export function timeFormatter (value) {
  return new Date(value).toLocaleTimeString('en-us', {
    hour: '2-digit',
    minute: '2-digit'
  })
}

export function goToProduct (router, customerId, itemNumber) {
  let path = `/product/internal/${itemNumber}`
  if (customerId) {
    path = `/customer/${customerId}${path}`
  } 
  
  router.push({path})
    .catch(err => {})
}

export async function getProduct (interceptor, itemNumber, customerNumber = null) {
  if (!interceptor || !itemNumber) {
    return null
  }

  let customerNumberParameter = ''

  if (customerNumber) {
    customerNumberParameter = `?customerNumber=${customerNumber}`
  }
  let product = null
  try {
    product = (await interceptor.get(`item/itemNumber/${itemNumber}${customerNumberParameter}`))?.data
  } catch (error) {}

  return product
}


export const DIRECT_LINK_QUERY_PARAMETER = "directLink";

/*
 * Creates the filter data that will be cached for the given active filters. Only the 
 * filters that have the addToQueryString property set to true will be added to the 
 * query string. If there are no cacheable filters after removing the excluded
 * filters then cacheNeedsUpdate will return false otherwise it will be true
 * @param {Array} activeFilters - List of active filters
 * @param {Array} excludedFilters - List of filters names to exclude from caching 
*/
export function createCachedFilters (activeFilters, excludedFilters, routeQuery) {
  let cacheableFilters = null
  let cacheNeedsUpdate = true

  if (isDirectLink(routeQuery)) {
    cacheNeedsUpdate = false
  } else if (activeFilters?.length > 0) {    
    if (excludedFilters?.length > 0) {      
      const exclude = new Set(excludedFilters.map(m => m.toUpperCase()))
      cacheableFilters = activeFilters.filter(f => !exclude.has(f.query.toUpperCase()))                  
    } else {
      cacheableFilters = activeFilters
    }
  } 
  
  return { cacheableFilters, cacheNeedsUpdate }
}


export function hasFilters (activeFilters, excludedFilters) {
  let hasFilters = false

  if (!excludedFilters) {
    excludedFilters = []
  }
  const activeFilterQueryStringNames = activeFilters?.map(a => a.query)
  if (activeFilterQueryStringNames?.filter(f => f).length > 0) {    
    const exclude = new Set(excludedFilters.map(m => m.toUpperCase()))
    let result = activeFilterQueryStringNames.filter(f => !exclude.has(f.toUpperCase()))    
    hasFilters = result?.length > 0
  }

  return hasFilters
}

export function isDirectLink (routeQuery) {    
  let isDirectLink = false

  if (routeQuery) {
    isDirectLink = !!routeQuery[DIRECT_LINK_QUERY_PARAMETER]
  }

  return isDirectLink
}


export function appendDirectLinkParameter (routeQuery) {    
  if (routeQuery) {
    routeQuery[`${DIRECT_LINK_QUERY_PARAMETER}`] = true
  }

  return routeQuery
}

export function handleDisplayValue(value, length, isMobileDevice) {
  if (isMobileDevice && value.length > length) {
    return value.slice(0, length) + '...'
  } return value
}