<template lang="pug">
bd-filtered-list(  
  :quickSearchApi="quickSearchApi"
  :getDataApi="getDataApi"
  :searchPlaceholder="searchPlaceholder"
  :activePath="activePath"
  :searchQueryString="searchQueryString"
  :blankSearchAllowed="blankSearchAllowed"
  :noRecordsMessage="noRecordsMessage"
  :enableQrCodeButton="enableQrCodeButton"
  :enableViewOptions="true"
  ref="bdFilteredList"
  @filterUpdate="onFilteredListDataUpdate"
  :disabledValue="disabledValue",
  :disableQuickSearchResults="disableQuickSearchResults",
  :minimumSearchLength="minimumSearchLength"
  :pageSize="pageSize"
  @dataUpdate="onDataUpdate"
  disableRegExValidation
)
  template(v-slot:viewOptionsSlot="")
    slot(name="viewOptionsSlot")
  template(v-slot:filters="{update, addDateFilterRef, dateFilterUpdate}")
    slot(name="filters" 
         :update="update" 
         :addDateFilterRef="addDateFilterRef" 
         :dateFilterUpdate="dateFilterUpdate" )  
  template(v-slot:filterList="" v-if="!!$slots.filterList")
    slot(name="filterList")        
  template(v-slot:default="{data}")
    slot(name="default" :data="data")
  template(v-slot:defaultData="")
    slot(name="defaultData")
</template>

<script>  
  import {createCachedFilters,
          hasFilters, 
          isDirectLink } from '../utility/Util.js'  
  import { provide, ref } from 'vue'  

  export default {
    name:'FilterCacheList',    
    emits: ['updateCache', 'filteredListDataUpdated', 'dataUpdate'],
    props: {
      openDecodedUrlNewWindow: {
        default: true,
        type: Boolean,
      },
      filters: {
        default: [],
        type: Array,
      },
      quickSearchApi: String,
      getDataApi: String,
      searchPlaceholder: String,
      activePath: String,
      searchQueryString: String,
      quickSearchBasePath: {
        default: null,
        type: String,
      },
      blankSearchAllowed: {
        default: true,
        type: Boolean,
      },
      noRecordsMessage: {
        default: 'No Records Found',
        type: String,
      },
      enableQrCodeButton: {
        default: true,
        type: Boolean,
      },
      enableViewOptions: {
        default: false,
        type: Boolean
      },
      disabledValue: {
        default: 'disabled',
        type: String
      },
      disableFilterCacheLoadOnCreated: {
          default: false,
          type: Boolean
      },
      excludedFilters: {
        default: [],
        type: Array
      }, 
      cachedFilters: {
        default: [], 
        type: Array,        
      },
      disableQuickSearchResults: {
        default: false, 
        type: Boolean
      }, 
      minimumSearchLength: {
        default: null,
        type: Number
      },
      compactView: {
        default: false,
        type: Boolean
      }
    },
    setup () {
      const bdFilteredList = ref(null)
      return {        
        bdFilteredList
      }
    },    
    created () {      
      if (!this.disableFilterCacheLoadOnCreated) {      
          this.$nextTick(() => {
          this.updateFiltersFromCache()
        })
      } else {
        this.cachedDataLoaded = true
      }
    },
    data () {
      return {
        cachedDataLoaded: false,
        pageSize: 20
      }
    },
    methods: {
      reloadData () {
        this.bdFilteredList.reloadData(true, true)
      },
      updateFilteredList () {        
        this.bdFilteredList.filterUpdate()
      },

      setCachedDataLoaded (value) {        
        this.cachedDataLoaded = value
      },
      onFilteredListDataUpdate ({activeFilters, dataUpdated, quickSearchCleared}) {
        const hasActiveFilters = hasFilters(activeFilters, this.excludedFilters)
        if ((dataUpdated || hasActiveFilters) && this.cachedDataLoaded && !quickSearchCleared) {      
          const {cacheableFilters, cacheNeedsUpdate} = createCachedFilters(
            activeFilters, 
            this.excludedFilters,
            this.$route.query)
          
          if (cacheNeedsUpdate) {
            this.$emit('updateCache', cacheableFilters)            
          }
        } else if (!hasActiveFilters && (quickSearchCleared || !this.cachedDataLoaded)) {
          this.updateFiltersFromCache(true)
        } else {
          this.cachedDataLoaded = true
        }

        this.$emit('filteredListDataUpdated', activeFilters)
      },
      updateFiltersFromCache (forceUpdate = false) {
        if (!this.cachedDataLoaded || forceUpdate) {
          this.cachedDataLoaded = true                
          if (!isDirectLink(this.$route.query)) {                    
            this.bdFilteredList.updateActiveFilters(this.cachedFilters)
          }
        }
      },
      onDataUpdate({data}){
        this.$emit('dataUpdate', {data})
      }
    }

  }
</script>

<style scoped lang="scss">
</style>