<template lang="pug">
.account-selector
  .loading(v-if="loading") {{ loadingMessage }}
  .account-selector-detail(v-if="accounts?.length > 0")    
    .accounts
      .row(
        v-for="(account, index) in accounts",
        :key="accounts.customerNumber",
        @click="goToCustomer(account.customerNumber, account.lineItemCount)"
      ) 
        .leftContainer
          bd-button(
            icon="shopping_cart"
            :alert="account.lineItemCount"
            :badgeColor="'#8ABF41'"
            :centerBadge="true"
            :disableClick="true"
            v-if="account?.lineItemCount"
          )
          bd-button(v-if="isCustomerUser && !account?.lineItemCount" icon="person" :disableClick="true") 
          .nameContainer {{ account.customerName.length > 30 ? account.customerName.slice(0, 30) + '...' : account.customerName }}
  .no-accounts(v-if="(accounts?.length ?? 0) == 0 && !loading && isCustomerUser") You do not have access to any customers 
  </template>
    
  <script setup>
  import { inject, onBeforeMount, ref, computed } from "vue";
  import { useRouter } from "vue-router";
  import { EventTypes, authUtility } from "@burkhart/vue-components";
  import { AuthorizationRoles } from "../utility/Constants.js";


  const router = useRouter();
  const { interceptor } = inject("httpInterceptor");
  const emitter = inject("emitter");
  let accounts = ref([]);
  let openOrders = ref([]);
  const authorizationUtility = authUtility;
  const authRoles = AuthorizationRoles;
  const loading = ref(false);

  const props = defineProps({
    isCustomerUser: {
      type: Boolean,
      default: false
    },
    role: String
  })
  
  onBeforeMount(async () => {
    let navigateToCustomer = false
    loading.value = true
  
    if (props.isCustomerUser) {
      try {
        accounts.value = (
          await interceptor.get(
            `user/customeraccess/${authUtility.email}/all`
          )
        ).data;
      } catch (error) {
        console.error(error);
        loading.value = false;
        accounts = []
        emitter.emit(EventTypes.ErrorEvent, `Error loading your accounts`);
      }
    }
    

    //load the open orders if in the right role, to add into loaded accounts
    if (authorizationUtility.IsInRole(authRoles.OrderSubmit) || authorizationUtility.IsInRole('CustomerService')) {
      try {
        openOrders.value = (
          await interceptor.get(
            "shoppingcart/openorders/user?pageIndex=0&pageSize=1000"
          )
        ).data;
      } catch (error) {
        console.error(error);
        loading.value = false;      
        emitter.emit(EventTypes.ErrorEvent, `Error loading open orders`);
        openOrders = []
      }   
      
      if (accounts.value?.length > 0 && openOrders.value?.length > 0){
        accounts.value.forEach(a => {
          const openOrder = openOrders.value?.find(o => o.customerNumber == a.customerNumber)
          if (openOrder) {
            a.lineItemCount = openOrder.lineItemCount
            a.modified = openOrder.modified
          }
        })
      }
    }
    
    loading.value = false;

    if (accounts.value?.length > 0) {
      if (accounts.value.length == 1) {
        navigateToCustomer = true
      }
      accounts.value.sort(function (x, y) {
        let xName = x.customerName.toUpperCase();
        let yName = y.customerName.toUpperCase();
        return (xName < yName) ? -1 : (xName > yName) ? 1 : 0
      })
    }
    if (navigateToCustomer) {
      const accountInfo = accounts.value[0]
      goToCustomer(accountInfo.customerNumber, accountInfo.lineItemCount)
    }
  });
  
  const goToCustomer = (customerId, lineItemCount) => {
    const path = lineItemCount > 0 ? '/cart' : '';

    router.push({ path: `/customer/${customerId}${path}` }).catch((err) => {
      console.error(err);
      emitter.emit(EventTypes.ErrorEvent, `Could not switch to customer`);
    });
  };

  const loadingMessage = computed(() => 
    (props.isCustomerUser ? "Loading accounts" : "Checking for open carts") + "...")
  </script>
  
  <style lang="scss" scoped>
  $radius: 8px;
  .account-selector {
    font-size: 12px;
    width: 100%;  
    display: flex;
    flex-direction: column;
    .account-selector-detail {
      height: 100%;
      display: flex;
      flex-direction: column;
      .accounts {
        display: flex;
        flex-flow: column;
        margin-left: 4px;
        overflow: auto;     
        flex-grow: 1;
        min-height: 0;           
        .row {
          display: flex;
          flex-flow: row;
          align-items: center;
          border-radius: $radius;
          padding-top: 2px;
          justify-content: space-between;
          padding-right: 8px;
          min-height: 2.375rem;
          &:hover {
            cursor: pointer;
            background-color: darken($gray, 7);
          }
          .leftContainer {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .no-accounts {
    color: $sunrise;
    font-size: 20px;
    margin:0 auto;
    font-weight: bolder;
    margin-top: 10px;
  }
  </style>  