<template lang="pug">
.product-card
  bd-card
    template(v-slot:header="")
      product-header(v-model="product" :customerId="customer.CustomerID" enableClickToOpenProduct)
    .product
      .product-image-info
        .product-image
          image-display(:modelValue="imageSrc" :width="232" :height="232")
          product-purchase-info.small-screen(v-model="product" v-bind="purchaseInfoProperties")          
        .info
          product-description.field(v-model="product" compactView)
          .attachments
            product-sds(v-model="product")
          .availability-coupon
            product-availability(v-if="!isObjectEmpty(customer)" v-model="product" :customer="customer" :isCustomerUser="isCustomerUser")            
            .vertical-rule(v-if="!isObjectEmpty(customer) && hasCoupon")            
            product-coupon(v-model="product" :waitForPricingToLoad="waitForPricingToLoad")
          product-order-history(v-model="product" :customerId="customer.CustomerID" :orderHistoryPageSize="orderHistoryPageSize")
      product-purchase-info.large-screen(v-model="product" v-bind="purchaseInfoProperties")
</template>

<script>
  import { mapGetters, mapActions} from 'vuex'
  import { getImageUrlFromProduct, getCoupon, isObjectEmpty, isPricingLoaded } from '../utility/Util.js'  
  import QuantityInput from '@/components/QuantityInput'
  import { debounce } from 'throttle-debounce'
  import ImageDisplay from '@/components/ImageDisplay'  
  import ProductHeader from '@/components/ProductHeader'
  import ProductPurchaseInfo from '@/components/ProductPurchaseInfo'
  import ProductDescription from '@/components/ProductDescription'
  import ProductSds from '@/components/ProductSDS'
  import ProductAvailability from '@/components/ProductAvailability'
  import ProductCoupon from '@/components/ProductCoupon'
  import ProductOrderHistory from '@/components/ProductOrderHistory'



  export default {
    name:'ProductCard',
    components: {
      QuantityInput, 
      ImageDisplay,
      ProductHeader,
      ProductPurchaseInfo,
      ProductDescription,
      ProductSds,
      ProductAvailability,
      ProductCoupon,
      ProductOrderHistory
    },    
    props: {
      product: Object,
      orderHistoryPageSize: {
        type: Number,
        default: 5
      }, 
      enableCart: {
        type: Boolean,
        default: false
      }, 
      enablePricing: {
        type: Boolean,
        default: false
      },
      compactView: {
        type: Boolean, 
        default: true
      }, 
      waitForPricingToLoad: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      isObjectEmpty,
      ...mapActions(['addItemToCart', 'clearCart']),
      addItem: debounce(300, function (data) {this.saveItem(data)}),
      async saveItem (data) {
        if (!this.addToCartInProgress) {          
          this.addToCartInProgress = true
          data.quantity ??= 1
          data.imageUrl = this.imageSrc
          await this.addItemToCart({itemObject: data})
          this.addToCartInProgress = false
          this.quantity = null
        }
      }
    },
    computed: {
      ...mapGetters([
        'customer', 'cartItemQuantity', 'isCustomerUser'
      ]),
      imageSrc () {
        return getImageUrlFromProduct(this.product)        
      },
      hasCoupon () {
        return !!getCoupon(this.product) && isPricingLoaded(this.product, this.waitForPricingToLoad)
      },
      purchaseInfoProperties () {
        return {
          enablePricing: this.enablePricing, 
          isCustomerUser: this.isCustomerUser,
          enableCart: this.enableCart,
          waitForPricingToLoad: this.waitForPricingToLoad,
          compactView: true
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.product-card {
  .product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .product-image-info {
      display: flex;
      width: 100%;
      
      .info {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          padding: 0 16px;
      }      
      @media only screen and (max-width: 650px) {
        flex-direction: column;
        .product-image {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 16px 16px 0;
        }
      }

      @media only screen and (min-width: 651px) {
        flex-direction: row;
        .product-image {
          flex: 0 0 auto;          
        }
      }
    }

    .product-purchase-info {
      border-left: solid 1px $gray;
      padding-left: 16px;      
      width: 88px;
      @media only screen and (max-width: 650px) {
        
        &.large-screen {
          display: none;
        }
      }

      @media only screen and (min-width: 651px) {
        &.small-screen {
          display: none;
        }
      }      
    }
  }

  .field {
    margin-bottom: 8px;
  }

  .info {
    max-width: 490px;
    display: flex;
    flex: 1 1 auto;
    @media only screen and (max-width: 650px) {
      max-width: 590px;
    }
    .attachments {
      margin-left:-12px;
    }
  }

  .description {
    margin-top: 8px;
    width: 100%;
  }

  .button {
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin: auto !important;
  }

  .vertical-rule {
    width: 1px;
    background-color: $gray;
  }  

  .availability-coupon {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    
    .product-availability {
      min-width: 168px;
    }
    
  }

  .title-description {
    .title {
      margin-bottom: 8px;
    }
  }


}
</style>