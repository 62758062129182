import { httpInterceptor, emitter, EventTypes } from '@burkhart/vue-components'
import CartItem, {CartItemUpdate} from '../utility/CartItem.js'
import { EmittedEvents } from '../utility/Constants.js'
import { debounce } from 'throttle-debounce'

function _createEmptyCart () {
  return {
    customerNumber: null,
    shipToCustomerNumber: null,
    accountManagerNotes: [],
    comments:null,    
    items:[],
    key: null,
    pOnumber: null,
    freeShipping: false, 
    futureFillDate: null,
    syncEnabled: true,
    modified: null
  }
}

function _findRedemptionItem(item, itemNumber, couponId) {
  return item.itemNumber == itemNumber && item.type != 0 && item.coupon.id == couponId
}

function _findStandardItem(item, itemNumber) {
  return item.itemNumber == itemNumber && item.type == 0
}

function _getRedemptionItem(context, {itemNumber, couponId}) {
  return context.state.cart.items.find(item => item.itemNumber == itemNumber 
    && item.type != 0 && item.coupon.id == couponId)    
}

function _getCouponInfo(state, couponId) {
  return _getCouponInfoFromData(state.couponInfo, couponId)
}

function _getCouponInfoFromData(couponInfoSource, couponId) {
  return couponInfoSource.find(i => i.couponId == couponId)
}

function _createRedemptionUpdate (redemption) {
  return _createItemUpdate(redemption.itemNumber, true, redemption.couponId, false)
}

function _createStandardItemUpdate (itemNumber) {
  return _createItemUpdate(itemNumber, false, null, false)
}

function _createItemUpdate (itemNumber, isRedemptionItem, couponId, isItemDeleted = false) {
  const update = new CartItemUpdate
    (itemNumber, isRedemptionItem, couponId, isItemDeleted)
      .get()
  return update
}

function _synchronizeMetadata (storedCart, contextGetters, updatedElements, metadataField, getterFieldName = null, cartFieldName = null) {
  getterFieldName ??= metadataField
  cartFieldName ??= metadataField

  storedCart[cartFieldName] = updatedElements[metadataField] 
    ? contextGetters[getterFieldName] : storedCart[cartFieldName]
}


/**
 * Goes through and makes sure that all redemptions related to a coupon
 * are set to what the curent cart has only if a valid coupon id is given
 * @param {object} storedCart 
 * @param {Number} couponId  
 * @param {object} currentCart 
 */
function _synchronizeRedemptionItems (storedCart, couponId, currentCartItems) {
  
  if (!couponId) {
    return
  }

  const redemptionRelatedItems = currentCartItems.filter
    (f => f.coupon?.id == couponId)
    
  for (let i = 0; i < redemptionRelatedItems.length; i++) {
    const item = redemptionRelatedItems[i]
    const storedItemIndex = storedCart.items.findIndex
      (s => s.itemNumber == item.itemNumber && s.type == item.type && s.coupon.id == couponId)

    if (storedItemIndex >= 0) {
      storedCart.items[storedItemIndex] = item
    } else {
      storedCart.items.push(item)
    }
  }
  
}

const state = () => ({
  cart: _createEmptyCart(),
  couponInfo: [],
  updatedElements: {
    accountManagerNotes: false, 
    futureFillDate: false, 
    freeShipping: false, 
    poNumber: false,
    comments: false
  }
})

const getters = {
  cart(state) {
    return state.cart.items.reduce((couponList, item) => {
      let couponId = item.coupon?.id
      if (couponId) {
        let couponGroupItem = couponList.find(couponGroup => couponGroup.id == couponId)  
        if (couponGroupItem) {
          if (item.type == 0) {
            couponGroupItem.qualifyingList.push(item)
          } else {
            couponGroupItem.couponRedemptionList.push(item)
          }
        } else {
          let qualifyingList = []
          let couponRedemptionList = []

          if (item.type == 0) {
            qualifyingList.push(item)
          } else {
            couponRedemptionList.push(item)
          }
          couponList.push({
            id: couponId,
            qualifyingList: qualifyingList,
            couponRedemptionList: couponRedemptionList
          })
        }
      } else {
        couponList.push({
          qualifyingList: [item]
        })
      }
      return couponList
    }, [])
  },
  items (state) {
    return state.cart.items
  },
  cartTotal(state) {
    let p = 0
    state.cart.items.forEach(i => p += (i.hasPriceOverride ? i.overridePrice : i.price) * i.quantity)
    return p
  }, 
  cartQuantity (state) {
    return state.cart.items.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0)
  },
  cartKey (state) {
    return state.cart.key
  },
  couponInfo (state) {
    return state.couponInfo
  },
  poNumber (state) {
    return state.cart.pOnumber
  },
  comments (state) {
    return state.cart.comments
  },
  cartItemQuantity: (state) => (itemNumber) => {
    return state.cart.items.find(item => _findStandardItem(item, itemNumber))?.quantity
  }, 
  lineItemCount (state) {
    return state.cart.items.filter(c => c.type == 0).length
  },
  standardItemNumbers (state) {    
    return state.cart.items.filter(s => s.type == 0).map(m => m.itemNumber)
  },
  freeShipping (state) {
    return state.cart.freeShipping
  }, 
  accountManagerNotes (state) {
    return state.cart.accountManagerNotes
  },
  futureFillDate (state) {
    return state.cart.futureFillDate
  },
  actualItemPrice: (state) => (item) => {
    return item.hasPriceOverride ? item.overridePrice : item.price
  }, 
  cartModified (state) {
    return new Date(state.cart.modified)
  }, 
  updatedElements (state) {
    return state.updatedElements
  },
  actualCart (state) {
    return state.cart
  }
}

const mutations = {
  addItemToCart(state, itemObj) {
    let cartItem = state.cart.items.find(item => item.itemNumber == itemObj.itemNumber && item.type == 0)
    if (cartItem) {
      cartItem.quantity += itemObj.quantity
    } else {
      state.cart.items.push(itemObj)
    }
  },
  addCustomerInfoToCart(state, customerInfo) {
    state.cart.customerNumber = customerInfo.CustomerID
  },
  loadCart(state, data) {
    state.cart = data
  },
  loadCouponInfo (state, data) {
    state.couponInfo = data
  },
  addCouponRedemption(state, redemption) {
    state.cart.items.push(redemption)
  },
  removeItemFromCart(state, index) {
    state.cart.items.splice(index, 1)
  },
  overridePriceStore(state, overrideInfo) {
    let index = state.cart.items.findIndex(item => _findStandardItem(item, overrideInfo.itemNumber))
    state.cart.items[index].overridePrice = overrideInfo.overridePrice
    state.cart.items[index].hasPriceOverride = overrideInfo.hasPriceOverride
  },
  changeQty(state, value) {
    let index = state.cart.items.findIndex(item => item.itemNumber == value.itemNumber && item.type == 0)
    state.cart.items[index].quantity = value.quantity
  },
  changeRedemptionQty(state, {storedRedemption, quantity}) {
    storedRedemption.quantity = quantity
  },
  clearCart(state) {
    delete state.cart
    state.cart = _createEmptyCart()

    if (state.couponInfo) {
      state.couponInfo.length = 0
    }
  },
  addCartKey (state, value) {
    state.cart.key = value
  },
  addCouponInfo (state, {couponId, itemNumber, options}) {
    let couponInfo = {
      couponId: couponId,
      items: [itemNumber],
      options: options,
      isRedemptionComplete: false
    }    
    state.couponInfo.push(couponInfo)
  },
  updateCouponInfoItems (state, {couponId, itemNumber}) {
    let couponInfo = _getCouponInfo(state, couponId)   
    if (couponInfo.items.indexOf(itemNumber) < 0) {    
      couponInfo.items.push(itemNumber)
    }
  },
  removeCouponItem (state, {couponId, itemNumber}) {    
    let couponInfo = _getCouponInfo(state, couponId)   
    let index = couponInfo.items.indexOf(itemNumber)
    couponInfo.items.splice(index, 1)
  },
  removeCouponInfo (state, couponId) {
    let index = state.couponInfo.findIndex(c => c.couponId == couponId)
    state.couponInfo.splice(index, 1)
  },
  updateComments (state, value) {
    state.cart.comments = value
    state.updatedElements.comments = true
  },
  updatePoNumber(state, value) {
    state.cart.pOnumber = value
    state.updatedElements.poNumber = true
  },
  updateFreeShipping (state, value) {
    state.cart.freeShipping = value
    state.updatedElements.freeShipping = true
  },
  updateAccountManagerNotes (state, value) {
    const index = 0
    let note = null
    
    state.cart.accountManagerNotes ??= []

    if (state.cart.accountManagerNotes.length == 0) {
      note = {
        note: value
      }
      state.cart.accountManagerNotes.push(note)
    } else {
      note = state.cart.accountManagerNotes[index]
      note.note = value      
    }
    state.updatedElements.accountManagerNotes = true    
  }, 
  updateFutureFillDate(state, value) {
    state.cart.futureFillDate = value
    state.updatedElements.futureFillDate = true
  },
  updateModifiedDate (state, value) {
    state.cart.modified = value
  },
  enableSync (state) {
    state.cart.syncEnabled = true
  },
  resetUpdatedElements (state) {
    for (const [key, value] of Object.entries(state.updatedElements)) {
      state.updatedElements[key] = false
    }      
  }
}

const actions = {
  async addItemToCart (context, {itemObject, emitMessageAfterCartUpdate = false}) {
    let cartItem = new CartItem(itemObject.data, itemObject.quantity, 0, null).getCartItem()
    if (cartItem.coupon) {

      let couponInfo = _getCouponInfo(context.state, cartItem.coupon.id)      

      // if the coupon info doesn't already exist then the descriptions will 
      // need to be fetched
      if (!couponInfo) {

        const sourceCouponData = cartItem.coupon.data

        sourceCouponData.Options.forEach(i => {          
          i.isRedemptionBurkhartProduct = false
          if (sourceCouponData.RewardType?.toUpperCase() == "R") {
            i.redemptionItem = i.CouponOptions
          } else {
            i.redemptionItem = i.FreeProducts
          }

          i.redemptionDescription = i.redemptionItem
        })
        //filter out any non-product numbers
        const productNumbers = sourceCouponData.Options
          .filter(o => o.redemptionItem?.trim().match(/^[\d]/) )
          .map(m => m.redemptionItem)

        let productNumberDescriptions = {}

        if (productNumbers.length > 0) {
          // get the descriptions for all products associated with the coupon
          try {
            let response = await httpInterceptor.interceptor.post('/item/details', productNumbers)
            response.data.forEach(m => productNumberDescriptions[m.itemNumber] = m.description)
          } catch (error) {
            console.error(error)
          }           
        }
        // add the description to the coupon data options
        if (Object.keys(productNumberDescriptions).length > 0) {
          sourceCouponData.Options.forEach(i => {
            if (productNumberDescriptions[i.redemptionItem]) {
              i.redemptionDescription = `${productNumberDescriptions[i.redemptionItem]}`
              i.isRedemptionBurkhartProduct = true
            }
          })
        }
                
        context.commit('addCouponInfo', { 
          couponId: cartItem.coupon.id, 
          itemNumber: cartItem.itemNumber,
          options: sourceCouponData.Options
        })

      } else {
        cartItem.coupon.data.Options = couponInfo.options

        //keep track of the items associated with a coupon
        context.commit('updateCouponInfoItems', {
          couponId: cartItem.coupon.id,
          itemNumber: cartItem.itemNumber
        })        
      }
    }

    
    if (!context.state.cart.customerNumber) {
      context.commit('addCustomerInfoToCart', context.rootGetters.customer)
    }
    await context.dispatch('_executeAddItemToCart', {cartItem, emitMessageAfterCartUpdate})
    
  },
  async getActiveCart (context) {
    let storedCart = null
    try {      
      let result = (await httpInterceptor.interceptor.get(`/shoppingcart/customerNumber/${context.rootGetters.customerId}?pageIndex=0&pageSize=1`))?.data      
      storedCart = result ? result[0] : null
    } catch (error) {
      console.error(error)
    }

    return storedCart
  },
  async loadCart (context) {
    let cart = await context.dispatch('checkActiveCart')
    if (cart != null) {
      context.commit('loadCart', cart)
    }    
  },
  updateCustomerCart(context, customerNumber) {
    httpInterceptor.interceptor.get(`/shoppingcart/customernumber/${customerNumber}`)
      .then(response => {
        let cartData = response.data?.find(f=>f.submittedDate == null)
        if (!cartData) {
          cartData = _createEmptyCart()
        }
        context.commit('loadCart', cartData)
        //load the couponinfo
        if (cartData?.items?.length > 0) {
          for (var i = 0; i < cartData.items.length; i++) {
            let item = cartData.items[i]
            //only want to look at qualifying items
            if (item.type != 0) {
              continue
            }
            if (item.coupon) {
              let couponInfo = _getCouponInfo(context.state, item.coupon.id)
              if (!couponInfo) {
                context.commit('addCouponInfo', { 
                  couponId: item.coupon.id, 
                  itemNumber: item.itemNumber,
                  options: item.coupon.data.Options
                })                
              } else {
                context.commit('updateCouponInfoItems', {
                  couponId: item.coupon.id,
                  itemNumber: item.itemNumber
                })                
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  async removeItemFromCart(context, {itemNumber, couponId, isRedemptionItem}) {
    let index = null

    if (isRedemptionItem) {
      index = context.getters.items.findIndex(i => _findRedemptionItem(i, itemNumber, couponId))
    } else {
      if (couponId) {
        //all coupon redemptions will need to be deleted if all qualifying items
        //for a coupon have been deleted
        let couponItems = context.state.cart.items.filter(i => i.coupon?.id == couponId)
        let qualifyingItems = couponItems.filter(c => c.type == 0)        
        if (qualifyingItems.length == 1 && 
          qualifyingItems[0].itemNumber == itemNumber) {
            //remove all redemptions
            let redemptions = couponItems.filter(c => c.type != 0)
            redemptions.forEach(r => context.dispatch(
              'removeItemFromCart',({
                itemNumber: r.itemNumber,
                couponId: r.coupon.id,
                isRedemptionItem: true}
            )))
          }
      }
      index = context.getters.items.findIndex(i => _findStandardItem(i, itemNumber))      
      context.dispatch('removeTagRequest', itemNumber, {root: true})
    }

    if (index >= 0) {
        if (couponId && !isRedemptionItem) {
          //remove the item from the coupon it is associated with
          context.dispatch('removeCouponItem', {
            couponId: couponId,
            itemNumber: itemNumber
          })
        }     
      context.commit('removeItemFromCart', index)
      const update = _createItemUpdate(itemNumber, isRedemptionItem, couponId, true)
      await context.dispatch('updateRemoteCart', update)      
    } else {
      console.error('Item could not be found')
      emitter.emit(EventTypes.ErrorEvent, `Could not remove item number ${itemNumber}`)
    }
  },
  removeCouponItem(context, {couponId, itemNumber}) {
    context.commit('removeCouponItem', { 
      couponId: couponId,
      itemNumber: itemNumber
    })

    let couponInfo = _getCouponInfo(context.state, couponId)

    //if there are no more items associated with the coupon then it can 
    //be removed from the state
    if (couponInfo.items.length == 0) {
      context.commit('removeCouponInfo', couponId)
    }
  },
  clearCart(context) {
    context.commit('clearCart')
  },
  async overridePriceStore(context, overrideInfo) {
    overrideInfo.hasPriceOverride = overrideInfo.overridePrice != null
    context.commit('overridePriceStore', overrideInfo)
    const update = _createStandardItemUpdate(overrideInfo.itemNumber)
    await context.dispatch('updateRemoteCart', update)
  },
  async changeQty(context, value) {
    context.commit('changeQty', value)
    const update = _createStandardItemUpdate(value.itemNumber)
    await context.dispatch('updateRemoteCart', update)
  },

  async updateRedemptionQuantity (context, redemption) {
    let storedRedemption = _getRedemptionItem(context, { 
      itemNumber: redemption.itemNumber,
      couponId: redemption.couponId
    })

    context.commit('changeRedemptionQty', {
      storedRedemption: storedRedemption, 
      quantity: redemption.quantity})

    const update = _createRedemptionUpdate(redemption)
    await context.dispatch('updateRemoteCart', update)      
  },
  async addCouponRedemption(context, redemption) { 
    
    let storedRedemption = _getRedemptionItem(context, { 
      itemNumber: redemption.itemNumber,
      couponId: redemption.couponId
    })

    if (storedRedemption) {
      let quantity = storedRedemption.quantity + redemption.quantity      
      context.commit('changeRedemptionQty', {
        storedRedemption: storedRedemption, 
        quantity: quantity})
    } else {
      let searchItem = null
      if (redemption.isRedemptionBurkhartProduct) {         
        try {
          let response = await httpInterceptor.interceptor.get(`/item/itemNumber/${redemption.itemNumber}`)
          searchItem = response.data
        } catch (error) {
          console.error(error)
        }
      }

      let cartItem = new CartItem(searchItem, redemption.quantity, redemption.type, 
        redemption.rewardValue, null, redemption.itemNumber, redemption.description, 
        redemption.isRedemptionBurkhartProduct,
        redemption.coupon).getCartItem()
      cartItem.price = 0      
      context.commit('addCouponRedemption', cartItem)
    }

    const update = _createRedemptionUpdate(redemption)
    context.dispatch('updateRemoteCart', update)
  },
  async syncCart (context, {updatedItemNumber, isRedemptionItem, couponId, isItemDeleted} = {}) {
    
    let storedCart = await context.dispatch('checkActiveCart')
    const cartAlreadySubmitted = (context.getters.cartKey != storedCart?.key)
    
    context.commit('addCartKey', storedCart?.key)

    const storedCartModified = new Date(storedCart?.modified)
    let isCartOutOfSync = false

    const synchronize = (storedCart?.syncEnabled && storedCartModified > context.getters.cartModified)
      || (storedCart?.items.length == 0) 
      || cartAlreadySubmitted

    await context.dispatch('syncTagRequests', null, { root:true })

    if (synchronize) {

      if (!storedCart) {
        storedCart = _createEmptyCart()
        const currentCart = context.getters.actualCart
        storedCart.customerNumber = currentCart.customerNumber
        storedCart.shipToCustomerNumber = currentCart.shipToCustomerNumber
        storedCart.key = context.getters.cartKey
        storedCart.couponItemInfo = []
      }

      let updatedItem = null
      
      if (updatedItemNumber) {

        const findExpression = !isRedemptionItem 
          ? f => _findStandardItem(f, updatedItemNumber) : f => _findRedemptionItem(f, updatedItemNumber, couponId)
          
        const storedItemIndex = storedCart.items.findIndex(findExpression)
        updatedItem = context.getters.items.find(findExpression)

        
        if (storedItemIndex >= 0) {
          if (!isItemDeleted) {
            storedCart.items[storedItemIndex] = updatedItem
          } else {
            storedCart.items.splice(storedItemIndex, 1)
          }

        } else if (!isItemDeleted){
          storedCart.items.push(updatedItem)
        }

        couponId ??= updatedItem.coupon?.id        

        //make sure the coupon info exists in the database data
        if (couponId) {
          const couponInfoIndex = storedCart.couponItemInfo.findIndex(c => couponId == couponId)
          const currentCouponInfo = _getCouponInfo(context.state, couponId)

          if (couponInfoIndex < 0) {
            //coupon info has been removed from what's stored in the database. Need
            //to add it back
            storedCart.couponItemInfo.push(currentCouponInfo)
          } else {
            storedCart.couponItemInfo[couponInfoIndex] = currentCouponInfo
          }
        }        
        
        _synchronizeRedemptionItems(storedCart, updatedItem?.coupon?.id ?? couponId, 
          context.getters.items)
        
      }
      

      //synchronize cart metadata
      const updatedElements = context.getters.updatedElements

      if (!cartAlreadySubmitted) {
        _synchronizeMetadata(storedCart, context.getters, updatedElements, 'accountManagerNotes')
        _synchronizeMetadata(storedCart, context.getters, updatedElements, 'futureFillDate')
        _synchronizeMetadata(storedCart, context.getters, updatedElements, 'freeShipping')
        _synchronizeMetadata(storedCart, context.getters, updatedElements, 'poNumber', 'poNumber', 'pOnumber')
        _synchronizeMetadata(storedCart, context.getters, updatedElements, 'comments')
      }


      context.commit('loadCart', storedCart)
      context.commit('loadCouponInfo', storedCart.couponItemInfo)
      context.commit('enableSync')

      if (!cartAlreadySubmitted) {
        //if the cart has already been submitted a different event will have 
        //already been emitted in checkActiveCart 
        emitter.emit(EmittedEvents.CartOutOfSyncEvent)
      }
      
      isCartOutOfSync = true
    }

    return isCartOutOfSync
  },
  async updateRemoteCart(context, cartItemUpdate = {}) {
    await context.dispatch('_postCart', cartItemUpdate)
  },
  updateComments(context, value) {
    context.commit('updateComments', value)
  },
  updatePoNumber(context, value) {
    context.commit('updatePoNumber', value)
  },
  async _postCart(context, cartItemUpdate = {}) {
    try {
        
      await context.dispatch('syncCart', cartItemUpdate)
      context.state.cart.couponItemInfo = context.state.couponInfo      

      let response = await httpInterceptor.interceptor.post('shoppingcart/', context.state.cart)        

      if (!context.getters.cartKey && response.data) {
        context.commit('addCartKey', response.data.key)
      }

      context.commit('resetUpdatedElements')
      context.commit('updateModifiedDate', response.data.modified)
    } catch (error) {
      console.error(error)
    }
  },
  async submitOrder(context) {
    const activeCart = await context.dispatch('checkActiveCart')

    if (activeCart != null) {
      await context.dispatch('_postCart')

      try {        
        await httpInterceptor.interceptor.post(
          `/shoppingcart/submit/${context.getters.cartKey}?customerTerritoryId=${context.rootGetters.customer.SalesTerritoryKey}&customerId=${context.rootGetters.customerId}`)
        context.commit('clearCart')          
        emitter.emit(EmittedEvents.OrderRefreshEvent)
        emitter.emit(EventTypes.SuccessNotificationEvent, 'Cart submitted successfully')      
      } catch (error) {
        console.error(error)
      }
    }

  },
  updateCouponRedemptionStatus(context, {
      couponId, isRedemptionComplete, rewardQuantityRemaining})  {
    const couponInfo = _getCouponInfo(context.state, couponId)    
    if (couponInfo) {
      couponInfo.isRedemptionComplete = isRedemptionComplete
      couponInfo.rewardQuantityRemaining = rewardQuantityRemaining
    }
  },
  async checkActiveCart (context) {
    const activeCart = await context.dispatch('getActiveCart')
    if (context.getters.cartKey && context.getters.cartKey != activeCart?.key) {
      emitter.emit(EmittedEvents.CartAlreadySubmittedEvent)
    }

    return activeCart
  }, 

  async _executeAddItemToCart (context, {cartItem, emitMessageAfterCartUpdate}) {
    context.commit('addItemToCart', cartItem)

    const emitMessage = () => emitter.emit(
      EventTypes.SuccessNotificationEvent, cartItem.description + ' x' + cartItem.quantity + ' added')
      
    if (!emitMessageAfterCartUpdate) {
      emitMessage()
    }

    const cartItemUpdate = _createStandardItemUpdate(cartItem.itemNumber)
    await context.dispatch('updateRemoteCart', cartItemUpdate)

    if (emitMessageAfterCartUpdate) {
      emitMessage()
    }    
  }

  
}

export default { state, getters, mutations, actions }
