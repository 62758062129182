import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BurkhartLibrary } from '@burkhart/vue-components'
import BalmUI from 'balm-ui'
import { currencyFormatter, dateFormatter, goToProduct } from './utility/Util.js'

const vueApp = createApp(App)

vueApp.provide('currencyFormatter', currencyFormatter)
vueApp.provide('dateFormatter', dateFormatter)
vueApp.provide('goToProduct', goToProduct)

vueApp.use(BurkhartLibrary)
  .use(router)
  .use(store)
  .use(BalmUI)
  .mount('#app')