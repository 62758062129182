<template lang="pug">
.customer-sales-report
  bd-slider(v-bind:modelValue="show" addCloseButton :zIndex="zIndex" @closeSlider="closeSlider")
    template(v-slot:title="")
      .title {{ title }}
    template(v-slot:headerContent="")
      bd-button(icon='picture_as_pdf' raised @click="generatePDF" :disabled="generatingPDF") PDF
    template(v-slot:content="")
      .report-data(v-if="!isLoading")
        table    
          tr(class="report-header")
            th.sticky
            th(v-for="monthYear in monthYearColumns") {{ monthYear }}
            th.total Totals
          tr(v-for="(category) in reportCategories") 
            th.label.sticky {{ category.label }}
            td.amount(v-for="categoryTotal in getCategoryTotals(category.label)") {{ formatTotal(categoryTotal) }}
          tr(class="report-footer")
            th.label.sticky Totals
            th.amount(v-for="total in getMonthTotals()") {{ formatTotal(total) }}        
      .loading(v-if="isLoading")
        ui-spinner(:size="'medium'" active)
</template>

<script setup>
import { inject, watch, ref } from 'vue'
import { decimalFormatter } from '@/utility/Util.js'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { v4 as uuid } from 'uuid'


pdfMake.vfs = pdfFonts.pdfMake.vfs

let isLoading = ref(false)
let reportData = ref(null)
let generatingPDF = ref(false)
let monthYearColumns = ref([])
let monthYearTotals = ref([])

const tableFontSize = 10

const { interceptor } = inject("httpInterceptor");

const title = '13 Month Category Sales Report'

const miscellaneousCategory = 'MISC'
const miscellaneousLabel = 'Misc'

const reportCategories = [
  {
    label: 'Merch',
    id: 'MERCHANDISE'
  },
  {
    label: 'Teeth',
    id: 'TEETH'
  },  
  {
    label: 'Support SUP',
    id: 'SERVICES'
  },    
  {
    label: 'Labor',
    id: 'LABOR'
  },
  {
    label: 'Equip',
    id: 'EQUIPMENT'
  },
  {
    label: 'Rental',
    id: 'RENTAL'
  },  
  {
    label: 'Parts',
    id: 'PARTS'
  },    
  {
    label: miscellaneousLabel,
    id: miscellaneousCategory
  }
]

const props = defineProps({
  modelValue: {
    type: Object
  }, 
  show: {
    type: Boolean,
    default: false
  },
  zIndex: {
    type: Number,
    default: 200    
  }
})

const emit = defineEmits([
  'closeSlider',
  'update:modelValue'
])

watch(() => props.show, 
  async (newValue, oldValue) => {
    if (newValue) {
      await loadData()
    }
}) 

const loadData = async () => {

  isLoading.value = true 

  try {
    reportData.value = (await interceptor.get(
      `customer/categorySalesByMonthSummary/${props.modelValue.CustomerID}`))
      .data
  } catch (error) {    
  }

  createMonthYearData(new Date(reportData.value.startDate), new Date(reportData.value.endDate))


  isLoading.value = false
}

const formatTotal = (total) => { 
  let value = decimalFormatter(total, 0) 
  return value == 0 ? '' : value
}

const getPDFcolumnWidths = () => {
  let columnWidths = []
  if (monthYearColumns.value?.length > 0) {
    columnWidths.push(60)
    monthYearColumns.value.forEach(m => columnWidths.push(40))
    columnWidths.push(60)
  }

  return columnWidths
}

const generatePDFcolumnLabels = () => {
  let columnLabels = []
  const border = [false, false, false, true]
  let columnLabelProperties = {
    border, 
    fontSize: tableFontSize
  }
  if (monthYearColumns.value?.length > 0) {
    columnLabels.push({
      text: '', 
      ...columnLabelProperties
    })
    
    columnLabelProperties.alignment = 'right'
    
    monthYearColumns.value.forEach(m => columnLabels.push({
      text: m,
      ...columnLabelProperties
    }))
    columnLabels.push({
      text: 'Totals',      
      ...columnLabelProperties
    })
  } 
  
  return columnLabels
}

const generatePDFdata = () => {
  const border = [false, false, false, false]
  return reportCategories.reduce((accumulator, current) => {  
    
    let data = getCategoryTotals(current.label).map(t => {
      return ___createTableData(t, border)
    })

    data.unshift(__createLabel(current.label, border))
    
    accumulator.push(data)
    return accumulator
  }, [])
}

const generatePDFtotals = () => {
  const border = [false, true, false, false]
  let totals = getMonthTotals().map(t => {
    return ___createTableData(t, border)
  })
  totals.unshift(__createLabel('Total', border))
  return [totals]
}

const __createLabel = (text, border) => {
  return {
    text: text, 
    alignment: 'left', 
    fontSize: tableFontSize, 
    border
  }
}

const ___createTableData = (value, border) => {
  return {
    text: formatTotal(value),
    alignment: 'right', 
    fontSize: tableFontSize,
    border
  }
}

const generatePDF = () => {
  generatingPDF.value = true

  const customer = props.modelValue  
  const name = customer.Name
  const customerId = customer.CustomerID
  const territoryId = customer.TerritoryID ? 
    customer.TerritoryID.replace('*', ': ') : ''
  const priceClass = customer.PreferredStatus 
  const contractName = customer.ContractName

  let doc = {
    info: {
      title: `Burkhart Dental Supply - ${title}`,
      subject: `${title}-${name}`, 
      author: 'Burkhart Dental Supply'
    },
    pageOrientation: 'landscape',    
    pageMargins: [25, 40],
    header: [
      {
        text: `${title}`,
        alignment: 'center',
        margin: [0, 20, 0, 10]
      },
    ],
    content: [
      {
        columns: [
          {
            text: 'Customer No',
            width: 75
          },
          {
            text: customerId,
            width: 75
          },
          {
            text: name,
            width: 200
          },      
          {
            text: territoryId,
            width: 60
          }, 
          {
            text: 'Price Class:',
            width: 70,            
          }, 
          {
            text: priceClass,
            width: 50
          },
          {
            text: 'Contract:',
            width: 55
          },
          {
            text: contractName,
            width: 'auto'
          }
        ], 
        margin: [0, 10, 0, 10]
      },
      {            
        table: {
          headerRows: 1,
          widths: getPDFcolumnWidths(),
          body: [
            generatePDFcolumnLabels(),
            ...generatePDFdata(),
            ...generatePDFtotals()
          ]
        }      
      }      
    ]
  }


  pdfMake.createPdf(doc).download(`${uuid()}.pdf`)

  generatingPDF.value = false
}

const getCategoryTotals = (category) => {
  let totals = []
  totals = monthYearTotals.value
    .filter(m => m.category == category).map(t => t.total)
  
  let categoryTotal = 0
  if (totals.length > 0) {
    categoryTotal = totals.reduce(
      (accumulator, currentValue) => accumulator + (currentValue ?? 0), 0)    
  }

  totals.push(categoryTotal)

  return totals
}

const getMonthTotals = () => {
  let totals = []
  let overallTotal = 0

  const length = monthYearColumns?.value?.length ?? 0

  for (let i = 0; i < length; i++) {
    let total = monthYearTotals.value.filter(m => m.id == monthYearColumns.value[i])
      .map(m => m.total)
      .reduce((accumulator, currentValue) => accumulator + (currentValue ?? 0), 0)
    totals.push(total)
    overallTotal += total
  }

  totals.push(overallTotal)

  return totals
}

const createMonthYearTotal = (date) => {
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')

  let monthYear = `${date.getFullYear()}`.substring(2,4) + '-' + month

  reportCategories.forEach(r => {
    let data = reportData.value.data.find(d => d.description?.toUpperCase() == r.id 
      && d.month == month && d.year == year
    )   

    monthYearTotals.value.push({
      total: data?.total ? Math.round(data.total) : 0,
      category: r.label,
      id: monthYear
    })
  })

  addUnknownTotals(month, year, monthYear, reportData.value.data)

  //add the column label
  monthYearColumns.value.push(monthYear)
}

const addUnknownTotals = (month, year, id, data) => {
  //make sure any unknown categories are accounted for
  let unknownData = data.filter(f => !reportCategories.find(r => r.id == f.description.toUpperCase()) && f.month == month && f.year == year)
  
  if (unknownData?.length > 0) {
    let miscellaneousCategoryData = monthYearTotals.value.find(
    m => m.category==miscellaneousLabel && m.id == id)

    if (!miscellaneousCategoryData) {
      miscellaneousCategoryData = {
        total: 0,
        category: miscellaneousLabel,
        id: id
      }

      monthYearTotals.value.push(miscellaneousCategoryData)
    }

    let unknownTotal = unknownData.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)

    miscellaneousCategoryData.total = (miscellaneousCategoryData.total ?? 0) + unknownTotal
  }
}

const createMonthYearData = (startDate, endDate) => {

  monthYearColumns.value.length = 0
  monthYearTotals.value.length = 0
  
  createMonthYearTotal(endDate)
  let nextDate = new Date(endDate)
  
  do {
    let month = nextDate.getMonth() - 1
    nextDate.setMonth(month, 1)
    createMonthYearTotal(nextDate)
  } while (nextDate > startDate)
}

const closeSlider = () => {  
  emit('closeSlider')
}

</script>

<style scoped lang="scss">
.customer-sales-report {
  display: flex;
  flex-direction: column;
  width:100%;
  
  overflow-x: hidden;
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
    .close-button &i.material-icons {
      cursor: pointer;
      max-width: 36px;
    }
    .title {
      color: $iced-slate;
      font-weight: bolder;
    }
    .bd-button {
      padding: 4px;
    }
  }

  .report-data {
    overflow-x: auto;    
    margin: 12px 8px;
    display: flex;

    >table {
      border-collapse: collapse;      
      font-size: 14px;
      overflow-x: auto;
      margin: 0 auto;
      .label {
        text-align: left;

      }
      .sticky {
        position: sticky;
        left: 0;
        background-color: $white;
        z-index: 2;
      }

      td, th {
      min-width: 56px;
      text-align: right;
      z-index: 1;      
    }      
      
      .total {
        width: 60px;
      }
    }

    .report-header>th {
      border-bottom: 2px solid $teal;
    }
    .report-footer>th {
      border-top: 2px solid $teal;
    }
  }

  .loading {
    margin: 0 auto;
  }

}
</style>