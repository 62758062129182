<template lang="pug">
.home
  .home-container(v-if="auth.loggedIn")
    .logo-container(v-if="isCustomerUser")
      img(src="./../assets/Logo_BurkhartDentalSupply_black-01.svg" width="300" height="146")
    .account-selector(v-if="isCustomerUser")
      accountSelector(:isCustomerUser="isCustomerUser" :role="userRole") 
    .search(v-else="")
      search(:role="userRole")
</template>

<script>
  import Search from '@/components/Search'
  import AccountSelector from '@/components/AccountSelector'
  import { mapGetters } from 'vuex'  
  import { inject } from 'vue'
  import { authUtility } from '@burkhart/vue-components'
  import { AuthorizationRoles } from '../utility/Constants.js'    

  export default {
    name: 'Home',
    props: ['id', 'error'],
    components: {
      search: Search,
      accountSelector: AccountSelector
    },
    data () {
      return {}
    },
    setup () {
      const authorizationUtility = authUtility
      const authRoles = AuthorizationRoles
      const auth = inject('auth')
      return {
        authorizationUtility,
        authRoles,
        auth
      }
    },
    computed: {
      ...mapGetters([
        'userRole',
        'isCustomerUser'
      ])

    }
  }
</script>

<style lang="scss" scoped>
  .home {
    margin: 0 auto;  
    height: 100%;
    display: flex;  
    width: 100%;
    overflow-y: hidden;
  .home-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      flex-grow: 1;
      min-height: 0;
      .search {
        height: 100%;
        margin-top: 32px;
        width: 520px;
        @media only screen and (max-width: 520px) {
          width: 100%;
        }
      }
      .logo-container {
        text-align: center;
      }
    }
  }
</style>
