export class EmittedEvents {
  static get OrderRefreshEvent () {
    return 'OrderRefresh'
  }
  static get AppOutOfSyncEvent () {
    return 'OutOfSync'
  }
  static get CartOutOfSyncEvent () {
    return 'CartOutOfSync'
  }
  static get CartAlreadySubmittedEvent () {
    return 'CartAlreadySubmitted'
  }
}

export class AuthorizationRoles {
  static get Customer () {
    return 'Customer'
  }
  static get PricingView () {
    return 'PricingView'
  }
  static get CustomerService () {
    return 'CustomerService'
  }
  static get OrderSubmit () {
    return 'OrderSubmit'
  }
}

export class CustomerPopOuts {
  static get SalesReport() {
    return 'SalesReport'
  }
}
