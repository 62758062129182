import Order from '@/views/Order'
import Home from '@/views/Home'
import Customer from '@/views/Customer'
import Orders from '@/views/Orders'
import Products from '@/views/Products'
import Cart from '@/views/Cart'
import { isAuthorized } from '@burkhart/vue-components'
import qs from 'qs'
import Product from '@/views/Product'
import { createRouter, createWebHashHistory } from 'vue-router'

const publicRouteName = 'Public'
export const publicProductViewInternalRouteName = 'PublicProductViewInternal'
export const customerProductRouteName = 'CustomerProduct'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    props: false,
    meta: {
      goToPublicRouteIfNotLoggedIn: true
    }    
  },{
    path: '/error/:error',
    name: 'Error',
    component: Home,
    props: true
  },{
    path: '/customer/:id',
    redirect: { name: 'CustomerProduct', path:'/customer/:id/products' },
    name: 'Customer',
    component: Customer,
    props: true,    
    children: [
      {
        name: 'CustomerOrder',
        path: 'orders/:orderSearch?',
        component: Orders,
        props: true
      },{
        name: 'CustomerProduct',
        path: 'products',
        component: Products,
        props: true            
      },{
        name: 'CustomerCart',
        path: 'cart',
        component: Cart,
        props: true
      },{
        name: 'ProductViewRedirect',
        path: 'product/:itemNumber',
        component: Product, 
        props: true,
        redirect: to => {          
          return {name: 'ProductViewInternal', path:`/customer/${to.params.id}/product/internal/${to.params.itemNumber}`}
        }
      }, {
        name: 'ProductViewInternal',
        path: 'product/internal/:itemNumber',
        component: Product, 
        props: true
      }

    ],
    meta: {
      requiresAuth: true
    }
  },{
    path: '/order/:id/:shipid?/:index?',
    name: 'Order',
    component: Order,
    props: true
  }, {
    path: '/product/:itemNumber',    
    name: 'Product',
    component: Product,
    props: true
  }, {
    path: '/product',    
    name: 'ProductInvalid',
    component: Products,
    props: true,
    redirect: to => {          
      return {name: publicRouteName}
    }    
  }, {
    path: '/',    
    name: publicRouteName,
    component: Products, 
    meta: {
      goHomeIfLoggedIn: true
    },
    children: [
      {
        name: publicProductViewInternalRouteName,
        path: '/product/internal/:itemNumber',
        component: Product, 
        props: true
      }      
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(), 
  routes, 
  stringifyQuery: query => {
    let result = qs.stringify(query, { format: 'RFC3986'})
    return result 
  } 
})


router.beforeEach(async (to, from) => {    
  return await setRoute(to, from, isAuthorized)
})

export async function setRoute (to, from, isAuthorizedFunc) {
  let routingResult = true
  
  const homeRoute = {name: 'Home'}

  if (to.meta.requiresAuth || to.meta.goHomeIfLoggedIn 
      || to.meta.goToPublicRouteIfNotLoggedIn) {
    const isAuthorized = await isAuthorizedFunc()    

    if (to.meta.requiresAuth || to.meta.goHomeIfLoggedIn ) {
      if ((to.meta.requiresAuth && !isAuthorized)
      || (to.meta.goHomeIfLoggedIn && isAuthorized)) {
        routingResult = homeRoute
      }
    } else if (!isAuthorized && to.meta.goToPublicRouteIfNotLoggedIn) {
      //only navigate if the current route isn't already the public route
      routingResult = (from.name == publicRouteName) ? false : {name: publicRouteName}
    }
 
  }

  return routingResult
}

/*
The following navigation duplicated error swallowing is based on https://stackoverflow.com/a/58439497/6286783
*/
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(error => {
    if (error.name != "NavigationDuplicated") {
      throw error
    }
  })
}

export default router