<template lang="pug">
.alerts(v-bd-click-outside="hideAlerts")
  bd-button(@click="showAlerts" icon="notifications" :alert="newCount")
  .alert-dropdown(v-bind:class="{show: show}")
    .alerts-header
      .left Alerts
      .right(@click="markAllAsRead") Mark All as Read
    .alerts-list#alerts-list
      .alert(v-for="item in alertList" v-bind:class="{unread: !item.isRead}" @click="updateIsRead(item)")        
        bd-button.alert-icon(icon="warning")
        .content
          .left-block
            .type {{ item.orderAlertType }} Items
            .name {{ item.customerName }}
          .right-block
            .order-number Order# {{ item.salesOrderId }}
            .customer-number Customer# {{ item.customerNumber }}
      .loading
        ui-spinner(:size="'medium'" active v-if="loading")
        .end-of-list(v-else="") End of List
</template>

<script>
  import { formatISO } from 'date-fns'
  import { inject } from 'vue'
  import { appendDirectLinkParameter } from '../utility/Util.js'

  export default {
    name:'Alerts',
    pageSize: 10,
    data() {
      return {
        show: false,
        loading: false,
        alertList: [],
        totalRecords: 0,
        newCount: 0,
        loadIndex: 0
      }
    },
    setup () {
      const { interceptor } = inject('httpInterceptor')
      return {
        interceptor
      }
    },
    mounted () {
      this.loadAlerts()
    },
    methods: {
      showAlerts () {
        if (!this.show) {
          this.show = true
          this.updateLastAccessedDate()
        } 
        else {
          this.hideAlerts()
        }
      },
      hideAlerts () {
        this.show = false
      },
      scroll () {
        let alertList = document.querySelector('#alerts-list')
        alertList.addEventListener('scroll', () => {
          if (alertList.scrollHeight - alertList.offsetHeight === alertList.scrollTop) {
            if (!this.loading && (this.$options.pageSize * (this.loadIndex + 1)) < this.totalRecords ) {
              this.loadIndex++
              this.loadAlerts()
            }
          }
        })
      },
      loadAlerts () {
        this.loading = true
        this.interceptor.get(`/alert/pageIndex/${this.loadIndex}/pageSize/${this.$options.pageSize}`)
          .then(response => {
            this.alertList.push(...response.data.orderAlerts.data)
            this.totalRecords = response.data.orderAlerts.totalCount
            this.newCount = response.data.orderAlerts.newCount
            this.scroll()
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.error(error)
          })
      },
      updateLastAccessedDate () {
        this.interceptor.post(`/alert/lastAccessed?accessedDate=${formatISO(new Date())}`)
          .then(response => {
            this.newCount = 0
          })
          .catch(error => {
            console.error(error)
          })
      },
      updateIsRead (alert) {
        this.interceptor.post('/alert/viewed', {
            AlertType: 'Order',
            AlertId: alert.id,
            ViewedDate: formatISO(new Date())
          })
          .then(response => {
            alert.isRead = true
          })
          .catch(error => {
            console.error(error)
          })
        this.gotoOrder(alert.salesOrderId, alert.customerNumber, alert.orderAlertType)
      },
      markAllAsRead () {
        this.interceptor.post('/alert/viewed/orderAlerts/all')
          .then(response => {
            this.alertList = this.alertList.map(i => i = {...i, isRead: true })
          })
          .catch(error => {
            console.error(error)
          })
      },
      gotoOrder (salesOrderId, customerId, alertType) {

        let filter = {
          search: `${salesOrderId}`,
          customerNumber: customerId
        }

        switch (alertType){
          case "BackOrdered":
            filter['hasBackOrderedItems']=true
            break;
          case "Canceled":
            filter['hasCanceledItems']=true            
            break;
          default:
            break;
        }
        const query = appendDirectLinkParameter(filter)

        this.$router.push({path:`/customer/${customerId}/orders`, query}).catch(err => {})
        this.hideAlerts()
      },
    },
  }
</script>

<style scoped lang="scss">

  .alerts {
    display: flex;

    .bd-button {
      margin: 0px;
      color: $white;
      position: relative;

      &:hover {
        background-color: $deep-sea !important;
      }
    }    
  }

  .header-icon {
    color: $white;
  }

  .alert-circle {
    background-color: $grapefruit;
    font-size: 10px;
    min-width: 14px;
    padding: 0 2px;
    height: 14px;
    border-radius: 7px;
    text-align: center;
    line-height: 13px;
    position: absolute;
    top: 0px;
    left: 0px;
    color: $white;
  }

  .alert-dropdown {
    background-color: $white;
    border: 1px solid $gray;
    box-shadow: 0px 3px 8px $shadow-color;
    color: $deep-sea;
    position: absolute;
    top: 35px;
    right: 0px;
    display: none;
    z-index: 1010;

    max-width: 435px;
    min-width: 330px;

    &.show{
      display: block;
    }

    .alerts-header {
      border-bottom: 1px solid $deep-sea;
      display: flex;
      justify-content: space-between;
      padding: 3px 5px;

      .left {
        font-weight: bold;
      }

      .right {
        color: $link;
        cursor: pointer;
      }
    }

    .alerts-list {
      max-height: 400px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      .alert {
        display: flex;
        padding: 5px;

        &:not(:last-child) {
          border-bottom: 1px solid $grey;
        }

        &.unread {
          background-color: lighten($teal, 30);

          &:hover {
            background-color: $robbins-egg;
            cursor: pointer;
          }
        }

        &:hover {
          background-color: lighten($robbins-egg, 10);
          cursor: pointer;
        }

        .alert-icon {
          color: $grapefruit;
        }

        .content {
          display: flex;
          width: 100%;
          padding: 2px 5px 0 5px;

          .left-block {
            width: 140px;

            .name {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .loading {
      display: flex;
      align-items: center;

      .end-of-list {
        display: block;
        color: $iced-slate;
        text-align: center;
      }
    }
  }
</style>