<template lang="pug">
input(
  name="quantity"    
  v-model="displayedValue",
  @change="update($event.target.value)"
  @blur="onBlur"
  type="number"
  min="1", 
  :max="max"
  :class="{ error: hasError && !resetToValidWhenInvalid }"
  :disabled="disabled"
  placeholder="QTY"
)
</template>

<script>
  import { isNumber  } from '../utility/Util.js'
  export default {
    name:'quantity-input',
    props: {
      modelValue: {
        type: Number
      }, 
      max: {
        type: Number,
        default: null
      },
      resetToValidWhenInvalid: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }, 
      defaultValueIfNull: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        inputValue: this.modelValue,
        validValue: this.modelValue,
        hasError: false
      }
    },
    emits: [
      'validInput',
      'hasError'
    ],
    computed: {
      displayedValue : {
        get () {
          return this.inputValue
        },
        set (newValue) {
          this.inputValue = newValue
        }
      }
    },
    methods: {
      update(newValue) {

        if (!newValue && this.defaultValueIfNull) {
          newValue = this.defaultValueIfNull
        }

        this.hasError = !this.isValid(newValue)
        if (!this.hasError) {
          this.$emit('validInput', +newValue)
          this.validValue = newValue
        }
        this.$emit('hasError', this.hasError)
      },
      isValid(value) {
        return (isNumber(value) && value > 0 
            && (this.max == null || (this.max != null && value <= this.max))) 
      },
      onBlur (event) {
        if (this.hasError && this.resetToValidWhenInvalid) {
            this.displayedValue = this.validValue
        }
      }
    }, 
    watch: {
      modelValue (newValue) {        
        if (newValue != this.displayedValue) {
          this.displayedValue = newValue
          this.validValue = newValue
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .error {
    border-color: $error !important
  }
  input {
    border-radius: 35px;
    border: 1px solid transparent;
    border-color: $iced-slate;
    background-color: $white;
    color: $deep-sea;
    padding: 0 0 0 10px;          
    min-height: 24px;    
    font-size: 12px;
    width: 52px;

    &:focus {
      outline: none;
    }
  }  
</style>