<template lang="pug">
.product-purchase-info(v-if="(itemIsOrderable || itemPricingEnabled || contactAccountManager)")
  .price-loading(v-if="itemPricingEnabled && !pricingLoaded")
    ui-spinner(:size="'small'" active)
  .price(v-if="itemPricingEnabled && pricingLoaded && !compactProductPurchaseView") {{ price }}
  .cart(v-if="pricingLoaded && !compactProductPurchaseView")
    quantity-input(:modelValue="quantity" @validInput="newValue => quantity = newValue" 
      @hasError="hasError => hasInputError = hasError" :resetToValidWhenInvalid="false" :defaultValueIfNull="1")
    bd-button(icon="add_shopping_cart" raised @click="addToCart({data: modelValue, quantity: quantity})" :disabled="!itemIsOrderable || !enableAddToCartButton")
    .cart-quantity(v-if="addedToCart") {{cartQuantity}} in cart
  .contact-account-manager(v-if="contactAccountManager && !compactProductPurchaseView")
    .message Contact your account manager to purchase 
  .cart.compact(v-if="compactProductPurchaseView && pricingLoaded")
    bd-button.compact(icon="add_shopping_cart" @click="addToCart({data: modelValue, quantity: 1})" :disabled="!itemIsOrderable || !enableAddToCartButton" :size="32")
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref, inject } from 'vue'
import QuantityInput from '@/components/QuantityInput'
import { debounce } from 'throttle-debounce'
import { getImageUrlFromProduct, isPricingLoaded } from '../utility/Util.js'

const store = useStore()
const currencyFormatter = inject('currencyFormatter')

let quantity = ref(null)
let hasInputError = ref(false)
let addToCartInProgress = ref(false)

const props = defineProps({
  modelValue: {
    type: Object
  }, 
  enablePricing: {
    type: Boolean,
    default: false
  },
  isCustomerUser: {
    type: Boolean,
    default: true
  }, 
  enableCart: {
    type: Boolean,
    default: false
  }, 
  waitForPricingToLoad: {
    type: Boolean,
    default: false
  },
  compactProductPurchaseView: {
    type: Boolean,
    default: false
  }
})

const itemPricingEnabled = computed(() => props.enablePricing 
    && !(props.isCustomerUser && props.modelValue?.lifeCycle == "D"))
const itemIsOrderable = computed(() => props.enableCart && props.modelValue?.isOrderable)
const enableAddToCartButton = computed(() => !hasInputError.value && !addToCartInProgress.value)
const contactAccountManager = computed(() => props.isCustomerUser 
  && props.modelValue?.lifeCycle == "D" && props.enableCart)
const cartQuantity = computed(() => store.getters.cartItemQuantity(props.modelValue.itemNumber))
const addedToCart = computed(() => (cartQuantity.value ?? 0) > 0)
const addToCart = debounce(300, async function(data) {saveItem(data)})
const price = computed(() => currencyFormatter(props.modelValue.price || 0))
const pricingLoaded = computed(() => isPricingLoaded(props.modelValue, props.waitForPricingToLoad))
const saveItem = (data) => {
  if (!addToCartInProgress.value) {          
      addToCartInProgress.value = true
      data.quantity ??= 1
      data.imageUrl = getImageUrlFromProduct(props.modelValue) 
      store.dispatch('addItemToCart', {itemObject:data})
      addToCartInProgress.value = false
      quantity.value = null
  }
}
</script>

<style scoped lang="scss">
.product-purchase-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;    
  gap: 8px;

  .cart, .contact-account-manager {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    
    .cart-quantity {
      color: $teal;
      font-weight: bolder;
      font-size: 14px;
    }

    .message {
      font-style: italic;
      font-weight: bolder;
      color: $grapefruit;
    }
  }

  .cart.compact {
    flex-direction: row;
    align-items: center;
  }

  .bd-button {
    &.compact {
      background-color: $zest;
      padding: 4px;
      margin: 0 4px;
      width: 100%;
    }

    &.disabled {
      background-color: $gray !important;

      &:hover {
        background-color: $gray !important; 
      }
    }

    &:hover {
      background-color: darken($zest, 15) !important; 
    }
  }
}
</style>