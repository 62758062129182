<template lang="pug">
.product-order-history(v-if="hasOrderHistory")
  .label Order History
  .order-list
    .order(:class="getOrderClass(item.status)" v-for="item in pagedOrderHistory" @click="gotoOrder(item.salesOrderId )")
      span(class="icon")
        ui-icon(class="icon") {{ getOrderStatusIcon(item.status) }}
      .item.hover-effects {{ dateFormatter(item.orderDate) }}
      .item.hover-effects {{ item.warehouse.split(",")[0] }}
      .quantity.hover-effects x{{ item.quantity }}
  .order-history-paging(v-if="modelValue.itemOrders.length > orderHistoryPageSize")
    bd-button(:disabled = "previousPageDisabled" @click="goToOrderHistoryPage(0)" icon="first_page") 
    bd-button(:disabled = "previousPageDisabled" @click="goToOrderHistoryPage(orderHistoryCurrentPageIndex - 1)" icon="chevron_left")
    .page-count {{orderHistoryCurrentPageIndex + 1}} of {{orderHistoryLastPageIndex + 1}}
    bd-button(:disabled = "nextPageDisabled" @click="goToOrderHistoryPage(orderHistoryCurrentPageIndex + 1)" icon="chevron_right")
    bd-button(:disabled = "nextPageDisabled" @click="goToOrderHistoryPage(orderHistoryLastPageIndex)" icon="last_page")
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import { getOrderStatusIcon, appendDirectLinkParameter } from '../utility/Util.js'
import { useRouter } from "vue-router";

const router = useRouter();
const orderHistoryCurrentPageIndex = ref(0)
const orderHistoryLastPageIndex = ref(0)

const dateFormatter = inject('dateFormatter')

const props = defineProps({
  modelValue: {
    type: Object
  },
  orderHistoryPageSize: {
    type: Number,
    default: 5
  },
  customerId: {
    type: String
  }
})

const pagedOrderHistory = computed(() => {  
  let firstRecord = orderHistoryCurrentPageIndex.value * props.orderHistoryPageSize
  let lastRecord = firstRecord + props.orderHistoryPageSize
  return sortedOrderHistory.value.slice(firstRecord, lastRecord)
})

const sortedOrderHistory = computed(() => {
  let itemOrders = null 

  if (hasOrderHistory.value) {
    itemOrders = [...props.modelValue.itemOrders]
    itemOrders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate))
  }

  return itemOrders
})

const previousPageDisabled = computed(() => orderHistoryCurrentPageIndex.value == 0 )
const nextPageDisabled = computed(() => orderHistoryCurrentPageIndex.value == (orderHistoryLastPageIndex.value) )
const hasOrderHistory = computed(() => props.modelValue.itemOrders?.length > 0)

const gotoOrder = (id) => {
  const query = appendDirectLinkParameter({search: `${id}`})
  router.push({path: `/customer/${props.customerId}/orders`, query })
    .catch(err => {})
}

const goToOrderHistoryPage = (newPageNumber) => {
  if (newPageNumber < 0) {
    newPageNumber = 0
  } else if (newPageNumber > orderHistoryLastPageIndex.value){
    newPageNumber = orderHistoryLastPageIndex.value
  }

  if (newPageNumber != orderHistoryCurrentPageIndex.value) {
    orderHistoryCurrentPageIndex.value = newPageNumber
  }
}

const setupPaging = () => {
  orderHistoryCurrentPageIndex.value = 0

  if (hasOrderHistory.value ) {
    orderHistoryLastPageIndex.value = Math.ceil(props.modelValue.itemOrders.length / props.orderHistoryPageSize) - 1
  }
}

const getOrderClass = (status) => 'order-' + (status?.toLowerCase() ?? '')


setupPaging()

</script>

<style scoped lang="scss">
  .product-order-history {
    min-width: 250px;

    .order-list {
      margin: 8px 0 0 0;
      .order {
        padding: 2px 8px;
        display: flex;
        align-items: flex-end;        
        align-items: center;
        border-radius: 25px;

        .icon {
          width: 35px;
          height: 24px;
        }
        .item {
          flex: 1 1 0px;
        }
        .quantity {
          margin-right: 3px;
        }

        &:hover {
          cursor: pointer;
          .hover-effects {
            text-decoration: underline;
          }
        }
      }

      .order-shipped {
        background-color: lighten($shipment, 15);
      }
      .order-processing {
        background-color: $processing;
      }
      .order-pending {
        background-color: $pending;
      }
      .order-canceled {
        background-color: lighten($canceled, 25);
      }
      .order-backordered {
        background-color: lighten($backordered, 25);
      }
    }

    .order-history-paging {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      align-items: center;
      .page-count {
        font-size: 12px;
        display: flex;
        align-items: flex-end;
        padding: 4px 0px;
      }
    }
  }

</style>